import OpenAi from "openai";
import { AssistantType } from "../../../enums/assitantType.enum";
import { DEFAULT_REQUEST_TIMEOUT_MS } from "../../../utils/timerUtils";

export class OpenAiClient {
  private static _client: OpenAi;
  private static _assistantId: string = process.env.OPENAI_ASSISTANT_ID || "";
  private static _visualizationAssistantId: string =
    process.env.OPENAI_ASSISTANT_ID_VISUALIZATION || "";
  public static getClient(): OpenAi {
    if (!this._client) {
      this._client = new OpenAi({ timeout: DEFAULT_REQUEST_TIMEOUT_MS });
    }

    return this._client;
  }
  public static getAssistantId(): string {
    return this.getAssistantIdByFunction();
  }
  public static getAssistantIdByFunction(
    assistantType: AssistantType = AssistantType.DEFAULT,
  ): string {
    switch (assistantType) {
      case AssistantType.VISUALIZE:
        return this._visualizationAssistantId;
      default:
        return this._assistantId;
    }
  }
}
