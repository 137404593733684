import React from "react";
import FolderIcon from "../../assets/svgIcons/folder.svg";
import FIleIcon from "../../assets/svgIcons/file.svg";
import { Checkbox } from "../common/design-system/Checkbox";
import type { FileTabColumns, UserFileApi } from "../../typing/shared";
import {
  formatDate,
  getFileItemType,
  getFileName,
} from "../../utils/helper-functions";
import ErrorTooltip from "./ErrorTooltip";

interface FileListItemProps {
  isChecked: boolean;
  onSelect: () => void;
  item: UserFileApi;
  onClick: (item: UserFileApi) => void;
  columnsToDisplay: FileTabColumns[];
}

export default function FileItem({
  columnsToDisplay,
  isChecked,
  item,
  onClick,
  onSelect,
}: FileListItemProps) {
  const itemType = getFileItemType(item);

  return (
    <tr
      className="cc-border-b cc-border-[#00000012] hover:cc-bg-surface-surface_1 cc-cursor-pointer"
      key={`file-${item.id}`}
      onClick={() => onClick(item)}
    >
      <th className="cc-flex cc-items-center cc-pl-6 cc-pr-2 cc-py-3  md:!cc-hidden ">
        <Checkbox
          checked={isChecked}
          className="cc-my-0.5 cc-mr-[8px]"
          onCheckedChange={onSelect}
        />
        {itemType === "FOLDER" && (
          <img
            alt="Folder Icon"
            className="cc-w-5 cc-shrink-0 cc-mr-[8px]"
            src={FolderIcon}
          />
        )}
        {itemType === "FILE" && (
          <img
            alt="File Icon"
            className="cc-w-5 cc-shrink-0 cc-mr-[8px]"
            src={FIleIcon}
          />
        )}

        {columnsToDisplay.includes("name") && (
          <p
            className={` ${
              (columnsToDisplay.includes("created_at") ||
                columnsToDisplay.includes("updated_at")) &&
              columnsToDisplay.includes("external_url")
                ? "cc-w-auto"
                : "cc-w-[312px]"
            } cc-break-all cc-line-clamp-2 cc-text-start cc-text-[#000] dark:cc-text-dark-text-white cc-text-[14px] cc-font-semibold cc-leadind-[24px]`}
          >
            {getFileName(item)}
          </p>
        )}
      </th>

      {columnsToDisplay.includes("status") && (
        <td className="cc-py-3 cc-px-2 cc-align-top  md:cc-hidden cc-table-cell">
          {item.sync_status === "READY" && (
            <div className="cc-w-fit cc-bg-surface-success_accent_1 cc-text-success_high_em cc-py-[3px] cc-text-xs cc-px-2 cc-rounded-lg cc-font-semibold">
              Ready
            </div>
          )}
          {item.sync_status === "SYNC_ERROR" && (
            <div className="cc-w-fit cc-font-semibold cc-bg-surface-danger_accent_1 cc-text-outline-danger_high_em cc-py-[3px] cc-text-xs cc-px-2 cc-rounded-lg cc-relative error">
              Error
              <div className="cc-absolute cc-top-[32px] -cc-left-[6px] error-tooltip cc-z-[1]">
                <ErrorTooltip
                  leftPosTip={"21"}
                  message={item.sync_error_message}
                />
              </div>
            </div>
          )}
          {(item.sync_status === "SYNCING" ||
            item.sync_status === "QUEUED_FOR_SYNC") && (
            <div className="cc-w-fit cc-font-semibold cc-bg-surface-warning_accent_1 cc-text-warning-600 cc-py-[3px] cc-text-xs cc-px-2 cc-rounded-lg">
              Syncing
            </div>
          )}
        </td>
      )}

      {columnsToDisplay.includes("created_at") && (
        <td className="cc-py-3 cc-px-2 cc-align-top md:cc-hidden cc-table-cell">
          <p className="cc-text-[14px] cc-leading-[24px] cc-font-semibold dark:cc-text-dark-text-white">
            {formatDate(item.created_at)}
          </p>
        </td>
      )}

      {columnsToDisplay.includes("updated_at") && (
        <td className="cc-py-3 cc-px-2 cc-align-top md:cc-hidden cc-table-cell">
          <p className="cc-text-[14px] cc-leading-[24px] cc-font-semibold dark:cc-text-dark-text-white">
            {formatDate(item.updated_at)}
          </p>
        </td>
      )}

      {columnsToDisplay.includes("external_url") && (
        <td className="cc-py-3 cc-px-2 cc-align-top md:cc-hidden cc-table-cell">
          <p
            className="cc-w-[250px] cc-break-all cc-text-[14px] cc-leading-[24px] cc-font-semibold cc-line-clamp-3 dark:cc-text-dark-text-white"
            title={item.external_url || "NA"}
          >
            {item.external_url || "NA"}
          </p>
        </td>
      )}

      {/* Mobile view */}

      <td className="md:!cc-table-cell cc-hidden cc-py-3 cc-px-2 cc-align-top">
        <div>
          <div className="cc-flex cc-items-center">
            <Checkbox
              checked={isChecked}
              className="cc-my-0.5 cc-mr-[8px]"
              onCheckedChange={onSelect}
            />
            {itemType === "FOLDER" && (
              <img
                alt="Folder Icon"
                className="cc-w-5 cc-shrink-0 cc-mr-[8px]"
                src={FolderIcon}
              />
            )}
            {itemType === "FILE" && (
              <img
                alt="File Icon"
                className="cc-w-5 cc-shrink-0 cc-mr-[8px]"
                src={FIleIcon}
              />
            )}
            <p className="cc-w-[100%] cc-break-all cc-text-start cc-text-[#000] cc-text-[14px] cc-font-semibold cc-leadind-[24px] dark:cc-text-dark-text-white">
              {item.name}
            </p>
            <div>
              {columnsToDisplay.includes("status") && (
                <>
                  {item.sync_status === "READY" && (
                    <div className="cc-w-fit cc-bg-surface-success_accent_1 cc-text-success_high_em cc-py-[3px] cc-text-xs cc-px-2 cc-rounded-lg cc-font-semibold">
                      Ready
                    </div>
                  )}
                  {item.sync_status === "SYNC_ERROR" && (
                    <div className="cc-w-fit cc-font-semibold cc-bg-surface-danger_accent_1 cc-text-outline-danger_high_em cc-py-[3px] cc-text-xs cc-px-2 cc-rounded-lg cc-relative error">
                      Error
                      <div className="cc-absolute cc-top-[32px] -cc-left-[107px] error-tooltip cc-z-[1]">
                        <ErrorTooltip
                          leftPosTip={"116"}
                          message={item.sync_error_message}
                        />
                      </div>
                    </div>
                  )}
                  {(item.sync_status === "SYNCING" ||
                    item.sync_status === "QUEUED_FOR_SYNC") && (
                    <div className="cc-w-fit cc-font-semibold cc-bg-surface-warning_accent_1 cc-text-warning-600 cc-py-[3px] cc-text-xs cc-px-2 cc-rounded-lg">
                      Syncing
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="cc-ml-[54px]">
            {columnsToDisplay.includes("created_at") && (
              <div className="cc-my-[4px] ">
                <p className="cc-text-[12px] cc-text-[#8C8A94] cc-leading-[16px] dark:cc-text-dark-text-white cc-font-semibold">
                  {formatDate(item.created_at)}
                </p>
              </div>
            )}
            {columnsToDisplay.includes("updated_at") && (
              <div className="cc-my-[4px] ">
                <p className="cc-text-[12px] cc-text-[#8C8A94] cc-leading-[16px] dark:cc-text-dark-text-white cc-font-semibold">
                  {formatDate(item.updated_at)}
                </p>
              </div>
            )}
            {columnsToDisplay.includes("external_url") && (
              <div>
                <p
                  className="cc-w-full cc-break-all cc-text-[#8C8A94] cc-text-[12px] dark:cc-text-dark-text-white cc-leading-[16px] cc-font-semibold"
                  title={item.external_url || "NA"}
                >
                  {item.external_url || "NA"}
                </p>
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}
