export * from "ce/api/DatasourcesApi";
import API from "api/Api";
import type { ApiResponse } from "api/ApiResponses";
import type { AxiosPromise } from "axios";
import { default as CE_DatasourcesApi } from "ce/api/DatasourcesApi";

class DatasourcesApi extends CE_DatasourcesApi {
  static async fetchProviderData(
    workspaceId: string,
    credentialId: string,
    integrationType: string,
  ): Promise<
    AxiosPromise<ApiResponse<Record<string, string | boolean | number>>>
  > {
    return API.get(
      DatasourcesApi.url +
        `/provider-data?workspaceId=${workspaceId}&credentialId=${credentialId}&integrationType=${integrationType}`,
    );
  }
}

export default DatasourcesApi;
