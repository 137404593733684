import { FocusEntity } from "navigation/FocusEntity";
import type { FocusElementsConfigList } from "ee/navigation/FocusStrategy/types";
import { FocusElement, FocusElementConfigType } from "navigation/FocusElements";
import { getSelectedDatasourceId } from "ee/navigation/FocusSelectors";
import { setSelectedDatasource } from "ee/navigation/AppIDEFocusSetters";
import { getFirstDatasourceId } from "selectors/datasourceSelectors";
import {
  getPluginActionConfigSelectedTab,
  setPluginActionEditorSelectedTab,
} from "PluginActionEditor/store";
import { EDITOR_TABS } from "constants/QueryEditorConstants";
import { PluginPackageName } from "entities/Plugin";
import { API_EDITOR_TABS } from "PluginActionEditor/constants/CommonApiConstants";

export default {
  [FocusEntity.DATASOURCE_LIST]: [
    {
      type: FocusElementConfigType.URL,
      name: FocusElement.SelectedDatasource,
      selector: getSelectedDatasourceId,
      setter: setSelectedDatasource,
      defaultValue: getFirstDatasourceId,
    },
  ],
  [FocusEntity.QUERY]: [
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.PluginActionConfigTabs,
      selector: getPluginActionConfigSelectedTab,
      setter: setPluginActionEditorSelectedTab,
      defaultValue: EDITOR_TABS.QUERY,
      subTypes: {
        [PluginPackageName.GRAPHQL]: {
          defaultValue: API_EDITOR_TABS.BODY,
        },
        [PluginPackageName.REST_API]: {
          defaultValue: API_EDITOR_TABS.HEADERS,
        },
      },
    },
  ],
} as FocusElementsConfigList;
