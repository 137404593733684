import { createImmerReducer } from "utils/ReducerUtils";
import type { ReduxAction } from "actions/ReduxActionTypes";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { EditorEntityTab } from "IDE/Interfaces/EditorTypes";
import { klona } from "klona";
import { get, remove, set } from "lodash";

export const IDETabsDefaultValue = {
  [EditorEntityTab.JS]: [],
  [EditorEntityTab.QUERIES]: [],
};

const initialState: PackageIDEState = {
  tabs: {},
  renameEntity: "",
};

const packageIdeReducer = createImmerReducer(initialState, {
  [ReduxActionTypes.SET_PACKAGE_IDE_TABS]: (
    state: PackageIDEState,
    action: ReduxAction<ParentEntityIDETabs>,
  ) => {
    set(state, "tabs", action.payload);
  },
  [ReduxActionTypes.SET_PACKAGE_IDE_JS_TABS]: (
    state: PackageIDEState,
    action: ReduxAction<{ tabs: string[]; parentId: string }>,
  ) => {
    set(
      state,
      `tabs.${action.payload.parentId}.${EditorEntityTab.JS}`,
      action.payload.tabs,
    );
  },
  [ReduxActionTypes.SET_PACKAGE_IDE_QUERIES_TABS]: (
    state: PackageIDEState,
    action: ReduxAction<{ tabs: string[]; parentId: string }>,
  ) => {
    set(
      state,
      `tabs.${action.payload.parentId}.${EditorEntityTab.QUERIES}`,
      action.payload.tabs,
    );
  },
  [ReduxActionTypes.RESET_EDITOR_REQUEST]: (
    state: PackageIDEState,
  ): PackageIDEState => {
    return klona({
      ...initialState,
      tabs: state.tabs,
    });
  },
  [ReduxActionTypes.CLOSE_JS_MODULE_ACTION_TAB_SUCCESS]: (
    state: PackageIDEState,
    action: ReduxAction<{ id: string; parentId: string }>,
  ) => {
    const tabs = get(
      state,
      ["tabs", action.payload.parentId, EditorEntityTab.JS],
      [] as string[],
    );

    remove(tabs, (tab) => tab === action.payload.id);
  },
  [ReduxActionTypes.CLOSE_QUERY_MODULE_ACTION_TAB_SUCCESS]: (
    state: PackageIDEState,
    action: ReduxAction<{ id: string; parentId: string }>,
  ) => {
    const tabs = get(
      state,
      ["tabs", action.payload.parentId, EditorEntityTab.QUERIES],
      [] as string[],
    );

    remove(tabs, (tab) => tab === action.payload.id);
  },
  [ReduxActionTypes.SET_RENAME_ENTITY]: (
    state: PackageIDEState,
    action: {
      payload: string;
    },
  ) => {
    state.renameEntity = action.payload;
  },
});

export interface PackageIDEState {
  tabs: ParentEntityIDETabs;
  renameEntity: string;
}

export interface ParentEntityIDETabs {
  [parentId: string]: PackageIDETabs;
}

export interface PackageIDETabs {
  [EditorEntityTab.JS]: string[];
  [EditorEntityTab.QUERIES]: string[];
}

export default packageIdeReducer;
