import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import WorkflowApi from "ee/api/WorkflowApi";
import { EMPTY_RESPONSE } from "components/editorComponents/emptyResponse";
import { createActionExecutionResponse } from "sagas/ActionExecution/PluginActionSagaUtils";
import type { ResolveHITLQueryPayload } from "ee/PluginActionEditor/components/PostActionRun/types";
import type { EventLocation } from "ee/utils/analyticsUtilTypes";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { getPluginIdOfPackageName } from "sagas/selectors";
import { PluginPackageName } from "entities/Plugin";
import { checkAndGetPluginFormConfigsSaga } from "sagas/PluginSagas";
import { DEFAULT_DATASOURCE_NAME } from "PluginActionEditor/constants/ApiEditorConstants";
import { ActionParentEntityType } from "ee/entities/Engine/actionHelpers";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { ActionDataState } from "ee/reducers/entityReducers/actionsReducer";
import { getActions } from "ee/selectors/entitiesSelector";
import { createNewHITLQueryName } from "ee/utils/workflowHelpers";
import type { ActionConfig, ApiAction } from "entities/Action";
import {
  createActionRequest,
  executePluginActionSuccess,
  updateActionData,
} from "actions/pluginActionActions";
import type { ActionExecutionResponse } from "api/ActionAPI";
import { validateResponse } from "sagas/ErrorSagas";
import { PluginActionExecutionError } from "sagas/ActionExecution/errorUtils";
import { getPluginActionDefaultValues } from "sagas/ActionSagas";
import { merge } from "lodash";
import type { ApiResponse } from "api/types";
import type { GenerateWorkflowApprovalPageResponse } from "ee/pages/GenerateWorkflowApprovalPage/types";
import {
  closeGenerateApprovalPageModal,
  generateApprovalPageError,
  generateApprovalPageSuccess,
} from "ee/pages/GenerateWorkflowApprovalPage/store/generateWorkflowApprovalPageActions";
import { toast } from "@appsmith/ads";
import { IDE_TYPE, type IDEType } from "ee/IDE/Interfaces/IDETypes";
import { error } from "loglevel";

// TODO: Change the default payload once the server work is completed
export function* createDefaultHITLQueryPayload(props: {
  workflowId: string;
  newActionName: string;
  from: EventLocation;
}) {
  const workspaceId: string = yield select(getCurrentWorkspaceId);
  const { from, newActionName, workflowId } = props;
  const pluginId: string = yield select(
    getPluginIdOfPackageName,
    PluginPackageName.WORKFLOW,
  );

  yield call(checkAndGetPluginFormConfigsSaga, pluginId);

  const pluginDefaultValues: { actionConfiguration: ActionConfig } = yield call(
    getPluginActionDefaultValues,
    pluginId,
  );

  const actionConfiguration = merge(
    {
      timeoutInMillisecond: 10000,
      formData: {
        workflowId: {
          data: workflowId,
        },
      },
    },
    pluginDefaultValues.actionConfiguration,
  );

  return {
    actionConfiguration,
    name: newActionName,
    datasource: {
      name: DEFAULT_DATASOURCE_NAME,
      pluginId,
      workspaceId,
      datasourceConfiguration: {},
    },
    eventData: {
      actionType: "WORKFLOWS",
      from: from,
    },
    contextType: ActionParentEntityType.WORKFLOW,
  };
}

export function* createHITLQuery(
  action: ReduxAction<{ workflowId: string; from: EventLocation }>,
) {
  const { from, workflowId } = action.payload;

  if (workflowId) {
    const actions: ActionDataState = yield select(getActions);
    const newActionName = createNewHITLQueryName(actions, workflowId || "");
    // Note: Do NOT send pluginId on top level here.
    // It breaks embedded rest datasource flow.

    const createApiActionPayload: Partial<ApiAction> = yield call(
      createDefaultHITLQueryPayload,
      {
        workflowId,
        from,
        newActionName,
      },
    );

    yield put(
      createActionRequest({
        ...createApiActionPayload,
        workflowId,
      }),
    );
  }
}

export function* resolveHITLQuery(
  action: ReduxAction<ResolveHITLQueryPayload>,
) {
  const { actionId, baseActionId } = action.payload;

  let responsePayload = EMPTY_RESPONSE;
  let response: ActionExecutionResponse;

  try {
    const apiResponse: ActionExecutionResponse =
      yield WorkflowApi.resolveHITLQuery(action.payload);

    yield validateResponse(apiResponse);

    if (actionId.length > 0) {
      response = {
        ...apiResponse,
        clientMeta: {
          duration: "",
          size: "",
        },
        data: {
          headers: {},
          statusCode: "200 OK",
          request: {
            headers: {},
            httpMethod: "PUT",
            body: { payload: action.payload },
            url: "",
          },
          body: apiResponse.data,
          isExecutionSuccess: true,
          dataTypes: [
            {
              dataType: "JSON",
            },
          ],
        },
      };
      responsePayload = {
        ...createActionExecutionResponse(response),
        responseDisplayFormat: "JSON",
      };

      yield put(
        executePluginActionSuccess({
          id: actionId,
          baseId: baseActionId,
          response: responsePayload,
          isActionCreatedInApp: true,
        }),
      );

      yield put(
        updateActionData([
          {
            entityName: PluginPackageName.WORKFLOW,
            dataPath: "data",
            data: responsePayload.body,
          },
        ]),
      );
    }
  } catch (error) {
    if (actionId.length > 0) {
      yield put(
        executePluginActionSuccess({
          id: actionId,
          baseId: baseActionId,
          response: EMPTY_RESPONSE,
          isActionCreatedInApp: true,
        }),
      );
      yield put(
        updateActionData([
          {
            entityName: PluginPackageName.WORKFLOW,
            dataPath: "data",
            data: EMPTY_RESPONSE.body,
          },
        ]),
      );

      throw new PluginActionExecutionError("Response not valid", false);
    }
  }
}
export function* generateApprovalPage(
  action: ReduxAction<{
    workflowId: string;
    applicationId: string;
    sourceIDEType: IDEType;
  }>,
) {
  const { applicationId, sourceIDEType, workflowId } = action.payload;
  const workspaceId: string = yield select(getCurrentWorkspaceId);

  try {
    const apiResponse: ApiResponse<GenerateWorkflowApprovalPageResponse> =
      yield WorkflowApi.generateApprovalPage({
        workspaceId,
        workflowId,
        applicationId,
      });

    yield validateResponse(apiResponse);
    yield put(generateApprovalPageSuccess());
    toast.show(`Approval page generated successfully`, {
      kind: "success",
    });
    // close the modal
    yield put(closeGenerateApprovalPageModal());
    const pageId = apiResponse.data.id;
    const pageUrl = `/applications/${applicationId}/pages/${pageId}/edit`;

    if (sourceIDEType === IDE_TYPE.Workflow) {
      // open the new page in a new tab
      window.open(pageUrl, "_blank");
    } else {
      // navigate to the new page in the same tab
      window.open(pageUrl, "_self");
    }
  } catch (err) {
    yield put(generateApprovalPageError());
    error("Error generating approval page", err);
  }
}

export default function* WorkflowsHITLSagas() {
  yield all([
    takeLatest(ReduxActionTypes.CREATE_HITL_QUERY_IN_WORKFLOW, createHITLQuery),
    takeLatest(
      ReduxActionTypes.TRIGGER_HITL_QUERY_RESOLUTION_INIT,
      resolveHITLQuery,
    ),
    takeLatest(
      ReduxActionTypes.GENERATE_APPROVAL_PAGE_INIT,
      generateApprovalPage,
    ),
  ]);
}
