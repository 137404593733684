import React, { useState } from "react";
import type { ActiveStep, ProcessedIntegration } from "../../typing/shared";
import type { IntegrationAPIResponse } from "../IntegrationModal";
import GithubAuthScreen from "./GithubAuthScreen";
import GithubRepoScreen from "./GithubRepoScreen";

function GithubScreen({
  activeIntegrations,
  dataSource,
  processedIntegration,
  setActiveStep,
  setPauseDataSourceSelection,
  setSelectedDataSource,
  setShowAdditionalStep,
  setShowFilePicker,
}: {
  setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>;
  processedIntegration: ProcessedIntegration;
  activeIntegrations: IntegrationAPIResponse[];
  setShowFilePicker: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAdditionalStep: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDataSource: React.Dispatch<
    React.SetStateAction<IntegrationAPIResponse | null>
  >;
  setPauseDataSourceSelection: React.Dispatch<React.SetStateAction<boolean>>;
  dataSource: IntegrationAPIResponse | null;
}) {
  const [step, setStep] = useState("credentials");
  const [username, setUsername] = useState("");

  if (step == "credentials") {
    return (
      <GithubAuthScreen
        processedIntegration={processedIntegration}
        setStep={setStep}
        setUsername={setUsername}
        username={username}
      />
    );
  } else {
    return (
      <GithubRepoScreen
        activeIntegrations={activeIntegrations}
        dataSource={dataSource}
        processedIntegration={processedIntegration}
        setActiveStep={setActiveStep}
        setPauseDataSourceSelection={setPauseDataSourceSelection}
        setSelectedDataSource={setSelectedDataSource}
        setShowAdditionalStep={setShowAdditionalStep}
        setShowFilePicker={setShowFilePicker}
        username={username}
      />
    );
  }
}

export default GithubScreen;
