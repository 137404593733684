export * from "ce/selectors/aiAgentSelectors";

import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import type { AppState } from "ee/reducers";
import { selectFeatureFlagCheck } from "ce/selectors/featureFlagsSelectors";
import { createSelector } from "reselect";
import type { ActionDataState } from "ee/reducers/entityReducers/actionsReducer";
import { PluginPackageName, type Plugin } from "entities/Plugin";
import { getActions, getPluginByPackageName } from "./entitiesSelector";

export const getIsCreatingAiAgent = (state: AppState) => {
  return state.entities.aiAgents.isCreatingAgentInWorkspace;
};

export const getIsAiAgentFlowEnabled = (state: AppState) => {
  return selectFeatureFlagCheck(state, FEATURE_FLAG.license_ai_agent_enabled);
};

export const getAgentChatQueries = createSelector(
  (state: AppState) =>
    getPluginByPackageName(state, PluginPackageName.APPSMITH_AI),
  getActions,
  (plugin: Plugin | undefined, actions: ActionDataState) => {
    if (!plugin) return [];

    return actions.filter(
      (action) =>
        action.config.pluginId === plugin.id &&
        action.config.actionConfiguration.formData.usecase.data ===
          "AI_CHAT_ASSISTANT",
    );
  },
);

export const getIsFCIntegrationsEnabled = (state: AppState) => {
  return state.entities.aiAgents.isFCIntegrationsEnabled;
};

export const getAgentChatQuery = (actionId: string) =>
  createSelector(getAgentChatQueries, (queries) =>
    queries.find((query) => query.config.id === actionId),
  );
