import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";

interface CreateAiAgentFromWorkspacePayload {
  workspaceId: string;
}

export const createAiAgentFromWorkspace = (
  payload: CreateAiAgentFromWorkspacePayload,
) => {
  return {
    type: ReduxActionTypes.CREATE_AI_AGENT_FROM_WORKSPACE_INIT,
    payload,
  };
};

export const createAiAgentFromWorkspaceSuccess = () => {
  return {
    type: ReduxActionTypes.CREATE_AI_AGENT_FROM_WORKSPACE_SUCCESS,
  };
};

export const createAiAgentFromWorkspaceError = (error: string) => {
  return {
    type: ReduxActionErrorTypes.CREATE_AI_AGENT_FROM_WORKSPACE_ERROR,
    payload: { error },
  };
};

interface CreateDatasourceAndSearchQueriesForAiAgentPayload {
  integrationName: string;
  pluginName: string;
  command: string;
  query: string;
}

export const createDatasourceAndSearchQueriesForAiAgent = (
  payload: CreateDatasourceAndSearchQueriesForAiAgentPayload,
) => {
  return {
    type: ReduxActionTypes.CREATE_DATASOURCE_AND_SEARCH_QUERIES_FOR_AI_AGENT_INIT,
    payload,
  };
};

export const createDatasourceAndSearchQueriesForAiAgentSuccess = () => {
  return {
    type: ReduxActionTypes.CREATE_DATASOURCE_AND_SEARCH_QUERIES_FOR_AI_AGENT_SUCCESS,
    payload: {},
  };
};

export const openCarbonModal = (payload: { shouldOpen: boolean }) => {
  return {
    type: ReduxActionTypes.OPEN_CARBON_MODAL,
    payload,
  };
};

export const setInstallingIntegrationName = (payload: {
  integrationName: string | null;
}) => {
  return {
    type: ReduxActionTypes.INSTALLING_INTEGRATION_NAME,
    payload,
  };
};

export const toggleFCIntegrations = (payload: { isEnabled: boolean }) => {
  return {
    type: ReduxActionTypes.TOGGLE_FC_INTEGRATIONS,
    payload,
  };
};
