import type { MouseEventHandler } from "react";
import React from "react";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import type { ContainerStyle } from "../component";
import ContainerComponent from "../component";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import { startCase, camelCase } from "lodash";
import WidgetsMultiSelectBox from "layoutSystems/fixedlayout/common/widgetGrouping/WidgetsMultiSelectBox";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import { getSnappedGrid } from "sagas/WidgetOperationUtils";
import {
  isAutoHeightEnabledForWidget,
  isAutoHeightEnabledForWidgetWithLimits,
} from "widgets/WidgetUtils";
import {
  type AnvilConfig,
  type AutocompletionDefinitions,
  type AutoLayoutConfig,
  type WidgetBaseConfiguration,
  type WidgetDefaultProps,
} from "WidgetProvider/constants";
import IconSVG from "../icon.svg";
import ThumbnailSVG from "../thumbnail.svg";
import { FILL_WIDGET_MIN_WIDTH } from "constants/minWidthConstants";
import {
  FlexVerticalAlignment,
  Positioning,
  ResponsiveBehavior,
} from "layoutSystems/common/utils/constants";
import { renderAppsmithCanvas } from "layoutSystems/CanvasFactory";
import type { Module } from "ee/constants/ModuleConstants";
import { klona } from "klona";
import styled from "styled-components";
import { renderChildren } from "layoutSystems/common/utils/canvasUtils";
import { GridDefaults, RenderModes } from "constants/WidgetConstants";

const SkeletonWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

function toPascalCase(str: string): string {
  return startCase(camelCase(str)).replace(/\s/g, "");
}

const DEFAULT_EMPTY_INPUTS_CONFIG = [
  {
    sectionName: "General",
    children: [],
  },
];

export interface ModuleWidgetFactoryProps {
  moduleUUID: string;
  rows: number;
  columns: number;
  widgetName?: string;
  packageName?: string;
  inputsForm?: Module["inputsForm"];
}

export function moduleWidgetFactory({
  columns,
  inputsForm,
  moduleUUID,
  packageName,
  rows,
  widgetName,
}: ModuleWidgetFactoryProps) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return class ModuleWidget extends BaseWidget<
    ModuleWidgetProps<WidgetProps>,
    WidgetState
  > {
    // make this dynamic
    static type = "MODULE_WIDGET_" + moduleUUID;

    constructor(props: ModuleWidgetProps<WidgetProps>) {
      super(props);
    }

    static getConfig(): WidgetBaseConfiguration {
      return {
        name: widgetName || "",
        iconSVG: IconSVG,
        thumbnailSVG: ThumbnailSVG,
        tags: [],
        isCanvas: true,
        searchTags: [packageName || ""],
        hideCard: true,
      };
    }

    static getFeatures() {
      return {};
    }

    static getMethods() {
      return {};
    }

    static getDefaults(): WidgetDefaultProps {
      return {
        rows,
        columns,
        widgetName: toPascalCase(widgetName || ""),
        animateLoading: true,
        children: [],
        blueprint: {},
        version: 1,
        flexVerticalAlignment: FlexVerticalAlignment.Stretch,
        responsiveBehavior: ResponsiveBehavior.Fill,
        minWidth: FILL_WIDGET_MIN_WIDTH,
        isModuleWidget: true,
        moduleUUID,
        positioning: Positioning.Fixed,
        hasMetaWidgets: true,
      };
    }

    static getAutoLayoutConfig(): AutoLayoutConfig {
      return {
        widgetSize: [
          {
            viewportMinWidth: 0,
            configuration: () => {
              return {
                minWidth: "280px",
                minHeight: "50px",
              };
            },
          },
        ],
        disableResizeHandles: (props: ModuleWidgetProps<WidgetProps>) => ({
          // Disables vertical resize handles for all container widgets except for the List item container
          vertical: !props.isListItemContainer,
        }),
      };
    }

    static getAnvilConfig(): AnvilConfig | null {
      return {
        isLargeWidget: false,
        widgetSize: {
          maxHeight: {},
          maxWidth: {},
          minHeight: { base: "50px" },
          minWidth: { base: "280px" },
        },
      };
    }

    static getAutocompleteDefinitions(): AutocompletionDefinitions {
      // Use inputs from module definition to autocomplete
      return {
        "!doc":
          "Containers are used to group widgets together to form logical higher order widgets. Containers let you organize your page better and move all the widgets inside them together.",
        "!url": "https://docs.appsmith.com/widget-reference/container",
      };
    }

    static getSetterConfig(): SetterConfig | null {
      return null;
    }

    static getPropertyPaneContentConfig() {
      if (!inputsForm) {
        return DEFAULT_EMPTY_INPUTS_CONFIG;
      }

      // Use inputs from module definition to create property pane
      const inputs = klona(inputsForm);

      inputs[0].sectionName = "Inputs";

      return inputs;
    }

    static getDerivedPropertiesMap(): DerivedPropertiesMap {
      return {};
    }
    static getDefaultPropertiesMap(): Record<string, string> {
      return {};
    }

    static getMetaPropertiesMap(): Record<string, unknown> {
      return {};
    }

    static getStylesheetConfig(): Stylesheet {
      return {};
    }

    getSnapSpaces = () => {
      const { componentWidth } = this.props;
      const { snapGrid } = getSnappedGrid(this.props, componentWidth);

      return snapGrid;
    };

    renderChildWidget(childWidgetData: WidgetProps): React.ReactNode {
      const childWidget = { ...childWidgetData };

      const { componentHeight, componentWidth } = this.props;

      childWidget.rightColumn = componentWidth;
      childWidget.bottomRow = this.props.shouldScrollContents
        ? childWidget.bottomRow
        : componentHeight;
      childWidget.minHeight = componentHeight;
      childWidget.shouldScrollContents = false;
      childWidget.canExtend = this.props.shouldScrollContents;

      childWidget.parentId = this.props.widgetId;
      // Pass layout controls to children
      childWidget.positioning =
        childWidget?.positioning || this.props.positioning;
      childWidget.useAutoLayout = this.props.positioning
        ? this.props.positioning === Positioning.Vertical
        : false;

      return renderAppsmithCanvas(childWidget as WidgetProps);
    }

    renderChildren = () => {
      const { snapColumnSpace } = this.getSnapSpaces();
      const layoutSystemProps = {
        parentColumnSpace: snapColumnSpace,
        parentRowSpace: GridDefaults.DEFAULT_GRID_ROW_HEIGHT,
      };
      const defaultWidgetProps = {
        positioning: this.props.positioning,
      };

      const modalWidgetProps = this.props.metaWidgetChildrenStructure?.filter(
        (child) => child.type === "MODAL_WIDGET",
      );

      const modalWidgets = renderChildren(
        modalWidgetProps as WidgetProps[],
        "0",
        RenderModes.PAGE,
        defaultWidgetProps,
        layoutSystemProps,
        true,
      );

      const nonModalWidgets =
        this.props.metaWidgetChildrenStructure
          ?.filter((child) => child.type !== "MODAL_WIDGET")
          .map((child) => {
            return this.renderChildWidget(child as WidgetProps);
          }) || [];

      return [...modalWidgets, ...nonModalWidgets];
    };

    renderAsContainerComponent(props: ModuleWidgetProps<WidgetProps>) {
      const isAutoHeightEnabled: boolean =
        isAutoHeightEnabledForWidget(this.props) &&
        !isAutoHeightEnabledForWidgetWithLimits(this.props) &&
        this.props.positioning !== Positioning.Vertical;

      return (
        <ContainerComponent
          key={props.widgetId}
          {...props}
          noScroll={isAutoHeightEnabled}
        >
          <WidgetsMultiSelectBox
            {...this.getSnapSpaces()}
            noContainerOffset={!!props.noContainerOffset}
            widgetId={this.props.widgetId}
            widgetType={this.props.type}
          />
          {/* without the wrapping div onClick events are triggered twice */}
          <>{this.renderChildren()}</>
        </ContainerComponent>
      );
    }

    getWidgetView() {
      if (
        !this.props.metaWidgetChildrenStructure ||
        this.props.metaWidgetChildrenStructure.length === 0
      ) {
        return <SkeletonWrapper className="bp3-skeleton" />;
      }

      return this.renderAsContainerComponent(this.props);
    }
  };
}

export interface ModuleWidgetProps<T extends WidgetProps> extends WidgetProps {
  children?: T[];
  containerStyle?: ContainerStyle;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onClickCapture?: MouseEventHandler<HTMLDivElement>;
  shouldScrollContents?: boolean;
  noPad?: boolean;
  moduleInstanceId?: string;
  moduleUUID?: string;
}

export default moduleWidgetFactory;
