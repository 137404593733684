export * from "ce/sagas/organizationSagas";
import { updateOrganizationConfigSaga } from "ce/sagas/organizationSagas";
import type { ReduxAction } from "actions/ReduxActionTypes";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  take,
  takeLatest,
} from "redux-saga/effects";
import type { ApiResponse, APIResponseError } from "api/ApiResponses";
import { OrganizationApi } from "ee/api/OrganizationApi";
import { validateResponse } from "sagas/ErrorSagas";
import history from "utils/history";
import type { OrganizationReduxState } from "ee/reducers/organizationReducer";
import type { License } from "ee/reducers/organizationReducer.types";
import localStorage from "utils/localStorage";
import {
  ADMIN_SETTINGS_PATH,
  BUILDER_PATH,
  BUILDER_PATH_DEPRECATED,
  LICENSE_CHECK_PATH,
  MIGRATIONS_URL,
  PAGE_NOT_FOUND_URL,
  SETUP,
  USER_AUTH_URL,
  VIEWER_PATH,
  VIEWER_PATH_DEPRECATED,
} from "constants/routes";
import { matchPath } from "react-router";
import { SettingCategories } from "ee/pages/AdminSettings/config/types";
import { toast } from "@appsmith/ads";
import {
  createMessage,
  LICENSE_REFRESHED_SUCCESSFULLY,
} from "ee/constants/messages";
import {
  showLicenseModal,
  showRemoveLicenseModal,
  showDowngradeLicenseModal,
} from "ee/actions/organizationActions";
import { firstTimeUserOnboardingInit } from "actions/onboardingActions";
import {
  LICENSE_MODIFICATION,
  LICENSE_PLANS,
} from "ee/pages/Billing/Types/types";
import { getIsSafeRedirectURL } from "utils/helpers";
import { ERROR_CODES } from "ee/constants/ApiConstants";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import { getFromServerWhenNoPrefetchedResult } from "sagas/helper";
import { fetchFeatureFlags } from "./userSagas";

export function* fetchCurrentOrganizationConfigSaga(action?: {
  payload?: {
    organizationConfig?: ApiResponse<OrganizationReduxState<License>>;
  };
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): any {
  try {
    const license = action?.payload?.organizationConfig;
    const response: ApiResponse<OrganizationReduxState<License>> = yield call(
      getFromServerWhenNoPrefetchedResult,
      license,
      () => call(OrganizationApi.fetchCurrentOrganizationConfig),
    );

    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      // TODO: Fix this the next time the file is edited
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = response.data as any;

      // If the organization config is not present, we need to set the default config
      yield put({
        type: ReduxActionTypes.FETCH_CURRENT_ORGANIZATION_CONFIG_SUCCESS,
        payload: {
          ...data,
          tenantId: data.id,
        },
      });

      AnalyticsUtil.initInstanceId(data.instanceId);

      AnalyticsUtil.initLicense(data.organizationConfiguration.license);
    }
  } catch (error) {
    const errorObj = error as APIResponseError;

    yield put({
      type: ReduxActionErrorTypes.FETCH_CURRENT_ORGANIZATION_CONFIG_ERROR,
      payload: {
        errorObj,
      },
    });
    yield put({
      type: ReduxActionTypes.SAFE_CRASH_APPSMITH_REQUEST,
      payload: {
        code: errorObj?.code ?? ERROR_CODES.SERVER_ERROR,
      },
    });
  }
}

export function* startLicenseStatusCheckSaga() {
  const urlObject = new URL(window.location.href);
  const redirectUrl = urlObject?.searchParams.get("redirectUrl");
  const shouldEnableFirstTimeUserOnboarding = urlObject?.searchParams.get(
    "enableFirstTimeUserExperience",
  );

  while (true) {
    try {
      const response: ApiResponse<OrganizationReduxState<License>> = yield call(
        OrganizationApi.fetchCurrentOrganizationConfig,
      );
      const isValidResponse: boolean = yield validateResponse(response);

      if (isValidResponse) {
        // TODO: Fix this the next time the file is edited
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data = response.data as any;

        yield put({
          type: ReduxActionTypes.FETCH_CURRENT_ORGANIZATION_CONFIG_SUCCESS,
          payload: {
            ...data,
            tenantId: data.id,
          },
        });
      }

      const isLicenseActive =
        response.data?.organizationConfiguration?.license?.active;
      const isLicenseFree =
        response.data?.organizationConfiguration?.license?.plan ===
        LICENSE_PLANS.FREE;

      if (!isLicenseActive && !isLicenseFree) {
        yield put({ type: ReduxActionTypes.STOP_LICENSE_STATUS_CHECK });

        if (redirectUrl) {
          history.replace(
            `${LICENSE_CHECK_PATH}?redirectUrl=${redirectUrl}&enableFirstTimeUserExperience=${shouldEnableFirstTimeUserOnboarding}}`,
          );
        } else {
          history.replace(LICENSE_CHECK_PATH);
        }
      }
    } catch (error) {
      const errorObj = error as APIResponseError;

      yield put({
        type: ReduxActionErrorTypes.FETCH_CURRENT_ORGANIZATION_CONFIG_ERROR,
        payload: {
          errorObj,
        },
      });

      yield put({
        type: ReduxActionTypes.SAFE_CRASH_APPSMITH_REQUEST,
        payload: {
          code: errorObj?.code ?? ERROR_CODES.SERVER_ERROR,
        },
      });
    }
    yield delay(60 * 60 * 1000);
  }
}

export function* validateLicenseSaga(
  action: ReduxAction<{
    isUserOnboarding: boolean;
    key: string;
  }>,
  // TODO: Fix this the next time the file is edited
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const urlObject = new URL(window.location.href);
  const redirectUrl =
    urlObject?.searchParams.get("redirectUrl") ?? "/applications";
  const shouldEnableFirstTimeUserOnboarding = urlObject?.searchParams.get(
    "enableFirstTimeUserExperience",
  );
  const adminSettingsPath = `${ADMIN_SETTINGS_PATH}/${SettingCategories.BILLING}`;
  const shouldRedirectOnUpdate = urlObject?.pathname !== adminSettingsPath;

  try {
    const response = yield call(
      action?.payload?.isUserOnboarding
        ? OrganizationApi.validateLicenseForOnboarding
        : OrganizationApi.validateLicense,
      action?.payload.key,
    );
    const isValidResponse: boolean = yield validateResponse(response);
    const license = response?.data?.organizationConfiguration?.license;

    if (isValidResponse) {
      if (response?.data && action?.payload?.isUserOnboarding) {
        location.href = location.origin + response?.data;
      }

      if (license?.active) {
        if (shouldEnableFirstTimeUserOnboarding) {
          let urlObj;

          try {
            urlObj = new URL(redirectUrl);
          } catch (e) {}
          const match = matchPath<{
            basePageId: string;
            applicationId: string;
          }>(urlObj?.pathname ?? redirectUrl, {
            path: [
              BUILDER_PATH,
              BUILDER_PATH_DEPRECATED,
              VIEWER_PATH,
              VIEWER_PATH_DEPRECATED,
            ],
            strict: false,
            exact: false,
          });
          const { applicationId, basePageId } = match?.params || {};

          if (applicationId || basePageId) {
            yield put(
              firstTimeUserOnboardingInit(applicationId, basePageId as string),
            );
          }
        } else if (
          shouldRedirectOnUpdate &&
          getIsSafeRedirectURL(redirectUrl)
        ) {
          window.location.assign(redirectUrl);
        }
      }

      initLicenseStatusCheckSaga();
      yield put({
        type: ReduxActionTypes.VALIDATE_LICENSE_KEY_SUCCESS,
        payload: response.data,
      });

      if (!shouldRedirectOnUpdate) {
        if (
          response?.data?.organizationConfiguration?.migrationStatus !==
          "COMPLETED"
        ) {
          history.replace(MIGRATIONS_URL);
        } else {
          window.location.reload();
        }
      }
    } else {
      yield put({
        type: ReduxActionErrorTypes.VALIDATE_LICENSE_KEY_ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.VALIDATE_LICENSE_KEY_ERROR,
      payload: {
        error,
      },
    });
  }
}

/**
 * This saga is used to cache the branding related config in the local storage
 * @param action
 */
// TODO: Fix this the next time the file is edited
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cacheOrganizationConfigSaga(action: ReduxAction<any>) {
  //We cache only branding related information in the local storage
  localStorage.setItem(
    "organizationConfig",
    JSON.stringify({
      brandColors: action.payload.organizationConfiguration.brandColors,
      brandFaviconUrl: action.payload.organizationConfiguration.brandFaviconUrl,
      brandLogoUrl: action.payload.organizationConfiguration.brandLogoUrl,
    }),
  );
}

export function* initLicenseStatusCheckSaga(): unknown {
  const url = new URL(window.location.href);

  const skipLicenseCheck =
    url.pathname.includes(USER_AUTH_URL) ||
    url.pathname.includes(SETUP) ||
    url.pathname.includes(PAGE_NOT_FOUND_URL);

  if (!skipLicenseCheck) {
    yield delay(60 * 60 * 1000);
    const task = yield fork(startLicenseStatusCheckSaga);

    yield take(ReduxActionTypes.STOP_LICENSE_STATUS_CHECK);
    yield cancel(task);
  }
}

export function* refreshLicenseSaga() {
  try {
    const response: ApiResponse<OrganizationReduxState<License>> = yield call(
      OrganizationApi.refreshLicense,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield call(fetchFeatureFlags);
      yield put({
        type: ReduxActionTypes.REFRESH_LICENSE_SUCCESS,
        payload: response.data,
      });
      toast.show(createMessage(LICENSE_REFRESHED_SUCCESSFULLY), {
        kind: "success",
      });
    } else {
      yield put({
        type: ReduxActionErrorTypes.REFRESH_LICENSE_ERROR,
        payload: {
          error: response.responseMeta.error,
        },
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.REFRESH_LICENSE_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* removeLicenseSaga() {
  try {
    const response: ApiResponse<OrganizationReduxState<License>> = yield call(
      OrganizationApi.removeLicense,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      history.replace(MIGRATIONS_URL);
      yield put({
        type: ReduxActionTypes.REMOVE_LICENSE_SUCCESS,
        payload: response.data,
      });
      yield put(showRemoveLicenseModal(false));
    } else {
      yield put({
        type: ReduxActionErrorTypes.REMOVE_LICENSE_ERROR,
        payload: {
          error: response.responseMeta.error,
        },
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.REMOVE_LICENSE_ERROR,
      payload: {
        error,
      },
    });
  }
}

export function* validateLicenseDryRunSaga(
  action: ReduxAction<{
    key: string;
  }>,
) {
  try {
    const response: ApiResponse<OrganizationReduxState<License>> = yield call(
      OrganizationApi.validateLicenseDryRun,
      action.payload.key,
    );
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      yield put({
        type: ReduxActionTypes.VALIDATE_LICENSE_KEY_DRY_RUN_SUCCESS,
        payload: response.data,
      });

      if (
        response.data?.organizationConfiguration?.license?.changeType ===
        LICENSE_MODIFICATION.DOWNGRADE
      ) {
        yield put(showLicenseModal(false));
        yield put(showDowngradeLicenseModal(true));
      } else {
        yield put({
          type: ReduxActionTypes.VALIDATE_LICENSE_KEY,
          payload: {
            key: action.payload.key,
            isUserOnboarding: false,
          },
        });
      }
    } else {
      yield put({
        type: ReduxActionErrorTypes.VALIDATE_LICENSE_KEY_DRY_RUN_ERROR,
        payload: {
          error: response.responseMeta.error,
        },
      });
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.VALIDATE_LICENSE_KEY_DRY_RUN_ERROR,
      payload: {
        error,
      },
    });
  }
}

export default function* organizationSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.FETCH_CURRENT_ORGANIZATION_CONFIG,
      fetchCurrentOrganizationConfigSaga,
    ),
    takeLatest(
      ReduxActionTypes.UPDATE_ORGANIZATION_CONFIG,
      updateOrganizationConfigSaga,
    ),
    takeLatest(ReduxActionTypes.VALIDATE_LICENSE_KEY, validateLicenseSaga),
    takeLatest(
      ReduxActionTypes.FETCH_USER_DETAILS_SUCCESS,
      initLicenseStatusCheckSaga,
    ),
    takeLatest(
      ReduxActionTypes.FETCH_CURRENT_ORGANIZATION_CONFIG_SUCCESS,
      cacheOrganizationConfigSaga,
    ),
    takeLatest(ReduxActionTypes.REFRESH_LICENSE_INIT, refreshLicenseSaga),
    takeLatest(ReduxActionTypes.REMOVE_LICENSE_INIT, removeLicenseSaga),
    takeLatest(
      ReduxActionTypes.VALIDATE_LICENSE_KEY_DRY_RUN_INIT,
      validateLicenseDryRunSaga,
    ),
    // takeLatest(
    //   ReduxActionTypes.UPDATE_ORGANIZATION_DOMAIN_CONFIG_INIT,
    //   updateOrganizationDomainConfigSaga,
    // ),
  ]);
}
