import { Carbon } from "carbon-typescript-sdk";
import type { RagAccessTokenRequestDto } from "../../../types/rag.dto";
import { validateApiPath } from "@appsmith/utils";
import { generateRagPartitionString as generateCustomerId } from "../../../generators/ragGenerators";
export const DEFAULT_CARBON_API_BASE_PATH = "https://carbon.appsmith.com";

export class CarbonClient {
  public static getClient(
    ragAccessTokenRequest: RagAccessTokenRequestDto,
  ): Carbon {
    const carbonApiKey: string = process.env.APPSMITH_CARBON_API_KEY || "";
    const carbonApiBasePath: string | undefined =
      process.env.APPSMITH_CARBON_API_BASE_PATH || DEFAULT_CARBON_API_BASE_PATH;

    return new Carbon({
      basePath: validateApiPath(carbonApiBasePath),
      apiKey: carbonApiKey,
      customerId: generateCustomerId(ragAccessTokenRequest),
    });
  }

  public static getClientWithCustomerId(customerId: string): Carbon {
    const carbonApiKey: string = process.env.APPSMITH_CARBON_API_KEY || "";
    const carbonApiBasePath: string | undefined =
      process.env.APPSMITH_CARBON_API_BASE_PATH || DEFAULT_CARBON_API_BASE_PATH;

    return new Carbon({
      basePath: validateApiPath(carbonApiBasePath),
      apiKey: carbonApiKey,
      customerId,
    });
  }

  public static async makeRequest(
    ragAccessTokenRequest: RagAccessTokenRequestDto,
    additionalUri: string,
    payload?: any,
    method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  ) {
    const carbonApiKey: string = process.env.APPSMITH_CARBON_API_KEY || "";
    const carbonApiBasePath: string | undefined =
      process.env.APPSMITH_CARBON_API_BASE_PATH || DEFAULT_CARBON_API_BASE_PATH;

    const baseUrl = validateApiPath(carbonApiBasePath);
    const url = `${baseUrl}${additionalUri}`;

    try {
      const response = await fetch(url, {
        method,
        headers: this.generateHeaders(carbonApiKey, ragAccessTokenRequest),
        body: payload ? JSON.stringify(payload) : undefined,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();

      return responseData;
    } catch (error) {
      throw error;
    }
  }

  private static generateHeaders(
    carbonApiKey: string,
    ragAccessTokenRequest: RagAccessTokenRequestDto,
  ) {
    return {
      Authorization: `Bearer ${carbonApiKey}`,
      "Content-Type": "application/json",
      "customer-id": generateCustomerId(ragAccessTokenRequest),
    };
  }
}
