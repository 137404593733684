import React from "react";
import { ColorRing } from "react-loader-spinner";

// eslint-disable-next-line react/prop-types
const Loader = ({ height = 50, width = 50 }) => {
  return (
    <div className="cc-flex cc-justify-center cc-items-center cc-h-auto cc-absolute cc-left-1/2 cc-top-2/4 -cc-translate-x-1/2 -cc-translate-y-1/2">
      <ColorRing
        ariaLabel="color-ring-loading"
        colors={["#0BABFB", "#0BABFB", "#0BABFB", "#0BABFB", "#0BABFB"]}
        height={height}
        visible
        width={width}
        wrapperClass="color-ring-wrapper"
        wrapperStyle={{}}
      />
    </div>
  );
};

export default Loader;
