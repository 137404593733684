import { getAllModules } from "ee/selectors/modulesSelector";
import { getCurrentPackage } from "ee/selectors/packageSelectors";
import { hasCreateModulePermission } from "ee/utils/permissionHelpers";
import EntityAddButton from "pages/Editor/Explorer/Entity/AddButton";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ListItemContainer, ListWithHeader } from "@appsmith/ads";
import { objectKeys } from "@appsmith/utils";
import type { ModulesSectionProps } from "ee/pages/PackageIDE/types";
import { createUIModule as createUIModuleAction } from "ee/actions/moduleActions";
import { DatasourceCreateEntryPoints } from "constants/Datasource";
import ModuleEntities from "ee/pages/PackageIDECommon/components/PackageExplorer/Modules/ModuleEntities";

export function ModulesSection({ onItemSelected }: ModulesSectionProps) {
  const dispatch = useDispatch();

  const modules = useSelector(getAllModules);
  const currentPackage = useSelector(getCurrentPackage);
  const userPackagePermissions = currentPackage?.userPermissions ?? [];

  const canCreateModules = hasCreateModulePermission(userPackagePermissions);

  const createUIModule = useCallback(() => {
    dispatch(
      createUIModuleAction({
        packageId: currentPackage?.id || "",
        from: DatasourceCreateEntryPoints.SUBMENU,
      }),
    );

    onItemSelected();
  }, [currentPackage?.id, dispatch, onItemSelected]);

  const createModuleControl = useMemo(() => {
    if (!canCreateModules) return null;

    return (
      <EntityAddButton className="create-module-btn" onClick={createUIModule} />
    );
  }, [canCreateModules, createUIModule]);

  return (
    <ListWithHeader
      headerClassName="modules"
      headerControls={createModuleControl}
      headerText={`All Modules (${objectKeys(modules).length})`}
      maxHeight={"300px"}
    >
      <ListItemContainer>
        <ModuleEntities
          action={createUIModule}
          canCreateModules={canCreateModules}
          onItemSelected={onItemSelected}
          packageId={currentPackage?.id || ""}
        />
      </ListItemContainer>
    </ListWithHeader>
  );
}
