import React, { useCallback, useState } from "react";
import { Text, Button, IDEHeader, IDEHeaderTitle } from "@appsmith/ads";
import { useDispatch, useSelector } from "react-redux";
import { EditInteractionKind, SavingState } from "@appsmith/ads-old";

import { createMessage, HEADER_TITLES } from "ee/constants/messages";
import EditorName from "pages/Editor/EditorName";
import { getCurrentAppWorkspace } from "ee/selectors/selectedWorkspaceSelectors";
import { EditorState } from "IDE/enums";
import { EditorSaveIndicator } from "pages/Editor/EditorSaveIndicator";
import {
  getCurrentPackage,
  getisErrorSavingPackageName,
  getIsPackagePublishing,
  getIsSavingPackageName,
  getPackagesList,
} from "ee/selectors/packageSelectors";
import { publishPackage, updatePackage } from "ee/actions/packageActions";
import {
  getHasModuleUpdateError,
  getIsModuleReferenceUpdating,
  getIsModuleSaving,
} from "ee/selectors/modulesSelector";
import type { Package } from "ee/constants/PackageConstants";
import { useNavigationMenuData } from "./hooks/useNavigationMenuData";
import { AppsmithLink } from "pages/Editor/AppsmithLink";
import type { HeaderTitleProps } from "../../../types";
import { useCurrentPackageState } from "../../hooks";
import { useGitConnected, useGitOps } from "git/hooks";
import { selectGitPackageEnabled } from "ee/selectors/moduleFeatureSelectors";
import ChevronMenu from "./ChevronMenu";

// const VERSION_TOOLTIP_OFFSET_X = -30;
// const VERSION_TOOLTIP_OFFSET_Y = 0;
// const DEFAULT_VERSION_TOOLTIP_ALIGN = {
//   offset: [VERSION_TOOLTIP_OFFSET_X, VERSION_TOOLTIP_OFFSET_Y],
// };

const HeaderTitleComponent = ({ packageState }: HeaderTitleProps) => {
  switch (packageState) {
    case EditorState.DATA:
      return (
        <IDEHeaderTitle
          key={packageState}
          title={createMessage(HEADER_TITLES.DATA)}
        />
      );
    case EditorState.EDITOR:
      return (
        <IDEHeaderTitle
          key={packageState}
          title={createMessage(HEADER_TITLES.PACKAGE_EDITOR)}
        />
      );
    case EditorState.SETTINGS:
      return (
        <IDEHeaderTitle
          key={packageState}
          title={createMessage(HEADER_TITLES.SETTINGS)}
        />
      );
    case EditorState.LIBRARIES:
      return (
        <IDEHeaderTitle
          key={packageState}
          title={createMessage(HEADER_TITLES.LIBRARIES)}
        />
      );
    default:
      return (
        <IDEHeaderTitle
          key={packageState}
          title={createMessage(HEADER_TITLES.PACKAGE_EDITOR)}
        />
      );
  }
};

export const Header = () => {
  const dispatch = useDispatch();

  // selectors
  const currentPackage = useSelector(getCurrentPackage);
  const packageId = currentPackage?.id || "";
  const packageList = useSelector(getPackagesList) || [];
  const currentWorkspace = useSelector(getCurrentAppWorkspace);
  const isModuleSaving = useSelector(getIsModuleSaving);
  const isSavingPackageName = useSelector(getIsSavingPackageName);
  const isModuleReferenceUpdating = useSelector(getIsModuleReferenceUpdating);
  const isErroredSavingPackageName = useSelector(getisErrorSavingPackageName);
  const hasModuleUpdateError = useSelector(getHasModuleUpdateError);
  const isPublishing = useSelector(getIsPackagePublishing);
  const packageState = useCurrentPackageState();

  const isGitPackageEnabled = useSelector(selectGitPackageEnabled);
  const isGitConnected = useGitConnected();
  const isEnabledAndConnected = isGitPackageEnabled && isGitConnected;
  const { toggleOpsModal } = useGitOps();

  const onPublishPackage = useCallback(() => {
    if (isEnabledAndConnected) {
      toggleOpsModal(true);
    } else {
      if (packageId) {
        dispatch(publishPackage({ packageId }));
      }
    }
  }, [dispatch, isEnabledAndConnected, packageId, toggleOpsModal]);
  const btnText = isEnabledAndConnected ? "Commit" : "Publish";
  const btnIcon = isEnabledAndConnected ? "git-commit" : "rocket";

  const onUpdatePackage = (val: string, pkg: Package | null) => {
    if (val !== pkg?.name) {
      dispatch(
        updatePackage({
          ...pkg,
          name: val,
          id: pkg?.id || packageId,
        }),
      );
    }
  };

  // states
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  // const publishingVersionTooltipText = createMessage(
  //   NEXT_PUBLISH_VERSION,
  //   currentPackage?.nextVersion,
  // );

  return (
    <IDEHeader>
      <IDEHeader.Left logo={<AppsmithLink />}>
        <HeaderTitleComponent packageState={packageState} />
        <EditorSaveIndicator
          isSaving={isModuleSaving || isModuleReferenceUpdating}
          saveError={hasModuleUpdateError}
        />
      </IDEHeader.Left>
      <IDEHeader.Center>
        {currentWorkspace.name && (
          <>
            <Text
              color="var(--ads-v2-colors-content-label-inactive-fg)"
              kind="body-m"
            >
              {currentWorkspace.name + " / "}
            </Text>
            <EditorName
              className="t--package-name editable-package-name max-w-48"
              defaultSavingState={
                isSavingPackageName
                  ? SavingState.STARTED
                  : SavingState.NOT_STARTED
              }
              defaultValue={currentPackage?.name || ""}
              editInteractionKind={EditInteractionKind.SINGLE}
              editorName="Package"
              fill
              getNavigationMenu={useNavigationMenuData}
              isError={isErroredSavingPackageName}
              isNewEditor={
                packageList.filter((el) => el.id === packageId).length > 0 // ankita: update later, this is always true for package
              }
              isPopoverOpen={isPopoverOpen}
              onBlur={(value: string) => onUpdatePackage(value, currentPackage)}
              setIsPopoverOpen={setIsPopoverOpen}
            />
          </>
        )}
      </IDEHeader.Center>
      <IDEHeader.Right>
        <Button
          data-testid="t--package-publish-btn"
          isLoading={isPublishing}
          kind="tertiary"
          onClick={onPublishPackage}
          size="md"
          startIcon={btnIcon}
        >
          {btnText}
        </Button>
        <ChevronMenu />
      </IDEHeader.Right>
    </IDEHeader>
  );
};
