import { createImmerReducer } from "utils/ReducerUtils";

import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { Package } from "ee/constants/PackageConstants";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type {
  FetchPackageResponse,
  FetchConsumablePackagesInWorkspaceResponse,
  PublishPackageResponse,
  PullPackageResponse,
} from "ee/api/PackageApi";
import { klona } from "klona";
import type { ConvertEntityToInstanceResponse } from "ee/api/ModuleInstanceApi";
import { getNextPatchVersion } from "ee/utils/Packages/moduleHelpers";
import type { ApiResponse } from "api/ApiResponses";
import { union } from "lodash";

type ID = string;

export type PackagesReducerState = Record<ID, Package>;

export const initialState: PackagesReducerState = {};

const packageReducer = createImmerReducer(initialState, {
  [ReduxActionTypes.CREATE_PACKAGE_FROM_WORKSPACE_SUCCESS]: (
    draftState: PackagesReducerState,
    action: ReduxAction<Package>,
  ) => {
    const { payload } = action;

    draftState[payload.id] = payload;

    return draftState;
  },

  [ReduxActionTypes.FETCH_PACKAGE_SUCCESS]: (
    draftState: PackagesReducerState,
    action: ReduxAction<FetchPackageResponse>,
  ) => {
    const { packageData } = action.payload;

    draftState[packageData.id] = packageData;

    return draftState;
  },

  [ReduxActionTypes.UPDATE_PACKAGE_SUCCESS]: (
    draftState: PackagesReducerState,
    action: ReduxAction<Package>,
  ) => {
    const packageData = action.payload;

    draftState[packageData.id] = packageData;

    return draftState;
  },

  [ReduxActionTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_SUCCESS]: (
    draftState: PackagesReducerState,
    action: ReduxAction<FetchConsumablePackagesInWorkspaceResponse>,
  ) => {
    draftState = klona(initialState);
    const { packages } = action.payload;

    packages.map((pkg) => {
      draftState[pkg.id] = pkg;
    });

    return draftState;
  },

  [ReduxActionTypes.PULL_PACKAGE_SUCCESS]: (
    draftState: PackagesReducerState,
    action: ReduxAction<ApiResponse<PullPackageResponse>>,
  ) => {
    draftState = klona(initialState);
    const { packages = [] } = action.payload.data.consumables || {};

    packages.map((pkg) => {
      draftState[pkg.id] = pkg;
    });

    return draftState;
  },

  [ReduxActionTypes.RESET_EDITOR_REQUEST]: () => {
    return klona(initialState);
  },

  [ReduxActionTypes.CONVERT_ENTITY_TO_INSTANCE_SUCCESS]: (
    draftState: PackagesReducerState,
    action: ReduxAction<ConvertEntityToInstanceResponse>,
  ) => {
    const { packageData } = action.payload;

    draftState[packageData.id] = packageData;
  },

  [ReduxActionTypes.PUBLISH_PACKAGE_SUCCESS]: (
    draftState: PackagesReducerState,
    action: ReduxAction<PublishPackageResponse & { packageId: string }>,
  ) => {
    const { lastPublishedVersion, packageId } = action.payload;

    if (draftState[packageId]) {
      draftState[packageId].nextVersion =
        getNextPatchVersion(lastPublishedVersion);
    }
  },

  [ReduxActionTypes.FETCH_REMOTE_PACKAGE_VERSIONS_SUCCESS]: (
    draftState: PackagesReducerState,
    action: ReduxAction<{ remoteVersions: string[]; packageId: string }>,
  ) => {
    const { packageId, remoteVersions } = action.payload;
    const localVersions = draftState[packageId]?.versions || [];

    draftState[packageId] = {
      ...draftState[packageId],
      versions: union(localVersions, remoteVersions),
    };

    return draftState;
  },
});

export default packageReducer;
