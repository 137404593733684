import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import type { JSAction } from "entities/JSCollection";

export const generateJSFunctionSchema = (action: JSAction) => {
  return {
    type: ReduxActionTypes.GENERATE_JS_FUNCTION_SCHEMA_REQUEST,
    payload: {
      action,
    },
  };
};

export const generatePluginActionSchema = (id: string) => {
  return {
    type: ReduxActionTypes.GENERATE_PLUGIN_ACTION_SCHEMA_REQUEST,
    payload: { id },
  };
};

export const generateAIAgentSchema = (id: string) => {
  return {
    type: ReduxActionTypes.GENERATE_AI_AGENT_SCHEMA_REQUEST,
    payload: { id },
  };
};

export const showWarningPublishAnvilApplication = () => {
  return {
    type: ReduxActionTypes.SHOW_WARNING_PUBLISH_ANVIL_APPLICATION,
  };
};

export const hideWarningPublishAnvilApplication = () => {
  return {
    type: ReduxActionTypes.HIDE_WARNING_PUBLISH_ANVIL_APPLICATION,
  };
};

export const ignoreWarningPublishAnvilApplication = () => {
  return {
    type: ReduxActionTypes.IGNORE_WARNING_PUBLISH_ANVIL_APPLICATION,
  };
};

export const saveSchemaPostWarningInit = () => {
  return {
    type: ReduxActionTypes.SAVE_SCHEMA_POST_WARNING_INIT,
  };
};

export const saveSchemaPostWarningSuccess = () => {
  return {
    type: ReduxActionTypes.SAVE_SCHEMA_POST_WARNING_SUCCESS,
  };
};
