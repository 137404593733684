import type { FocusEntityInfo } from "navigation/FocusEntity";
import { FocusEntity } from "navigation/FocusEntity";
import history, { NavigationMethod } from "utils/history";
import { PluginType } from "entities/Plugin";
import { getQueryModuleEntityItemUrl } from "ee/pages/PackageIDE/layouts/routers/utils/getQueryModuleEntityItemUrl";
import { getJSModuleEntityItemUrl } from "ee/pages/PackageIDE/layouts/routers/utils/getJSModuleEntityItemUrl";

export function setSelectedQueryModule(entityInfo?: FocusEntityInfo) {
  if (
    entityInfo &&
    entityInfo.params.basePackageId &&
    entityInfo.params.baseModuleId
  ) {
    if ([FocusEntity.API, FocusEntity.QUERY].includes(entityInfo.entity)) {
      const { baseApiId, baseQueryId, pluginPackageName } = entityInfo.params;
      const key = baseApiId ? baseApiId : baseQueryId;

      if (!key) return undefined;

      let type: PluginType = PluginType.API;

      if (pluginPackageName) {
        type = PluginType.SAAS;
      } else if (baseQueryId) {
        type = PluginType.DB;
      }

      const url = getQueryModuleEntityItemUrl({
        type,
        key,
        title: key,
        moduleId: entityInfo.params.baseModuleId,
      });

      history.replace(url, { invokedBy: NavigationMethod.ContextSwitching });
    }
  }
}

export function setSelectedJSModule(entityInfo?: FocusEntityInfo) {
  if (
    entityInfo &&
    entityInfo.entity === FocusEntity.JS_OBJECT &&
    entityInfo.params.basePackageId &&
    entityInfo.params.baseModuleId
  ) {
    const key = entityInfo.params.baseCollectionId;

    if (!key) return undefined;

    history.replace(
      getJSModuleEntityItemUrl({
        type: PluginType.JS,
        key,
        title: key,
        moduleId: entityInfo.params.baseModuleId,
      }),
      {
        invokedBy: NavigationMethod.ContextSwitching,
      },
    );
  }
}
