import { all, put, select, take, takeLatest } from "redux-saga/effects";
import { fetchActionsForPage } from "actions/pluginActionActions";
import {
  ReduxActionTypes,
  ReduxActionErrorTypes,
} from "ee/constants/ReduxActionConstants";
import type { Action } from "entities/Action";
import { getCurrentPageId } from "selectors/editorSelectors";
import { getFormValues, initialize } from "redux-form";
import { QUERY_EDITOR_FORM_NAME } from "ee/constants/forms";
import { getAgentChatQueries } from "ee/selectors/aiAgentSelectors";
import type { ActionData } from "ee/reducers/entityReducers/actionsReducer";

export function* fetchAIAgentActionsForPageSaga() {
  const pageId: string = yield select(getCurrentPageId);

  const agentQueries: ActionData[] = yield select(getAgentChatQueries);

  if (!agentQueries.length) {
    return;
  }

  try {
    yield put(fetchActionsForPage(pageId));

    yield take(ReduxActionTypes.FETCH_ACTIONS_FOR_PAGE_SUCCESS);

    const formValues: Action = yield select(
      getFormValues(QUERY_EDITOR_FORM_NAME),
    );

    const matchingAgentQuery = agentQueries.find(
      (query) => query.config.id === formValues?.id,
    );

    if (matchingAgentQuery) {
      yield put(initialize(QUERY_EDITOR_FORM_NAME, matchingAgentQuery.config));
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.FETCH_ACTIONS_FOR_PAGE_ERROR,
      payload: { error },
    });
  }
}

export function* watchAIActionsSagas() {
  yield all([
    // This is required for Function Callings.
    // Every time a new actions/jsFunction is create/removed/updated,
    // it have to be added to/remove from/update in all AIAgent query Function Calling configs.
    // That is done on the backend. To get the updated list of AIAgent queries, we need to refetch them.
    // Since there is no endpoint to fetch only AIAgent actions for a page we refetch them all.
    takeLatest(
      [
        ReduxActionTypes.CREATE_JS_ACTION_SUCCESS,
        ReduxActionTypes.DELETE_JS_ACTION_SUCCESS,
        ReduxActionTypes.CREATE_ACTION_SUCCESS,
        ReduxActionTypes.DELETE_ACTION_SUCCESS,
      ],
      fetchAIAgentActionsForPageSaga,
    ),
  ]);
}
