import { FocusEntity, identifyEntityFromPath } from "navigation/FocusEntity";
import type { FocusElementsConfigList } from "ee/navigation/FocusStrategy/types";
import { FocusElement, FocusElementConfigType } from "navigation/FocusElements";
import {
  getSelectedDatasourceId,
  getSelectedEntityUrl,
} from "ee/navigation/FocusSelectors";
import {
  setSelectedDatasource,
  setSelectedEntityUrl,
} from "ee/navigation/AppIDEFocusSetters";
import {
  setSelectedJSModule,
  setSelectedQueryModule,
} from "ee/navigation/PackageIDEFocusSelectors";
import { getFirstDatasourceId } from "selectors/datasourceSelectors";
import {
  getPluginActionConfigSelectedTab,
  getPluginActionDebuggerState,
  setPluginActionEditorDebuggerState,
  setPluginActionEditorSelectedTab,
} from "PluginActionEditor/store";
import { EDITOR_TABS } from "constants/QueryEditorConstants";
import { PluginPackageName } from "entities/Plugin";
import { API_EDITOR_TABS } from "PluginActionEditor/constants/CommonApiConstants";
import { getDsViewModeValues } from "selectors/ui";
import { setDatasourceViewMode } from "actions/datasourceActions";
import { getFocusableInputField } from "selectors/editorContextSelectors";
import {
  getLastJSModuleTab,
  getLastQueryModuleTab,
  getLastVisitedModuleUrl,
  getPackageIDETabs,
} from "ee/selectors/packageIDESelectors";
import { setPackageIDETabs } from "ee/actions/packageIDEActions";
import { ActionExecutionResizerHeight } from "PluginActionEditor/components/PluginActionResponse/constants";
import { setFocusableInputField } from "actions/editorContextActions";
import {
  getJSPaneConfigSelectedTab,
  getJsPaneDebuggerState,
} from "selectors/jsPaneSelectors";
import {
  setJsPaneConfigSelectedTab,
  setJsPaneDebuggerState,
} from "actions/jsPaneActions";
import { JSEditorTab } from "reducers/uiReducers/jsPaneReducer";

export default {
  [FocusEntity.DATASOURCE]: [
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.DatasourceViewMode,
      selector: getDsViewModeValues,
      setter: setDatasourceViewMode,
      defaultValue: { datasourceId: "", viewMode: true },
    },
  ],
  [FocusEntity.DATASOURCE_LIST]: [
    {
      type: FocusElementConfigType.URL,
      name: FocusElement.SelectedDatasource,
      selector: getSelectedDatasourceId,
      setter: setSelectedDatasource,
      defaultValue: getFirstDatasourceId,
    },
  ],
  [FocusEntity.JS_OBJECT]: [
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.InputField,
      selector: getFocusableInputField,
      setter: setFocusableInputField,
    },
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.JSPaneConfigTabs,
      selector: getJSPaneConfigSelectedTab,
      setter: setJsPaneConfigSelectedTab,
      defaultValue: JSEditorTab.CODE,
    },
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.JSDebugger,
      selector: getJsPaneDebuggerState,
      setter: setJsPaneDebuggerState,
      defaultValue: {
        open: false,
        responseTabHeight: ActionExecutionResizerHeight,
        selectedTab: undefined,
      },
    },
  ],
  [FocusEntity.QUERY]: [
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.InputField,
      selector: getFocusableInputField,
      setter: setFocusableInputField,
    },
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.PluginActionConfigTabs,
      selector: getPluginActionConfigSelectedTab,
      setter: setPluginActionEditorSelectedTab,
      defaultValue: EDITOR_TABS.QUERY,
      subTypes: {
        [PluginPackageName.GRAPHQL]: {
          defaultValue: API_EDITOR_TABS.BODY,
        },
        [PluginPackageName.REST_API]: {
          defaultValue: API_EDITOR_TABS.HEADERS,
        },
      },
    },
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.PluginActionDebugger,
      selector: getPluginActionDebuggerState,
      setter: setPluginActionEditorDebuggerState,
      defaultValue: {
        open: false,
        responseTabHeight: ActionExecutionResizerHeight,
        selectedTab: undefined,
      },
    },
  ],
  [FocusEntity.JS_OBJECT_LIST]: [
    {
      type: FocusElementConfigType.URL,
      name: FocusElement.SelectedJSObject,
      selector: identifyEntityFromPath,
      setter: setSelectedJSModule,
      defaultValue: getLastJSModuleTab,
    },
  ],
  [FocusEntity.QUERY_LIST]: [
    {
      type: FocusElementConfigType.URL,
      name: FocusElement.SelectedQuery,
      selector: identifyEntityFromPath,
      setter: setSelectedQueryModule,
      defaultValue: getLastQueryModuleTab,
    },
  ],
  [FocusEntity.EDITOR]: [
    {
      type: FocusElementConfigType.URL,
      name: FocusElement.SelectedEntity,
      selector: getSelectedEntityUrl,
      setter: setSelectedEntityUrl,
      defaultValue: getLastVisitedModuleUrl,
    },
    {
      type: FocusElementConfigType.Redux,
      name: FocusElement.IDETabs,
      selector: getPackageIDETabs,
      setter: setPackageIDETabs,
      defaultValue: {},
    },
  ],
} as FocusElementsConfigList;
