import { ChatConstants } from "../constants/chatConstants";
import { RagConstants } from "../constants/ragConstants";
import { OpenAiClient } from "../solutions/providerSolution/openai/openAiClient";
import type {
  RequestMetadataDto,
  SendMessageRequestChatParametersDto,
  UserContextDto,
} from "../types/chat.dto";
import type { RagSearchRequestDto } from "../types/rag.dto";
import type { RagPartitionModel } from "../types/rag.model";
import { getRerankModel } from "../rerank/rerankModel.enum";
import type { RagSearchParametersDto } from "../types/rag.dto";
import type {
  AppsmithMessageDto,
  AppsmithMessagesDto,
  AppsmithMessageContentDto,
  AppsmithTextMessageContentDto,
} from "../types/message.dto";
import { logError } from "../utils/loggingHelper";
import { getUserContextString } from "./userContextUtils";
import { getTimeContextString } from "./timeContextUtils";

export const generateRagPartition = (
  requestMetadata: RequestMetadataDto,
): RagPartitionModel => {
  return {
    instanceId: requestMetadata.instanceId,
    tenantId: requestMetadata.tenantId,
    workspaceId: requestMetadata.workspaceId,
    datasourceId: requestMetadata.datasourceId,
  };
};

export const generateRequestMetadata = (
  ragPartitionString: string,
): RequestMetadataDto => {
  const [instanceInfo, tenantInfo, workspaceInfo, datasourceInfo] =
    ragPartitionString.split(RagConstants.COMMA);
  const instanceId = instanceInfo.split(RagConstants.COLON)[1];
  const tenantId = tenantInfo.split(RagConstants.COLON)[1];
  const workspaceId = workspaceInfo.split(RagConstants.COLON)[1];
  const datasourceId = datasourceInfo.split(RagConstants.COLON)[1];

  return {
    instanceId,
    tenantId,
    workspaceId,
    datasourceId,
  };
};

export const generateRagPartitionString = (
  requestMetadata: RequestMetadataDto,
): string => {
  const { datasourceId, instanceId, tenantId, workspaceId } = requestMetadata;
  const instanceInfo: string =
    RagConstants.INSTANCE_ID + RagConstants.COLON + instanceId;
  const tenantInfo: string =
    RagConstants.TENANT_ID + RagConstants.COLON + tenantId;
  const workspaceInfo: string =
    RagConstants.WORKSPACE_ID + RagConstants.COLON + workspaceId;
  const datasourceInfo: string =
    RagConstants.DATASOURCE_ID + RagConstants.COLON + datasourceId;

  return (
    instanceInfo +
    RagConstants.COMMA +
    tenantInfo +
    RagConstants.COMMA +
    workspaceInfo +
    RagConstants.COMMA +
    datasourceInfo
  );
};

export const generateRagSearchRequestDto = (
  message: string,
  ragSearchParameters: RagSearchParametersDto,
  metadata: RequestMetadataDto,
): RagSearchRequestDto => {
  return {
    query: message,
    highAccuracy: RagConstants.DEFAULT_SEARCH_HIGH_ACCURACY,
    partition: generateRagPartition(metadata),
    filter: ragSearchParameters.filters,
    kValue: ragSearchParameters.kValue,
    reRankModel: getRerankModel(ragSearchParameters.reRankModel),
    hybridSearch: ragSearchParameters.hybridSearch,
  };
};

export const generateRefinedRagSearchQuery = async (
  chatParameters: SendMessageRequestChatParametersDto,
  conversationHistory: AppsmithMessagesDto | undefined,
  userContext?: UserContextDto,
): Promise<string> => {
  try {
    const refinedQueryResponse =
      await OpenAiClient.getClient().chat.completions.create({
        model: ChatConstants.DEFAULT_OPEN_AI_MODEL,
        messages: [
          {
            role: "user",
            content: getRefinedQueryPromptTemplate(
              chatParameters,
              conversationHistory,
              userContext,
            ),
          },
        ],
      });

    return (
      refinedQueryResponse.choices[0].message.content || chatParameters.message
    );
  } catch (error) {
    logError("generateRefinedRagSearchQuery", error as Error);

    return chatParameters.message;
  }
};

const isTextContent = (
  content: AppsmithMessageContentDto,
): content is AppsmithTextMessageContentDto => content.type === "text";

export const formatConversationHistory = (
  conversationHistory: AppsmithMessagesDto | undefined,
): string => {
  const textMessages: Array<AppsmithMessageDto> =
    conversationHistory?.content
      .filter((message) => isTextContent(message.message[0]))
      .reverse() || [];

  return (
    textMessages
      .map((message) => {
        const content = message.message[0];

        return isTextContent(content)
          ? `${message.role}: ${content.text.value}`
          : "";
      })
      .join("\n") || "No previous context available."
  );
};

const formatUserContext = (userContext?: UserContextDto): string => {
  if (!userContext) return "";

  return `
${RagConstants.RAG_QUERY_USER_CONTEXT_HEADER}
${getUserContextString(userContext)}
`;
};

const formatTemplateSection = (header: string, content: string): string => {
  return `
${header}
${content}
`;
};

const getRefinedQueryPromptTemplate = (
  chatParameters: SendMessageRequestChatParametersDto,
  conversationHistory: AppsmithMessagesDto | undefined,
  userContext?: UserContextDto,
): string => {
  const sections = [
    formatTemplateSection(
      RagConstants.RAG_QUERY_SYSTEM_ROLE_HEADER,
      RagConstants.RAG_QUERY_SYSTEM_ROLE_STRING,
    ),
    formatTemplateSection(
      RagConstants.RAG_QUERY_SYSTEM_INSTRUCTIONS_HEADER,
      RagConstants.RAG_QUERY_SYSTEM_INSTRUCTIONS_STRING,
    ),
    formatTemplateSection(
      RagConstants.RAG_QUERY_USER_INSTRUCTIONS_HEADER,
      chatParameters.refinedInstructions ||
        chatParameters.instructions ||
        "No user instructions provided.",
    ),
    formatUserContext(userContext),
    formatTemplateSection(
      RagConstants.RAG_QUERY_CONVERSATION_HISTORY_HEADER,
      formatConversationHistory(conversationHistory),
    ),
    formatTemplateSection(
      RagConstants.RAG_QUERY_USER_QUERY_HEADER,
      chatParameters.message,
    ),
    formatTemplateSection(
      RagConstants.RAG_QUERY_MAIN_TASK_HEADER,
      RagConstants.RAG_QUERY_MAIN_TASK_STRING,
    ),
    formatTemplateSection(
      RagConstants.RAG_QUERY_OUTPUT_FORMAT_HEADER,
      RagConstants.RAG_QUERY_OUTPUT_FORMAT_STRING,
    ),
    formatTemplateSection(
      RagConstants.RAG_QUERY_TIME_CONTEXT_HEADER,
      getTimeContextString(),
    ),
  ];

  return sections.join("\n");
};
