import React, { type ReactNode } from "react";
import { Divider, Flex, Icon, Text, Button } from "@appsmith/ads";

import {
  CLOUD_BILLING_CHOOSE_PLAN_BUTTONS,
  createMessage,
  ORG_UPDATE_LICENSE_HEADER,
  PER_MONTH_PER_USER,
} from "ee/constants/messages";
import {
  UserWelcomeScreenBannerHeader,
  UserWelcomeScreenActionContainer,
  UserWelcomeScreenContent,
  UserWelcomeScreenTextBanner,
  UserWelcomeScreenWrapper,
} from "pages/setup/common";
import WelcomeBackground from "pages/setup/WelcomeBackground";
import { getIsAiAgentFlowEnabled } from "ee/selectors/aiAgentSelectors";
import { useSelector } from "react-redux";

interface Plan {
  name: string;
  price: number | null;
  button: ReactNode;
  features: { text: string; icon?: string; subText?: string }[];
  footerText?: string;
}

const planMap = new Map<string, Plan>([
  [
    "free",
    {
      name: "Free",
      price: 0,
      button: (
        <Button kind="secondary" size="md">
          {createMessage(CLOUD_BILLING_CHOOSE_PLAN_BUTTONS.free)}
        </Button>
      ),
      features: [
        {
          text: "Upto 5 users",
          icon: "check-line",
        },
        {
          text: "5 workspaces",
          icon: "check-line",
        },
        {
          text: "Version control with Git",
          subText: "3 repos",
          icon: "check-line",
        },
        {
          text: "Google SSO",
          icon: "check-line",
        },
        {
          text: "3 standard roles for access control",
          icon: "check-line",
        },
        {
          text: "Public apps",
          icon: "check-line",
        },
        {
          text: "Community support",
          icon: "check-line",
        },
      ],
    },
  ],
  [
    "business",
    {
      name: "Business",
      price: 10,
      button: (
        <Button kind="primary" size="md">
          {createMessage(CLOUD_BILLING_CHOOSE_PLAN_BUTTONS.business)}
        </Button>
      ),
      features: [
        {
          text: "Everything in Free, plus",
        },
        {
          text: "Up to 99 users",
          icon: "check-line",
        },
        {
          text: "Unlimited environments, Git repos, and workspaces",
          icon: "check-line",
        },
        {
          text: "Workflows",
          icon: "check-line",
        },
        {
          text: "Reusable packages",
          icon: "check-line",
        },
        {
          text: "Audit logs",
          icon: "check-line",
        },
        {
          text: `Remove "Powered by Appsmith" branding`,
          icon: "check-line",
        },
        {
          text: "Email/chat support",
          icon: "check-line",
        },
      ],
      footerText: "No credit card required",
    },
  ],
]);

const aiPlanMap = new Map<string, Plan>([
  [
    "team",
    {
      name: "Team",
      price: 15,
      button: (
        <Button kind="primary" size="md">
          {createMessage(CLOUD_BILLING_CHOOSE_PLAN_BUTTONS.team)}
        </Button>
      ),
      features: [
        {
          text: "Add up to 99 users",
          icon: "check-line",
        },
        {
          text: "Workplace Q&A with RAG",
          icon: "check-line",
        },
        {
          text: "Tool calls",
          icon: "check-line",
        },
        {
          text: "Cloud-hosted Vector DB",
          icon: "check-line",
        },
        {
          text: "Appsmith AI (included)",
          icon: "check-line",
        },
        {
          text: "Chrome Widget to use Agent anywhere",
          icon: "check-line",
        },
        {
          text: "Private Chat Embedding",
          icon: "check-line",
        },
        {
          text: "Google Auth",
          icon: "check-line",
        },
        {
          text: "Audit Logs",
          icon: "check-line",
        },
        {
          text: "Custom Branding",
          icon: "check-line",
        },
      ],
      footerText: "No credit card required",
    },
  ],
  [
    "enterprise",
    {
      name: "Enterprise",
      price: null,
      button: (
        <Button kind="secondary" size="md">
          {createMessage(CLOUD_BILLING_CHOOSE_PLAN_BUTTONS.enterprise)}
        </Button>
      ),
      features: [
        {
          text: "Everything in Team, plus",
        },
        {
          text: "Multi-agent Collaboration",
          icon: "check-line",
        },
        {
          text: "Self-hosted Vector DB",
          icon: "check-line",
        },
        {
          text: "OpenAI, Azure AI, other models",
          icon: "check-line",
        },
        {
          text: "User and Group Provisioning (SCIM)",
          icon: "check-line",
        },
        {
          text: "Managed Hosting",
          icon: "check-line",
        },
        {
          text: "OIDC, SAML",
          icon: "check-line",
        },
        {
          text: "Dedicated Support and SLAs",
          icon: "check-line",
        },
      ],
      footerText: "https://calendly.com/akhil-appsmith/30min",
    },
  ],
]);

const PlanCard = ({ plan }: { plan: Plan }) => {
  const priceFgColor =
    plan.price !== null && plan.price > 0
      ? "var(--ads-v2-color-fg-brand)"
      : "var(--ads-v2-color-fg)";

  return (
    <Flex
      backgroundColor={"var(--ads-v2-color-bg)"}
      border={"1px solid"}
      borderColor={"var(--ads-v2-color-border)"}
      borderRadius={"var(--ads-v2-border-radius)"}
      flexDirection={"column"}
      gap={"spaces-6"}
      padding={"spaces-7"}
      width={"300px"}
    >
      <Flex flexDirection={"column"} gap={"spaces-2"}>
        <Text kind="heading-s">{plan.name}</Text>
        <Flex alignItems={"center"} flexDirection={"row"} gap={"spaces-2"}>
          {plan.price !== null && (
            <>
              <Text color={priceFgColor} kind="heading-m">
                ${plan.price}
              </Text>
              <Text color={priceFgColor} kind="body-s">
                {createMessage(PER_MONTH_PER_USER)}
              </Text>
            </>
          )}
        </Flex>
      </Flex>
      <Divider />
      <Flex flexDirection={"column"} gap={"spaces-6"}>
        {plan.features.map((feature) => (
          <Flex
            alignItems={"center"}
            flexDirection={"row"}
            gap={"spaces-3"}
            key={feature.text}
          >
            {/* Icon */}
            {feature.icon && <Icon name={feature.icon} />}
            <Flex flexDirection={"column"}>
              {/* Text */}
              <Text kind="body-m">{feature.text}</Text>
              {/* Subtext */}
              {feature.subText && (
                <Text color={"var(--ads-v2-color-fg-subtle)"} kind="body-s">
                  {feature.subText}
                </Text>
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
      {/* Footer */}
      <Flex flexDirection={"column"} gap={"spaces-3"}>
        {/* Button */}
        {plan.button}
        {/* Footer text */}
        {plan.footerText && (
          <Text
            className="text-center"
            color={"var(--ads-v2-color-fg-subtle)"}
            kind="body-s"
          >
            {plan.footerText}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

const ChoosePlan = () => {
  const isAiAgentFlowEnabled = useSelector(getIsAiAgentFlowEnabled);

  const _planMap = isAiAgentFlowEnabled ? aiPlanMap : planMap;

  return (
    <UserWelcomeScreenWrapper data-testid={"welcome-page"}>
      <UserWelcomeScreenContent>
        <UserWelcomeScreenTextBanner>
          <UserWelcomeScreenBannerHeader className="!text-2xl">
            {createMessage(ORG_UPDATE_LICENSE_HEADER)}
          </UserWelcomeScreenBannerHeader>
          <UserWelcomeScreenActionContainer>
            <Flex flexDirection={"row"} gap={"spaces-7"}>
              {Array.from(_planMap.entries()).map(([key, plan]) => (
                <PlanCard key={key} plan={plan} />
              ))}
            </Flex>
          </UserWelcomeScreenActionContainer>
        </UserWelcomeScreenTextBanner>
        <WelcomeBackground />
      </UserWelcomeScreenContent>
    </UserWelcomeScreenWrapper>
  );
};

export default ChoosePlan;
