import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import type { IDEType } from "ee/IDE/Interfaces/IDETypes";

export const openGenerateApprovalPageModal = () => {
  return {
    type: ReduxActionTypes.SHOW_GENERATE_APPROVAL_PAGE_MODAL,
    payload: {},
  };
};

export const closeGenerateApprovalPageModal = () => {
  return {
    type: ReduxActionTypes.HIDE_GENERATE_APPROVAL_PAGE_MODAL,
  };
};

export const generateApprovalPageInit = (payload: {
  workflowId: string;
  applicationId: string;
  sourceIDEType: IDEType;
}) => {
  return {
    type: ReduxActionTypes.GENERATE_APPROVAL_PAGE_INIT,
    payload,
  };
};

export const generateApprovalPageSuccess = () => {
  return {
    type: ReduxActionTypes.GENERATE_APPROVAL_PAGE_SUCCESS,
  };
};

export const generateApprovalPageError = () => {
  return {
    type: ReduxActionErrorTypes.GENERATE_APPROVAL_PAGE_ERROR,
  };
};
