import pino from "pino";
import moment from "moment";

const logger = pino({
  level: process.env.NODE_ENV === "production" ? "info" : "debug",
  timestamp: () =>
    `,"time":"${moment().utcOffset("+05:30").format("YYYY-MM-DD HH:mm:ss Z")}"`,
});

export { logger };
