import React from "react";
import { Button } from "./design-system/Button";
import { images } from "../../assets";

export default function SuccessState({
  action,
  heading,
  image = images.successIcon,
}: {
  image?: string;
  heading: string;
  action: () => void;
}) {
  return (
    <div className="sm:cc-border cc-border-surface-surface_3 cc-p-4 cc-flex cc-flex-col cc-items-center cc-justify-center cc-rounded-xl cc-flex-grow dark:cc-border-[#FFFFFF1F]">
      <div className="cc-p-2 cc-rounded-md cc-bg-surface-surface_1 cc-inline-block cc-mb-3">
        <img alt="Success" className="cc-h-6 cc-w-6" src={image} />
      </div>
      <div className="cc-text-base cc-font-semibold cc-mb-6 cc-text-center cc-max-w-[206px] dark:cc-text-dark-text-white">
        {heading}
      </div>
      <Button className="cc-px-6" onClick={action} size="md">
        Got it
      </Button>
    </div>
  );
}
