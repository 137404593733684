import React, { useCallback, useState } from "react";
import { IDEHeaderSwitcher } from "@appsmith/ads";

import { ModulesSection } from "./ModulesSection";
import { createMessage, MODULES_TITLE } from "ee/constants/messages";

const PackageHeaderEditorTitle = ({ title }: { title: string }) => {
  const [active, setActive] = useState(false);

  const closeMenu = useCallback(() => {
    setActive(false);
  }, [setActive]);

  return (
    <IDEHeaderSwitcher
      active={active}
      prefix={createMessage(MODULES_TITLE)}
      setActive={setActive}
      title={title}
      titleTestId="t-modules-switcher"
    >
      <ModulesSection onItemSelected={closeMenu} />
    </IDEHeaderSwitcher>
  );
};

export default PackageHeaderEditorTitle;
