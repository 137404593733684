import React from "react";

import { ColorRing } from "react-loader-spinner";

// eslint-disable-next-line react/prop-types
const LoaderScroll = ({ height = 50, width = 50 }) => {
  return (
    <div className="cc-flex cc-justify-center cc-items-center cc-h-auto  ">
      <ColorRing
        ariaLabel="color-ring-loading"
        colors={["#0BABFB", "#0BABFB", "#0BABFB", "#0BABFB", "#0BABFB"]}
        height={height}
        visible
        width={width}
        wrapperClass="color-ring-wrapper"
        wrapperStyle={{}}
      />
    </div>
  );
};

export default LoaderScroll;
