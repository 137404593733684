import React from "react";
import {
  emptyFunction,
  getIntegrationName,
} from "../../utils/helper-functions";
import { DialogHeader, DialogTitle } from "../common/design-system/Dialog";
import type { IntegrationAPIResponse } from ".";
import { cn } from "../common/design-system/utils";
import type {
  ActiveStep,
  ProcessedIntegration,
  LocalFilesIntegration,
  WebScraperIntegration,
} from "../../typing/shared";
import { IntegrationName } from "../../typing/shared";
import { useCarbon } from "../../context/CarbonContext";
import {
  DEFAULT_FILE_SIZE,
  MAX_PAGES_TO_SCRAPE,
  MESSAGES,
  ONE_MB,
} from "../../constants/shared";
import { getFileSizeLimit } from "../../utils/files";
import Loader from "../common/Loader";

export interface IntegrationListProps {
  activeIntegrations: IntegrationAPIResponse[];
  setActiveStep?: (stepId: ActiveStep) => void;
  onCloseModal: () => void;
}

function IntegrationList({
  activeIntegrations,
  onCloseModal,
  setActiveStep = emptyFunction,
}: IntegrationListProps) {
  const {
    accessToken,
    installingIntegrationId,
    maxFileSize = DEFAULT_FILE_SIZE,
    processedFCIntegrations = [],
    processedIntegrations,
    whiteLabelingData,
  } = useCarbon();

  return (
    <>
      <DialogHeader
        className="cc-bg-white cc-border-b cc-border-outline-low_em"
        closeButtonClass="cc-hidden sm:cc-flex"
        onCloseModal={() => onCloseModal()}
      >
        <div className="cc-flex-grow cc-flex cc-gap-3 cc-items-center">
          <DialogTitle
            className="cc-flex-grow sm:cc-text-left"
            justifyModification
          >
            Integrations
          </DialogTitle>
        </div>
      </DialogHeader>
      {!activeIntegrations.length && !accessToken ? (
        <div className="cc-h-screen cc-flex cc-items-center cc-justify-center">
          <p>Could not fetch integrations list.</p>
        </div>
      ) : (
        <div
          className="cc-p-4 cc-flex-grow cc-overflow-auto sm:cc-h-[640px] sm:cc-max-h-[90vh]"
          // Adding border-radius 0 due to this - https://stackoverflow.com/questions/49349337/chrome-text-blur-with-overflow-yscroll-and-fixed-height
          style={{
            borderRadius: "0px",
          }}
        >
          {processedIntegrations?.length
            ? Object.entries({
                "function-calling": processedFCIntegrations,
                rag: processedIntegrations,
              }).map(([type, integrations]) => (
                <React.Fragment key={type}>
                  <div className="cc-col-span-full cc-mt-4 first:cc-mt-0">
                    <h3 className="cc-text-lg cc-font-semibold cc-mb-2">
                      {MESSAGES[type].TITLE}{" "}
                      <span className="cc-text-sm cc-text-low_em cc-mb-4">
                        ({MESSAGES[type].HINT})
                      </span>
                    </h3>
                  </div>
                  <ul className="cc-grid cc-grid-cols-2 cc-gap-3 sm:cc-grid-cols-3">
                    {integrations.map((integration: ProcessedIntegration) => {
                      const isActive = activeIntegrations.find(
                        (int) =>
                          int.data_source_type == integration.data_source_type,
                      );

                      return (
                        <li
                          className={`cc-border cc-rounded-xl cc-h-fit dark:cc-text-dark-text-white  dark:cc-bg-dark-bg-black dark:cc-border-[#FFFFFF1F] cc-relative  cc-items-center cc-p-2 sm:cc-p-3 cc-transition-all ${
                            !integration.active
                              ? "cc-bg-gray-200 cc-cursor-not-allowed"
                              : "cc-bg-white cc-cursor-pointer hover:cc-bg-surface-surface_1 dark:hover:cc-bg-dark-surface_1 dark:hover:cc-border-dark-outline-med_em hover:cc-border-outline-med_em"
                          }`}
                          key={integration.id}
                          onClick={() => {
                            if (integration.onClick) {
                              integration.onClick();
                            } else {
                              setActiveStep(integration?.id);
                            }
                          }}
                        >
                          <div className="cc-grid cc-grid-cols-[40px,calc(100%_-_52px)] sm:cc-grid-cols-[56px,calc(100%_-_68px)] cc-gap-3 cc-items-center cc-justify-start">
                            <div className="cc-flex cc-relative cc-aspect-square cc-items-center cc-justify-center cc-border-2  cc-rounded-md cc-shadow-e2 cc-border-white cc-shrink-0 dark:cc-bg-dark-text-white dark:cc-shadow-[0px_3px_4px_-2px_#0000007A]">
                              {installingIntegrationId === integration.id ? (
                                <Loader />
                              ) : (
                                <div
                                  className={cn(
                                    `cc-flex  cc-items-center cc-justify-center cc-h-full cc-w-full cc-rounded-md cc-bg-gray-50`,
                                    integration?.iconBgColor &&
                                      "cc-bg-" + integration?.iconBgColor,
                                  )}
                                >
                                  {integration.icon}
                                </div>
                              )}
                            </div>
                            <div className="cc-flex-grow ">
                              <div className="cc-flex cc-items-center cc-break-words">
                                <div className="cc-text-base cc-font-semibold cc-items-center cc-inline-block  ">
                                  {getIntegrationName(integration)}
                                  {isActive ? (
                                    <span
                                      className={
                                        "cc-h-2 cc-ml-[4px]  cc-top-[10px] cc-right-[10px] cc-inline-block cc-w-2 cc-border dark:cc-border-dark-bg-black cc-border-white cc-rounded-lg cc-bg-success-600"
                                      }
                                    />
                                  ) : null}
                                </div>
                              </div>

                              {integration.id == IntegrationName.LOCAL_FILES ? (
                                <p className="cc-font-semibold dark:cc-text-dark-text-gray cc-text-xs cc-text-low_em cc-mt-1 cc-truncate">
                                  {`max ${
                                    getFileSizeLimit(
                                      integration as LocalFilesIntegration,
                                      whiteLabelingData,
                                      maxFileSize,
                                    ) / ONE_MB
                                  }MB per file`}
                                </p>
                              ) : null}
                              {integration.id == IntegrationName.WEB_SCRAPER ? (
                                <p className="cc-font-semibold dark:cc-text-dark-text-gray cc-text-xs cc-text-low_em cc-mt-1 cc-truncate">
                                  {`max ${
                                    (integration as WebScraperIntegration)
                                      .maxPagesToScrape || MAX_PAGES_TO_SCRAPE
                                  } links to sync`}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </React.Fragment>
              ))
            : null}
        </div>
      )}
    </>
  );
}

export default IntegrationList;
