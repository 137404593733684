export * from "ce/pages/common/AppHeader";
import { Routes as CE_Routes } from "ce/pages/common/AppHeader";
import React from "react";
import ReactDOM from "react-dom";
import { Route, Switch } from "react-router";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import { WORKFLOW_EDITOR_URL } from "ee/constants/routes/workflowRoutes";
import {
  PACKAGE_EDITOR_PATH,
  UI_PACKAGE_EDITOR_PATH,
} from "ee/constants/routes/packageRoutes";
import { PackageHeader } from "ee/pages/PackageIDE/layouts/components/Header";
import { UIPackageHeader } from "ee/pages/UIPackageIDE/layouts/components/Header";
import { Header as WorkflowEditorHeader } from "ee/pages/WorkflowIDE/layouts/components/Header";
import { ORG_UPDATE_PATH } from "ee/constants/routes/appRoutes";

type Props = RouteComponentProps;

const headerRoot = document.getElementById("header-root");

const Routes = () => {
  return (
    <Switch>
      <Route component={WorkflowEditorHeader} path={WORKFLOW_EDITOR_URL} />
      <Route component={PackageHeader} path={PACKAGE_EDITOR_PATH} />
      <Route component={UIPackageHeader} path={UI_PACKAGE_EDITOR_PATH} />
      <Route path={ORG_UPDATE_PATH} />
      <CE_Routes />
    </Switch>
  );
};

// TODO: Fix this the next time the file is edited
// eslint-disable-next-line @typescript-eslint/no-explicit-any
class AppHeader extends React.Component<Props, any> {
  private container = document.createElement("div");

  componentDidMount() {
    headerRoot?.appendChild(this.container);
  }
  componentWillUnmount() {
    headerRoot?.removeChild(this.container);
  }
  get header() {
    return <Routes />;
  }
  render() {
    return ReactDOM.createPortal(this.header, this.container);
  }
}

export default withRouter(AppHeader);
