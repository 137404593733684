import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import { create } from "mutative";
import { createImmerReducer } from "utils/ReducerUtils";
import type { ReduxAction } from "actions/ReduxActionTypes";
import type { Workflow } from "ee/constants/WorkflowConstants";

export interface GenerateWorkflowApprovalPageState {
  isOpen: boolean;
  isLoading: boolean;
  isFetchingWorkflows: boolean;
  workflows: Array<Workflow>;
}

export interface WorkflowPluginState {
  generateApprovalPageModalState: GenerateWorkflowApprovalPageState;
}

const initialState: WorkflowPluginState = {
  generateApprovalPageModalState: {
    isOpen: false,
    isLoading: false,
    isFetchingWorkflows: false,
    workflows: [],
  },
};

export const handlers = {
  [ReduxActionTypes.SHOW_GENERATE_APPROVAL_PAGE_MODAL]: (
    state: WorkflowPluginState,
  ) => {
    return create(state, (draft) => {
      draft.generateApprovalPageModalState.isOpen = true;
    });
  },
  [ReduxActionTypes.HIDE_GENERATE_APPROVAL_PAGE_MODAL]: (
    state: WorkflowPluginState,
  ) => {
    return create(state, (draft) => {
      draft.generateApprovalPageModalState.isOpen = false;
    });
  },
  [ReduxActionTypes.GENERATE_APPROVAL_PAGE_INIT]: (
    state: WorkflowPluginState,
  ) => {
    return create(state, (draft) => {
      draft.generateApprovalPageModalState.isLoading = true;
    });
  },
  [ReduxActionTypes.GENERATE_APPROVAL_PAGE_SUCCESS]: (
    state: WorkflowPluginState,
  ) => {
    return create(state, (draft) => {
      draft.generateApprovalPageModalState.isLoading = false;
    });
  },
  [ReduxActionErrorTypes.GENERATE_APPROVAL_PAGE_ERROR]: (
    state: WorkflowPluginState,
  ) => {
    return create(state, (draft) => {
      draft.generateApprovalPageModalState.isLoading = false;
    });
  },
  [ReduxActionTypes.FETCH_WORKFLOWS_WITH_APPROVAL_REQUEST_INIT]: (
    state: WorkflowPluginState,
  ) => {
    return create(state, (draft) => {
      draft.generateApprovalPageModalState.isFetchingWorkflows = true;
    });
  },
  [ReduxActionTypes.FETCH_WORKFLOWS_WITH_APPROVAL_REQUEST_SUCCESS]: (
    state: WorkflowPluginState,
    action: ReduxAction<{
      workflows: Array<Workflow>;
    }>,
  ) => {
    return create(state, (draft) => {
      draft.generateApprovalPageModalState.isFetchingWorkflows = false;
      draft.generateApprovalPageModalState.workflows = action.payload.workflows;
    });
  },
  [ReduxActionErrorTypes.FETCH_WORKFLOWS_WITH_APPROVAL_REQUEST_ERROR]: (
    state: WorkflowPluginState,
  ) => {
    return create(state, (draft) => {
      draft.generateApprovalPageModalState.isFetchingWorkflows = false;
    });
  },
};

const workflowPluginReducer = createImmerReducer(initialState, handlers);

export default workflowPluginReducer;
