/**
 * ModuleInterfaceSagas
 *
 * Purpose:
 * This saga file serves as a bridge between the Community Edition (CE) and Enterprise Edition (EE)
 * module-related functionalities. It provides a clean interface layer that handles all interactions
 * between core widget operations and module-specific features available in the enterprise version.
 */
export * from "ce/sagas/moduleInterfaceSagas";
import type { HandleModuleWidgetCreationSagaPayload } from "ce/sagas/moduleInterfaceSagas";
import { createUIModuleInstance } from "ee/actions/moduleInstanceActions";
import { ModuleInstanceCreatorType } from "ee/constants/ModuleInstanceConstants";
import { put, select } from "redux-saga/effects";
import { getCurrentPageId } from "selectors/editorSelectors";

export function* handleModuleWidgetCreationSaga(
  props: HandleModuleWidgetCreationSagaPayload,
) {
  try {
    const { addChildPayload, widgets } = props;
    const isModuleWidget = addChildPayload.type.startsWith("MODULE_WIDGET_");
    const pageId: string = yield select(getCurrentPageId);
    const widget = widgets[addChildPayload.newWidgetId];

    if (isModuleWidget) {
      widgets[addChildPayload.newWidgetId].isCreationPending = true;

      yield put(
        createUIModuleInstance({
          contextId: pageId,
          contextType: ModuleInstanceCreatorType.PAGE,
          widgetName: widget.widgetName,
          widgetId: widget.widgetId,
          parentWidgetId: widget.parentId || "",
          moduleUUID: widget.moduleUUID,
        }),
      );
    }

    return widgets;
  } catch (error) {
    return props.widgets;
  }
}
