/**
 * UIModuleInstanceApi
 *
 * This class provides a mock API interface for managing UI Module instances in local storage.
 * It mimics the behavior of a real API by providing similar response structures and async operations.
 *
 * Key responsibilities:
 * 1. Store and manage UI Module instances in browser's local storage
 * 2. Provide CRUD operations (Create, Read, Update, Delete) for UI Module instances
 * 3. Maintain consistency with the real API response format using Axios
 * 4. Handle errors gracefully with proper error messages
 *
 * Methods:
 * - fetchModuleInstances: Retrieves UI modules for a specific context
 * - createModuleInstance: Creates a new UI module instance with simulated API delay
 * - updateModuleInstance: Updates an existing UI module instance
 * - deleteModuleInstance: Removes a UI module instance and returns the deleted instance
 *
 * Note: This is a client-side implementation that uses local storage instead of server calls
 * to enable offline-first functionality for UI modules.
 */

import type { ApiResponse } from "api/ApiResponses";
import type {
  ModuleInstance,
  ModuleInstanceCreatorType,
} from "ee/constants/ModuleInstanceConstants";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import localStorage from "utils/localStorage";
import log from "loglevel";
import type { InternalAxiosRequestConfig } from "axios";
import { AxiosHeaders } from "axios";
import type { AxiosResponse } from "axios";
import type { DeleteModuleInstancePayload } from "ee/actions/moduleInstanceActions";

const UI_MODULE_INSTANCES_KEY = "UI_MODULE_INSTANCES";
const SIMULATED_API_DELAY = 1000; // 1 second delay

function createAxiosResponse<T>(data: T): AxiosResponse<ApiResponse<T>> {
  const headers = new AxiosHeaders();
  const config: InternalAxiosRequestConfig = {
    url: "",
    method: "get",
    headers,
    transitional: {
      silentJSONParsing: true,
      forcedJSONParsing: true,
      clarifyTimeoutError: false,
    },
  };

  return {
    responseMeta: { status: 200, success: true },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data,
    status: 200,
    statusText: "OK",
    headers: {},
    config,
  };
}

export class UIModuleInstanceApi {
  private static getStoredUIModuleInstances(): ModuleInstance[] {
    try {
      const storedData = localStorage.getItem(UI_MODULE_INSTANCES_KEY);

      return storedData ? JSON.parse(storedData) : [];
    } catch (error) {
      log.error("Error reading UI module instances from local storage:", error);

      return [];
    }
  }

  private static saveUIModuleInstances(instances: ModuleInstance[]) {
    try {
      localStorage.setItem(UI_MODULE_INSTANCES_KEY, JSON.stringify(instances));
    } catch (error) {
      log.error("Error saving UI module instances to local storage:", error);
    }
  }

  static async fetchModuleInstances(
    contextId: string,
    contextType: ModuleInstanceCreatorType,
  ): Promise<AxiosResponse<ApiResponse<ModuleInstance[]>>> {
    const storedInstances = this.getStoredUIModuleInstances();
    const filteredInstances = (storedInstances || []).filter(
      (instance) =>
        instance.contextId === contextId &&
        instance.contextType === contextType,
    );

    return createAxiosResponse(filteredInstances);
  }

  static async createModuleInstance(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<AxiosResponse<ApiResponse<any>>> {
    // Simulate API delay
    await new Promise((resolve) => setTimeout(resolve, SIMULATED_API_DELAY));

    const storedInstances = this.getStoredUIModuleInstances();
    const newInstance: ModuleInstance = {
      id: Date.now().toString(), // Generate a unique ID
      moduleUUID: payload.moduleUUID || "",
      name: payload.name,
      sourceModuleId: payload.sourceModuleId,
      contextId: payload.contextId,
      contextType: payload.contextType,
      type: MODULE_TYPE.UI,
      inputs: {},
      userPermissions: [],
      jsonPathKeys: [],
      invalids: [],
      isValid: true,
      layouts: payload.layouts,
      widgetId: payload.widgetId,
    };

    storedInstances.push(newInstance);
    this.saveUIModuleInstances(storedInstances);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = {
      moduleInstance: newInstance,
      entities: {
        actions: [],
        jsCollections: [],
        moduleInstances: [],
      },
    };

    return createAxiosResponse(response);
  }

  static async updateModuleInstance(
    payload: ModuleInstance,
  ): Promise<AxiosResponse<ApiResponse<ModuleInstance>>> {
    const storedInstances = this.getStoredUIModuleInstances();
    const updatedInstances = storedInstances.map((instance) =>
      instance.id === payload.id ? payload : instance,
    );

    this.saveUIModuleInstances(updatedInstances);

    return createAxiosResponse(payload);
  }

  static async deleteModuleInstance(
    payload: DeleteModuleInstancePayload,
  ): Promise<AxiosResponse<ApiResponse<ModuleInstance>>> {
    const storedInstances = this.getStoredUIModuleInstances();
    const instance = storedInstances.find(
      (instance) => instance.id === payload.id,
    );

    if (!instance) {
      throw new Error(`UI Module instance with id ${payload.id} not found`);
    }

    const filteredInstances = storedInstances.filter(
      (instance) => instance.id !== payload.id,
    );

    this.saveUIModuleInstances(filteredInstances);

    return createAxiosResponse(instance);
  }
}
