import React, { useEffect, useState } from "react";
import { DialogHeader, DialogTitle } from "../common/design-system/Dialog";

import BackIcon from "../../assets/svgIcons/back-icon.svg";
import WebsiteTabContent from "./WebsiteTabContent";
import SitemapTabContent from "./SitemapTabContent";
import type { ActiveStep, WebScraperIntegration } from "../../typing/shared";
import { IntegrationName } from "../../typing/shared";
import { useCarbon } from "../../context/CarbonContext";

import type { BannerState } from "../common/Banner";
import Banner from "../common/Banner";
import SyncedFilesList from "../CarbonFilePicker/SyncedFilesList";
import { SyncingModes } from "../CarbonFilePicker/CarbonFilePicker";
import type { IntegrationItemType } from "../../utils/integrationModalconstants";

export interface WebScraperProps {
  activeStep?: string;
  setActiveStep: React.Dispatch<React.SetStateAction<ActiveStep>>;
  onCloseModal: () => void;
  isWhiteLabeledEntryPoint: boolean;
}

export interface FilterData {
  [key: string]: number | string;
}

function WebScraper({
  isWhiteLabeledEntryPoint,
  onCloseModal,
  setActiveStep,
}: WebScraperProps) {
  const { entryPoint, processedIntegrations, showFilesTab } = useCarbon();
  const [activeTab, setActiveTab] = useState<string>("website");
  const [service, setService] = useState<
    (WebScraperIntegration & IntegrationItemType) | undefined
  >(undefined);
  const [bannerState, setBannerState] = useState<BannerState>({
    message: null,
  });

  const shouldShowFilesTab = showFilesTab || service?.showFilesTab;
  const [activeScreen, setActiveScreen] = useState<"FILES" | "UPLOAD">(
    shouldShowFilesTab ? "FILES" : "UPLOAD",
  );

  useEffect(() => {
    setService(
      processedIntegrations?.find(
        (integration) => integration.id === "WEB_SCRAPER",
      ),
    );
  }, [processedIntegrations]);

  const sitemapEnabled = service ? service?.sitemapEnabled ?? true : false;

  if (!service) return null;

  return (
    <>
      <DialogHeader
        className="cc-bg-white cc-border-b cc-border-outline-low_em"
        closeButtonClass="cc-hidden sm:cc-flex"
        onCloseModal={onCloseModal}
      >
        <div className="cc-flex-grow cc-flex cc-gap-3 cc-items-center">
          <button
            className="cc-pr-1 cc-h-10 cc-w-auto"
            onClick={() => {
              if (showFilesTab && activeScreen == "UPLOAD") {
                setActiveScreen("FILES");
              } else if (
                isWhiteLabeledEntryPoint &&
                entryPoint !== "INTEGRATION_LIST"
              ) {
                onCloseModal();
              } else if (!entryPoint || entryPoint == "INTEGRATION_LIST")
                setActiveStep("INTEGRATION_LIST");
              else {
                setActiveStep("CONNECT");
              }
            }}
          >
            <img
              alt="Lock"
              className="cc-h-[18px] cc-w-[18px] dark:cc-invert-[1] dark:cc-hue-rotate-180"
              src={BackIcon}
            />
          </button>
          <DialogTitle className="cc-flex-grow cc-text-left">
            Web Scraper
          </DialogTitle>
        </div>
      </DialogHeader>
      <Banner bannerState={bannerState} setBannerState={setBannerState} />
      {activeScreen == "UPLOAD" ? (
        <>
          {activeTab === "website" && (
            <WebsiteTabContent
              service={service}
              setActiveTab={setActiveTab}
              setBannerState={setBannerState}
              sitemapEnabled={sitemapEnabled}
            />
          )}
          {activeTab === "sitemap" && (
            <SitemapTabContent
              processedIntegration={processedIntegrations?.find(
                (p) => p.id == IntegrationName.WEB_SCRAPER,
              )}
              setActiveTab={setActiveTab}
              sitemapEnabled={sitemapEnabled}
            />
          )}
        </>
      ) : (
        <SyncedFilesList
          bannerState={bannerState}
          handleUploadFilesClick={() => setActiveScreen("UPLOAD")}
          mode={SyncingModes.UPLOAD}
          processedIntegration={service}
          selectedDataSource={null}
          setActiveStep={setActiveStep}
          setBannerState={setBannerState}
        />
      )}
    </>
  );
}

export default WebScraper;
