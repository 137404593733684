import React, { useCallback } from "react";
import { Menu, MenuItem, MenuContent, MenuTrigger } from "@appsmith/ads";
import { Button } from "@appsmith/ads";
import { GitDeployMenuItems, GitOpsTab } from "git";
import { gitToggleOpsModal } from "git/store";
import { packageArtifact } from "git-artifact-helpers/ee/packages";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentBasePackageId } from "ee/selectors/packageSelectors";
import { createMessage, PACKAGE_RELEASE_VERSION } from "ee/constants/messages";
import { useGitConnected } from "git/hooks";

export const ChevronMenu = () => {
  const dispatch = useDispatch();
  const isGitConnected = useGitConnected();
  const currentBasePackageId = useSelector(getCurrentBasePackageId);
  const handleClickOnRelease = useCallback(() => {
    dispatch(
      gitToggleOpsModal({
        artifactDef: packageArtifact(currentBasePackageId),
        open: true,
        tab: GitOpsTab.Release,
      }),
    );
  }, [currentBasePackageId, dispatch]);

  return (
    <Menu>
      <MenuTrigger>
        <Button
          className="t--deploy-popup-option-trigger"
          isIconButton
          kind="tertiary"
          size="md"
          startIcon={"down-arrow"}
        />
      </MenuTrigger>
      <MenuContent>
        <GitDeployMenuItems />
        {isGitConnected && (
          <MenuItem
            className="t--current-deployed-preview-btn"
            onClick={handleClickOnRelease}
            startIcon="pricetag"
          >
            {createMessage(PACKAGE_RELEASE_VERSION)}
          </MenuItem>
        )}
      </MenuContent>
    </Menu>
  );
};

export default ChevronMenu;
