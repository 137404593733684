export * from "ce/pages/AppIDE/hooks/useGenPageItems";

import { useGenPageItems as useCEGenPageItems } from "ce/pages/AppIDE/hooks/useGenPageItems";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import { openGenerateApprovalPageModal } from "ee/pages/GenerateWorkflowApprovalPage/store/generateWorkflowApprovalPageActions";
import {
  createMessage,
  GENERATE_APPROVAL_PAGE_ACTION_TITLE,
} from "ee/constants/messages";
import { useDispatch } from "react-redux";
import { useMemo } from "react";

export const useGenPageItems = () => {
  const ceItems = useCEGenPageItems();
  const dispatch = useDispatch();

  const isApprovalCrudEnabled = useFeatureFlag(
    FEATURE_FLAG.release_workflows_hitl_crud_enabled,
  );

  return useMemo(() => {
    // Get the items from CE implementation
    const items = [...ceItems];

    // Add approval page generation item if feature flag is enabled
    if (isApprovalCrudEnabled) {
      items.push({
        title: createMessage(GENERATE_APPROVAL_PAGE_ACTION_TITLE),
        icon: "workflows-mono",
        onClick: () => dispatch(openGenerateApprovalPageModal()),
        "data-testid": "generate-approval-page",
        key: "GENERATE_APPROVAL_PAGE",
      });
    }

    return items;
  }, [ceItems, isApprovalCrudEnabled, dispatch]);
};
