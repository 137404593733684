import type { GeneratePromptButtonProps } from "ce/components/GeneratePromptButton";
import React, { useCallback, useState } from "react";
import {
  Button,
  Flex,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  ToggleButton,
  toast,
} from "@appsmith/ads";
import Api from "api/Api";
import { getFormValues } from "redux-form";
import { QUERY_EDITOR_FORM_NAME } from "ce/constants/forms";
import { useSelector } from "react-redux";
import type { Action } from "entities/Action";
import { PluginPackageName } from "entities/Plugin";
import { getPluginByPackageName } from "ee/selectors/entitiesSelector";

const generatePromptApi = async (
  actionId: string,
  existingPrompt: string,
  description: string,
) => {
  return Api.post(`v1/ai/${actionId}/generate-prompt`, {
    existingPrompt,
    description,
  });
};

export const GeneratePromptButton = (props: GeneratePromptButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const formValues: Action = useSelector(
    getFormValues(QUERY_EDITOR_FORM_NAME),
  ) as Action;

  const handleGeneratePrompt = useCallback(async () => {
    setIsGenerating(true);
    try {
      const response = await generatePromptApi(
        formValues.id,
        props.existingPrompt,
        description,
      );

      props.onSubmit(response.data.prompt);
    } catch (e) {
      toast.show("Failed to generate prompt", {
        kind: "error",
      });
    } finally {
      setIsOpen(false);
      setIsGenerating(false);
    }
  }, [formValues.id, props, description]);

  const agentPluginId = useSelector((state) =>
    getPluginByPackageName(state, PluginPackageName.APPSMITH_AI),
  );

  if (!formValues || !agentPluginId) {
    return null;
  }

  if (formValues.pluginId !== agentPluginId.id) {
    return null;
  }

  return (
    <Popover onOpenChange={setIsOpen} open={isOpen}>
      <PopoverTrigger>
        <ToggleButton
          icon="star-fill"
          isSelected={isOpen}
          onClick={() => setIsOpen(true)}
          size="md"
        >
          Generate prompt
        </ToggleButton>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        avoidCollisions={false}
        showArrow
        side="bottom"
      >
        <PopoverHeader>Generate prompt</PopoverHeader>
        <PopoverBody>
          <Flex flexDirection="column" gap="spaces-2">
            <Input
              autoFocus
              onChange={setDescription}
              renderAs="textarea"
              value={description}
            />
            <Button
              isLoading={isGenerating}
              kind="primary"
              onClick={handleGeneratePrompt}
              size="sm"
            >
              Generate
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
