import { queryEditorIdURL } from "ee/RouteBuilder";
import type { ApplicationPayload } from "entities/Application";
import type { ReduxAction } from "actions/ReduxActionTypes";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import { findDefaultPage } from "pages/utils";
import type { ImportTemplateResponse, Template } from "api/TemplatesApi";
import TemplatesAPI from "api/TemplatesApi";
import { all, call, put, select, take, takeEvery } from "redux-saga/effects";
import history from "utils/history";
import { validateResponse } from "sagas/ErrorSagas";
import { getTemplatesSelector } from "selectors/templatesSelectors";
import { ConsolidatedPageLoadApi } from "api/services";
import type { EditConsolidatedApi } from "sagas/InitSagas";
import type { ApiResponse } from "api/ApiResponses";
import { setIdeEditorViewMode } from "actions/ideActions";
import { EditorViewMode } from "IDE/Interfaces/EditorTypes";
import { paragonInit } from "./DatasourcesSagas";
import type { IntegrationInstallEvent } from "@useparagon/connect";
import { paragon, SDK_EVENT } from "@useparagon/connect";
import type { Datasource } from "entities/Datasource";
import type { getPlugin } from "ee/selectors/entitiesSelector";
import {
  getActions,
  getDatasources,
  getPluginForm,
  getPlugins,
} from "ee/selectors/entitiesSelector";
import { getCurrentEnvironmentId } from "ee/selectors/environmentSelectors";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import { getConfigInitialValues } from "components/formControls/utils";
import {
  createDatasourceActionPayloadFromParagonEvent,
  createDatasourceAPIPayloadFromAction,
  paragonInstallIntegration,
} from "ee/sagas/helpers";
import DatasourcesApi, {
  type CreateDatasourceConfig,
} from "ee/api/DatasourcesApi";
import { getCurrentPageId } from "selectors/editorSelectors";
import { createMessage } from "ce/constants/messages";
import { getNextEntityName } from "utils/AppsmithUtils";
import type { ActionData } from "ee/reducers/entityReducers/actionsReducer";
import {
  deleteTempDSFromDraft,
  fetchDatasourceStructure,
  removeTempDatasource,
  updateDatasoruceRefs,
} from "actions/datasourceActions";
import { toast } from "@appsmith/ads";
import {
  createDatasourceAndSearchQueriesForAiAgentSuccess,
  openCarbonModal,
  setInstallingIntegrationName,
} from "ee/actions/aiAgentActions";
import {
  AI_AGENT_CREATE_DATASOURCE_AND_SEARCH_QUERIES_ERROR,
  AI_AGENT_CREATE_DATASOURCE_AND_SEARCH_QUERIES_SUCCESS,
} from "ee/constants/messages";
import store from "store";
import { createActionRequest } from "actions/pluginActionActions";
import { initialize } from "redux-form";
import { QUERY_EDITOR_FORM_NAME } from "ce/constants/forms";
import { PluginType } from "entities/Plugin";
import { checkAndGetPluginFormConfigsSaga } from "sagas/PluginSagas";

function* createAiAgentFromWorkspaceSaga(
  action: ReduxAction<{ templateId: string; workspaceId: string }>,
) {
  const templates: Template[] = yield select(getTemplatesSelector);

  const aiAgentTemplate = templates.find(
    (template) => template.title === "AI Agent",
  );

  try {
    const response: ImportTemplateResponse = yield call(
      TemplatesAPI.importTemplate,
      aiAgentTemplate?.id as string,
      action.payload.workspaceId,
    );
    const isValid: boolean = yield validateResponse(response);

    if (isValid) {
      const defaultPage = findDefaultPage(response.data.application.pages);
      const application: ApplicationPayload = {
        ...response.data.application,
        defaultPageId: defaultPage?.id,
        defaultBasePageId: defaultPage?.baseId,
      };

      // we are using cosolidateAPI here because we want to navigate to the editor
      // with query pane opened. But we don't have that information in above response.
      // So we are using consolidateAPI to get information about query of the app
      const consolidatedApiResponse: ApiResponse<EditConsolidatedApi> =
        yield call(ConsolidatedPageLoadApi.getConsolidatedPageLoadDataEdit, {
          applicationId: application.id,
          defaultPageId: application.defaultPageId,
        });

      const isValidConsolidatedApiResponse: boolean = yield validateResponse(
        consolidatedApiResponse,
      );

      if (isValidConsolidatedApiResponse) {
        const editURL = queryEditorIdURL({
          basePageId: defaultPage?.baseId,
          baseQueryId:
            consolidatedApiResponse.data.unpublishedActions.data[0].baseId,
        });

        // This is needed so that for new instance, we open the editor in split screen mode
        yield put(setIdeEditorViewMode(EditorViewMode.SplitScreen));

        yield put({
          type: ReduxActionTypes.CREATE_AI_AGENT_FROM_WORKSPACE_SUCCESS,
        });

        history.push(editURL);
      }
    }
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.CREATE_AI_AGENT_FROM_WORKSPACE_ERROR,
      payload: {
        error,
      },
    });
  }
}

type Plugin = ReturnType<typeof getPlugin>;
type Datasources = ReturnType<typeof getDatasources>;
type EnvId = ReturnType<typeof getCurrentEnvironmentId>;

function onClosePragonPortal() {
  store.dispatch({
    type: ReduxActionTypes.INSTALLING_INTEGRATION_NAME,
    payload: {
      pluginName: null,
    },
  });
}

/**
 * The main goal of this saga is create datasource and search queries for the ai agent
 * for the function calling integrations like jira, zendesk, slack and salesforce.
 * It includes following steps:
 *
 * 1. Install the integration using paragon
 * 2. Create a datasource from the paragon event
 * 3. Create a search query from the datasource
 *
 * @param action
 * @returns
 */
function* createDatasourceAndSearchQueriesSaga(
  action: ReduxAction<{
    integrationName: string;
    pluginName: string;
    command: string;
    query: string;
  }>,
) {
  const plugins: Plugin[] = yield select(getPlugins);
  const plugin = plugins.find(
    (plugin) => plugin?.pluginName === action?.payload?.pluginName,
  );

  if (!plugin) {
    toast.show(
      createMessage(AI_AGENT_CREATE_DATASOURCE_AND_SEARCH_QUERIES_ERROR),
      {
        kind: "error",
      },
    );

    return;
  }

  const currentEnvId: EnvId = yield select(getCurrentEnvironmentId);
  const datasources: Datasources = yield select(getDatasources);
  const pageId: string = yield select(getCurrentPageId);

  // Step 1 is to initialize the paragon and install the integration
  yield call(paragonInit);

  paragon.subscribe(SDK_EVENT.ON_PORTAL_CLOSE, onClosePragonPortal);

  try {
    const event: IntegrationInstallEvent = yield call(
      paragonInstallIntegration,
      plugin,
    );

    yield put(
      setInstallingIntegrationName({
        integrationName: action.payload.integrationName,
      }),
    );

    const createDatasourceActionPayload: CreateDatasourceConfig = yield call(
      createDatasourceActionPayloadFromParagonEvent,
      {
        event,
        currentEnvId,
        datasources,
        plugin,
      },
    );

    // Step 2. After paragon integration, we can create the datasource from the paragon event
    const workspaceId: string = yield select(getCurrentWorkspaceId);

    yield call(checkAndGetPluginFormConfigsSaga, plugin.id);
    const formConfig: ReturnType<typeof getPluginForm> = yield select(
      getPluginForm,
      plugin.id,
    );

    const initialValues: ReturnType<typeof getConfigInitialValues> = yield call(
      getConfigInitialValues,
      formConfig,
    );

    const payload = createDatasourceAPIPayloadFromAction({
      actionPayload: createDatasourceActionPayload,
      currentEnvId: currentEnvId,
      initialValues,
    });

    const response: ApiResponse<Datasource> =
      yield DatasourcesApi.createDatasource({
        ...payload,
        workspaceId,
      });
    const isValidResponse: boolean = yield validateResponse(response);

    if (isValidResponse) {
      const createdDatasource: Datasource = response.data;

      yield put(updateDatasoruceRefs(response.data));
      yield put(fetchDatasourceStructure(response?.data?.id, true));
      yield put(deleteTempDSFromDraft());
      yield put(removeTempDatasource());

      // Step 3. Create a search query from the datasource
      const actionConfig = {
        name: `search_${plugin.pluginName}`,
        actionConfiguration: {
          timeoutInMillisecond: 10000,
          paginationType: "NONE",
          encodeParamsToggle: true,
          formData: {
            command: action.payload.command,
            [action.payload.command]: {
              query: action.payload.query,
            },
          },
        },
      };

      const actions: ReturnType<typeof getActions> = yield select(getActions);
      const actionName = getNextEntityName(
        actionConfig.name,
        actions.map((el: ActionData) => el.config.name),
      );

      const actionPayload = {
        pageId,
        pluginId: createdDatasource.pluginId,
        datasource: {
          id: createdDatasource.id,
        },
        name: actionName,
        actionConfiguration: actionConfig.actionConfiguration,
        shouldRedirectToQueryEditor: false,
      };

      yield put(
        createActionRequest(
          actionPayload,
          createDatasourceAndSearchQueriesForAiAgentSuccess(),
        ),
      );

      yield put(setIdeEditorViewMode(EditorViewMode.SplitScreen));
    }
  } catch (error) {
    toast.show(
      createMessage(AI_AGENT_CREATE_DATASOURCE_AND_SEARCH_QUERIES_ERROR),
      {
        kind: "error",
      },
    );
  } finally {
    paragon.unsubscribe(SDK_EVENT.ON_PORTAL_CLOSE, onClosePragonPortal);
    paragon.closePortal();
  }
}

function* createDatasourceAndSearchQueriesForAiAgentSuccessSaga() {
  yield put(openCarbonModal({ shouldOpen: false }));

  toast.show(
    createMessage(AI_AGENT_CREATE_DATASOURCE_AND_SEARCH_QUERIES_SUCCESS),
    {
      kind: "success",
    },
  );

  yield take(ReduxActionTypes.FETCH_ACTIONS_FOR_PAGE_SUCCESS);

  // We'll also need to initialize the form for the action so that attached tool calls are rendered
  const actions: ReturnType<typeof getActions> = yield select(getActions);
  const action = actions.find(
    (el: ActionData) => el.config.pluginType === PluginType.AI,
  );

  if (!action) return;

  yield put(initialize(QUERY_EDITOR_FORM_NAME, action.config));
}

export default function* watchActionSagas() {
  yield all([
    takeEvery(
      ReduxActionTypes.CREATE_AI_AGENT_FROM_WORKSPACE_INIT,
      createAiAgentFromWorkspaceSaga,
    ),
    takeEvery(
      ReduxActionTypes.CREATE_DATASOURCE_AND_SEARCH_QUERIES_FOR_AI_AGENT_INIT,
      createDatasourceAndSearchQueriesSaga,
    ),
    takeEvery(
      ReduxActionTypes.CREATE_DATASOURCE_AND_SEARCH_QUERIES_FOR_AI_AGENT_SUCCESS,
      createDatasourceAndSearchQueriesForAiAgentSuccessSaga,
    ),
  ]);
}
