import type { AppState } from "ee/reducers";
import { createSelector } from "reselect";
import {
  getActions,
  getAllJSCollectionActions,
} from "ee/selectors/entitiesSelector";
import { get } from "lodash";
import type { FunctionCallingConfigFormToolField } from "components/formControls/FunctionCallingConfigControl/types";

const getActionSchemaGeneratingState = (state: AppState) =>
  state.ui.pluginActionEditor.isSchemaGenerating;

export const getIsAgentSchemaDirty = (state: AppState, id: string) =>
  !!state.ui.actionSchema.isAgentSchemaDirty[id];

export const isActionSchemaGenerating = (id: string) =>
  createSelector(
    [getActionSchemaGeneratingState],
    (isSchemaGeneratingMap) =>
      id in isSchemaGeneratingMap && isSchemaGeneratingMap[id],
  );

export const isJSActionGeneratingSchema = (
  state: AppState,
  collectionId: string,
) => state.ui.jsPane.isSchemaGenerating[collectionId];

export const getIsAnyActionSchemaDirty = (actionId: string) =>
  createSelector(
    getActions,
    getAllJSCollectionActions,
    (state: AppState) => getIsAgentSchemaDirty(state, actionId),
    (actions, jsCollectionActions, isAgentSchemaDirty) => {
      const agentQuery = actions.find(
        (action) => action.config.id === actionId,
      );
      const allAgentChatQueryFunctions: FunctionCallingConfigFormToolField[] =
        get(
          agentQuery,
          "config.actionConfiguration.formData.aiChatAssistant.input.functions",
          [],
        );

      const isDirty = allAgentChatQueryFunctions.some((tool) => {
        if (tool.entityType === "Query") {
          const action = actions.find(
            (action) => action.config.id === tool.entityId,
          );

          if (action) {
            return action.config.isDirtyMap?.SCHEMA_GENERATION;
          }
        } else if (tool.entityType === "JSFunction") {
          const jsFunction = jsCollectionActions.find(
            (jsFunction) => jsFunction.id === tool.entityId,
          );

          if (jsFunction) {
            return jsFunction.isDirtyMap?.SCHEMA_GENERATION;
          }
        }

        return false;
      });

      return isDirty || isAgentSchemaDirty;
    },
  );

export const getIsWarningPublishAnvilApplicationOpen = (state: AppState) =>
  state.ui.actionSchema.warnDirtyBeforePublish;

export const getIsSavingSchemaPostWarning = (state: AppState) =>
  state.ui.actionSchema.isSavingSchemaPostWarning;
