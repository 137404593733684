import type { ReactNode } from "react";
import React from "react";

import BoxLogo from "../assets/logos/box.svg";
import ConfluenceLogo from "../assets/logos/confluence.svg";
import DropboxLogo from "../assets/logos/dropbox.svg";
import FreshdeskLogo from "../assets/logos/freshdesk.svg";
import GmailLogo from "../assets/logos/gmail.svg";
import GoogleDriveLogo from "../assets/logos/google_drive.svg";
import IntercomLogo from "../assets/logos/intercom.svg";
import NotionLogo from "../assets/logos/notion.svg";
import OneDriveLogo from "../assets/logos/onedrive.svg";
import OutlookLogo from "../assets/logos/outlook.svg";
import S3Logo from "../assets/logos/s3.svg";
import SharePointLogo from "../assets/logos/sharepoint.svg";
import FileUploadIcon from "../assets/logos/file_upload.svg";
import WebScraperIcon from "../assets/logos/web_scraper.svg";
import JiraLogo from "../assets/logos/jira.svg";
// import SlackLogo from '../assets/logos/slack.svg';
import ZendeskLogo from "../assets/logos/zendesk.svg";
import ZoteroLogo from "../assets/logos/zotero.svg";
import GitbookLogo from "../assets/logos/gitbook.svg";
import GithubLogo from "../assets/logos/github.svg";
import SalesforceLogo from "../assets/logos/salesforce.svg";
import SlackLogo from "../assets/logos/slack.svg";
import GCSLogo from "../assets/logos/gcs-logo.svg";
import GuruLogo from "../assets/logos/guru-logo.svg";
import ServiceNowLogo from "../assets/logos/servicenow-logo.svg";
import AzureLogo from "../assets/logos/storage-blob.svg";
import Document360Logo from "../assets/logos/document360.png";
import { IntegrationName } from "../typing/shared";

export interface IntegrationItemType {
  id: IntegrationName;
  subpath: string;
  name: string;
  description: string;
  announcementName: string;
  icon: ReactNode;
  iconBgColor?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logo: any;
  active: boolean;
  data_source_type: IntegrationName;
  requiresOAuth: boolean;
  multiStep?: boolean;
  integrationsListViewTitle?: string;
  additionalInfo?: string;
  online?: boolean;
}

export const INTEGRATIONS_LIST: IntegrationItemType[] = [
  {
    id: IntegrationName.CONFLUENCE,
    subpath: "confluence",
    name: "Confluence",
    description:
      "Lets your users connect their Confluence accounts to Appsmith.",
    announcementName: "to connect Confluence",
    icon: <img className="cc-w-7 cc-h-7" src={ConfluenceLogo} />,
    iconBgColor: "gray-50",
    logo: ConfluenceLogo,
    active: true,
    data_source_type: IntegrationName.CONFLUENCE,
    requiresOAuth: true,
    multiStep: true,
    online: true,
  },
  {
    id: IntegrationName.LOCAL_FILES,
    subpath: "local",
    name: "Upload Files",
    description: "Lets your users upload local files to Appsmith.",
    announcementName: "to upload local files",
    icon: <img className="cc-w-7 cc-h-7" src={FileUploadIcon} />,
    iconBgColor: "gray-50",
    logo: FileUploadIcon,
    active: true,
    data_source_type: IntegrationName.LOCAL_FILES,
    requiresOAuth: false,
  },
  {
    id: IntegrationName.WEB_SCRAPER,
    subpath: "scraper",
    name: "Web Scraper",
    description: "Lets your users Scrape websites to Appsmith.",
    announcementName: "for Web Scraping",
    icon: <img className="cc-w-7 cc-h-7" src={WebScraperIcon} />,
    iconBgColor: "gray-50",
    logo: WebScraperIcon,
    active: true,
    data_source_type: IntegrationName.WEB_SCRAPER,
    requiresOAuth: false,
  },
  {
    id: IntegrationName.GITHUB,
    subpath: "github",
    name: "Github",
    description: "Lets your users connect their Gitbook accounts to Appsmith.",
    announcementName: "to connect Gitbook",
    icon: <img className="cc-w-7 cc-h-7" src={GithubLogo} />,
    iconBgColor: "gray-50",
    logo: GithubLogo,
    active: true,
    data_source_type: IntegrationName.GITHUB,
    requiresOAuth: false,
    multiStep: true,
    online: false,
  },
  {
    id: IntegrationName.DROPBOX,
    subpath: "dropbox",
    name: "Dropbox",
    description: "Lets your users connect their Dropbox accounts to Appsmith.",
    announcementName: "to connect Dropbox",
    icon: <img className="cc-w-7 cc-h-7" src={DropboxLogo} />,
    iconBgColor: "gray-50",
    logo: DropboxLogo,
    active: true,
    data_source_type: IntegrationName.DROPBOX,
    requiresOAuth: true,
  },
  {
    id: IntegrationName.GOOGLE_DRIVE,
    subpath: "google",
    name: "Google Drive",
    description: "Lets your users connect their Google Drive to Appsmith.",
    announcementName: "to connect Google Drive",
    icon: <img className="cc-w-7 cc-h-7" src={GoogleDriveLogo} />,
    iconBgColor: "gray-50",
    logo: GoogleDriveLogo,
    active: true,
    data_source_type: IntegrationName.GOOGLE_DRIVE,
    requiresOAuth: true,
    integrationsListViewTitle: "Google Drive",
  },
  {
    id: IntegrationName.GOOGLE_CLOUD_STORAGE,
    subpath: "gcs",
    name: "Google Cloud Storage",
    description:
      "Lets your users connect their Google Cloud Storage to Appsmith.",
    announcementName: "to connect Google Cloud Storage",
    icon: <img className="cc-w-7 cc-h-7" src={GCSLogo} />,
    iconBgColor: "gray-50",
    logo: GCSLogo,
    active: true,
    data_source_type: IntegrationName.GOOGLE_CLOUD_STORAGE,
    requiresOAuth: true,
    integrationsListViewTitle: "Google Cloud Storage",
  },
  {
    id: IntegrationName.GURU,
    subpath: "guru",
    name: "Guru",
    description: "Lets your users connect their Guru account to Appsmith.",
    announcementName: "to connect Guru",
    icon: <img className="cc-w-7 cc-h-7" src={GuruLogo} />,
    iconBgColor: "gray-50",
    logo: GuruLogo,
    active: true,
    data_source_type: IntegrationName.GURU,
    requiresOAuth: true,
    integrationsListViewTitle: "Guru",
  },
  {
    id: IntegrationName.NOTION,
    subpath: "notion",
    name: "Notion",
    description: "Lets your users connect their Notion accounts to Appsmith.",
    announcementName: "to connect Notion",
    icon: <img className="cc-w-7 cc-h-7" src={NotionLogo} />,
    iconBgColor: "gray-50",
    logo: NotionLogo,
    active: true,
    data_source_type: IntegrationName.NOTION,
    requiresOAuth: true,
  },
  {
    id: IntegrationName.SALESFORCE,
    subpath: "salesforce",
    name: "Salesforce",
    description:
      "Lets your users connect their Salesforce accounts to Appsmith.",
    announcementName: "to connect Salesforce",
    icon: <img className="cc-w-7 cc-h-7" src={SalesforceLogo} />,
    iconBgColor: "gray-50",
    logo: SalesforceLogo,
    active: true,
    data_source_type: IntegrationName.SALESFORCE,
    requiresOAuth: true,
    multiStep: true,
  },
  {
    id: IntegrationName.ZENDESK,
    subpath: "zendesk",
    name: "Zendesk",
    description: "Lets your users connect their Zendesk accounts to Appsmith.",
    announcementName: "to connect Zendesk",
    icon: <img className="cc-w-7 cc-h-7" src={ZendeskLogo} />,
    iconBgColor: "gray-50",
    logo: ZendeskLogo,
    active: true,
    data_source_type: IntegrationName.ZENDESK,
    requiresOAuth: true,
    multiStep: true,
  },
  {
    id: IntegrationName.ONEDRIVE,
    subpath: "onedrive",
    name: "OneDrive",
    description: "Lets your users connect their OneDrive accounts to Appsmith.",
    announcementName: "to connect OneDrive",
    icon: <img className="cc-w-7 cc-h-7" src={OneDriveLogo} />,
    iconBgColor: "gray-50",
    logo: OneDriveLogo,
    active: true,
    data_source_type: IntegrationName.ONEDRIVE,
    requiresOAuth: true,
  },
  {
    id: IntegrationName.S3,
    subpath: "s3",
    name: "S3",
    description: "Lets your users connect their data on S3 to Appsmith.",
    announcementName: "to connect S3",
    icon: <img className="cc-w-7 cc-h-7" src={S3Logo} />,
    iconBgColor: "gray-50",
    logo: S3Logo,
    active: true,
    data_source_type: IntegrationName.S3,
    requiresOAuth: false,
    multiStep: true,
  },
  {
    id: IntegrationName.SHAREPOINT,
    subpath: "sharepoint",
    name: "Sharepoint",
    description:
      "Lets your users connect their Sharepoint accounts to Appsmith.",
    announcementName: "to connect Sharepoint",
    icon: <img className="cc-w-7 cc-h-7" src={SharePointLogo} />,
    logo: SharePointLogo,
    active: true,
    data_source_type: IntegrationName.SHAREPOINT,
    requiresOAuth: true,
    multiStep: true,
  },
  {
    id: IntegrationName.GMAIL,
    subpath: "gmail",
    name: "Gmail",
    description: "Lets your users connect their Gmail to Appsmith.",
    announcementName: "to connect Gmail",
    icon: <img className="cc-w-7 cc-h-7" src={GmailLogo} />,
    iconBgColor: "gray-50",
    logo: GmailLogo,
    active: true,
    data_source_type: IntegrationName.GMAIL,
    requiresOAuth: true,
    integrationsListViewTitle: "Gmail",
  },
  {
    id: IntegrationName.FRESHDESK,
    subpath: "freshdesk",
    name: "Freshdesk",
    description:
      "Lets your users connect their Freshdesk accounts to Appsmith.",
    announcementName: "to connect Freshdesk",
    icon: <img className="cc-w-7 cc-h-7" src={FreshdeskLogo} />,
    logo: FreshdeskLogo,
    active: true,
    data_source_type: IntegrationName.FRESHDESK,
    requiresOAuth: true,
    multiStep: true,
  },
  {
    id: IntegrationName.BOX,
    subpath: "box",
    name: "Box",
    description: "Lets your users connect their Box accounts to Appsmith.",
    announcementName: "to connect Box",
    icon: <img alt="Box Logo" className="cc-w-7 cc-h-7" src={BoxLogo} />,
    iconBgColor: "gray-50",
    logo: BoxLogo,
    active: true,
    data_source_type: IntegrationName.BOX,
    requiresOAuth: true,
  },
  {
    id: IntegrationName.GITBOOK,
    subpath: "gitbook",
    name: "Gitbook",
    description: "Lets your users connect their Gitbook accounts to Appsmith.",
    announcementName: "to connect Gitbook",
    icon: <img className="cc-w-7 cc-h-7" src={GitbookLogo} />,
    logo: GitbookLogo,
    active: true,
    data_source_type: IntegrationName.GITBOOK,
    requiresOAuth: false,
    multiStep: true,
  },
  {
    id: IntegrationName.INTERCOM,
    subpath: "intercom",
    name: "Intercom",
    description: "Lets your users connect their Intercom to Appsmith.",
    announcementName: "to connect Intercom",
    icon: <img className="cc-w-7 cc-h-7" src={IntercomLogo} />,
    iconBgColor: "gray-50",
    logo: IntercomLogo,
    active: true,
    data_source_type: IntegrationName.INTERCOM,
    requiresOAuth: true,
  },
  {
    id: IntegrationName.OUTLOOK,
    subpath: "outlook",
    name: "Outlook",
    description: "Lets your users connect their Outlook accounts to Appsmith.",
    announcementName: "to connect Outlook",
    icon: <img className="cc-w-7 cc-h-7" src={OutlookLogo} />,
    logo: OutlookLogo,
    active: true,
    data_source_type: IntegrationName.OUTLOOK,
    requiresOAuth: true,
  },
  {
    id: IntegrationName.SERVICENOW,
    subpath: "servicenow",
    name: "ServiceNow",
    description:
      "Lets your users connect their ServiceNow accounts to Appsmith.",
    announcementName: "to connect ServiceNow",
    icon: <img className="cc-w-7 cc-h-7" src={ServiceNowLogo} />,
    logo: ServiceNowLogo,
    active: true,
    data_source_type: IntegrationName.SERVICENOW,
    requiresOAuth: true,
    multiStep: true,
  },
  {
    id: IntegrationName.SLACK,
    subpath: "slack",
    name: "Slack",
    description: "Lets your users connect their Slack accounts to Appsmith.",
    announcementName: "to connect Slack",
    icon: <img className="cc-w-7 cc-h-7" src={SlackLogo} />,
    logo: SlackLogo,
    active: true,
    data_source_type: IntegrationName.SLACK,
    requiresOAuth: true,
  },
  {
    id: IntegrationName.ZOTERO,
    subpath: "zotero",
    name: "Zotero",
    description: "Lets your users connect their Zotero accounts to Appsmith.",
    announcementName: "to connect Zotero",
    icon: <img className="cc-w-7 cc-h-7" src={ZoteroLogo} />,
    logo: ZoteroLogo,
    active: true,
    data_source_type: IntegrationName.ZOTERO,
    requiresOAuth: true,
    multiStep: false,
  },
  {
    id: IntegrationName.AZURE_BLOB_STORAGE,
    subpath: "AzureBlobStorage",
    name: "Azure Blob",
    description:
      "Lets your users connect their Azure Blob Storage to Appsmith.",
    announcementName: "to connect Azure Blob Storage",
    icon: <img className="cc-w-7 cc-h-7" src={AzureLogo} />,
    logo: AzureLogo,
    active: true,
    data_source_type: IntegrationName.AZURE_BLOB_STORAGE,
    requiresOAuth: false,
    multiStep: true,
  },
  {
    id: IntegrationName.DOCUMENT360,
    subpath: "Document360",
    name: "Document360",
    description:
      "Lets your users connect their Document360 workspaces to Appsmith.",
    announcementName: "to connect Document360",
    icon: <img className="cc-w-7 cc-h-7" src={Document360Logo} />,
    logo: Document360Logo,
    active: true,
    data_source_type: IntegrationName.DOCUMENT360,
    requiresOAuth: false,
    multiStep: true,
  },
  {
    id: IntegrationName.JIRA,
    subpath: "jira",
    name: "Jira",
    description: "Lets your users connect their Jira.",
    announcementName: "to connect Jira",
    icon: <img className="cc-w-7 cc-h-7" src={JiraLogo} />,
    logo: JiraLogo,
    active: true,
    data_source_type: IntegrationName.JIRA,
    requiresOAuth: true,
    multiStep: true,
  },
];
