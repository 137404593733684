import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { getCurrentPackageState } from "../utils/getCurrentPackageState";
import { EditorState } from "IDE/enums";

export const useCurrentPackageState = () => {
  const [appState, setAppState] = useState(EditorState.EDITOR);
  const { pathname } = useLocation();

  useEffect(
    function updateAppState() {
      setAppState(getCurrentPackageState(pathname));
    },
    [pathname],
  );

  return appState;
};
