import type { ReduxAction } from "actions/ReduxActionTypes";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { createImmerReducer } from "utils/ReducerUtils";

// Type for the action schema state in redux
export interface ActionSchemaReduxState {
  /**
   * @param {boolean} warnDirtyBeforePublish - Whether to warn when trying to publish with dirty action schema
   */
  warnDirtyBeforePublish: boolean;
  /**
   * @param {boolean} isSavingSchemaPostWarning - Whether the schema post warning is being saved
   */
  isSavingSchemaPostWarning: boolean;
  /**
   * @param {boolean} isAgentSchemaDirty - Whether the agent schema is dirty because the description of a Function was  changed
   */
  isAgentSchemaDirty: Record<string, boolean>;
}

// Initial state of the action schema state in redux
export const initialActionSchemaState: ActionSchemaReduxState = {
  warnDirtyBeforePublish: false,
  isSavingSchemaPostWarning: false,
  isAgentSchemaDirty: {},
};

// Reducer for the action schema state in redux
const handlers = {
  [ReduxActionTypes.SHOW_WARNING_PUBLISH_ANVIL_APPLICATION]: (
    state: ActionSchemaReduxState,
  ): ActionSchemaReduxState => ({
    ...state,
    warnDirtyBeforePublish: true,
  }),
  [ReduxActionTypes.HIDE_WARNING_PUBLISH_ANVIL_APPLICATION]: (
    state: ActionSchemaReduxState,
  ): ActionSchemaReduxState => ({
    ...state,
    warnDirtyBeforePublish: false,
  }),
  [ReduxActionTypes.SAVE_SCHEMA_POST_WARNING_INIT]: (
    state: ActionSchemaReduxState,
  ): ActionSchemaReduxState => ({
    ...state,
    isSavingSchemaPostWarning: true,
  }),
  [ReduxActionTypes.SAVE_SCHEMA_POST_WARNING_SUCCESS]: (
    state: ActionSchemaReduxState,
  ): ActionSchemaReduxState => ({
    ...state,
    isSavingSchemaPostWarning: false,
  }),
  [ReduxActionTypes.SET_AGENT_SCHEMA_DIRTY]: (
    state: ActionSchemaReduxState,
    action: ReduxAction<{ actionId: string }>,
  ): ActionSchemaReduxState => ({
    ...state,
    isAgentSchemaDirty: {
      ...state.isAgentSchemaDirty,
      [action.payload.actionId]: true,
    },
  }),
  [ReduxActionTypes.GENERATE_AI_AGENT_SCHEMA_SUCCESS]: (
    state: ActionSchemaReduxState,
    action: ReduxAction<{ id: string }>,
  ): ActionSchemaReduxState => ({
    ...state,
    isAgentSchemaDirty: {
      ...state.isAgentSchemaDirty,
      [action.payload.id]: false,
    },
  }),
};

export default createImmerReducer(initialActionSchemaState, handlers);
