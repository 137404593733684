import type { Dispatch, SetStateAction } from "react";
import React, { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import CalenderFooter from "./CalenderFooter";

interface PropsInfo {
  setOpen: Dispatch<SetStateAction<boolean>>;
  selected: Date | undefined;
  setSelected: Dispatch<SetStateAction<Date | undefined>>;
  setStoreDate: (date: string) => void;
}
const DatePicker = ({
  selected,
  setOpen,
  setSelected,
  setStoreDate,
}: PropsInfo) => {
  const [currentDate] = useState(new Date());

  useEffect(() => {
    const mainContainer = document.querySelector(".rdp");
    const newElement = document.createElement("div");

    newElement.setAttribute("class", "innerLayer");
    mainContainer?.appendChild(newElement);

    const innerLayer = document.querySelector(".innerLayer");

    innerLayer?.addEventListener("click", () => {
      setOpen(false);
    });
  }, []);

  return (
    <DayPicker
      footer={
        <CalenderFooter
          currentDate={currentDate}
          selected={selected}
          setOpen={setOpen}
          setStoreDate={setStoreDate}
        />
      }
      mode="single"
      onSelect={setSelected}
      selected={selected}
      showOutsideDays
      toDate={new Date()}
    />
  );
};

export default DatePicker;
