import React, { memo, useCallback, useEffect, useState, useMemo } from "react";
import {
  createMessage,
  ORG_NAME_LABEL,
  ORG_NAME_PLACEHOLDER,
  ORG_SUB_DOMAIN_PLACEHOLDER,
  ORG_SUB_DOMAIN_LABEL,
  ORG_UPDATE_HEADER,
  ORG_SUB_DOMAIN_POSTFIX,
  ORG_UPDATE_NEXT_BUTTON,
  ORG_SUB_DOMAIN_TAKEN,
  ORG_SUB_DOMAIN_CHECKING,
  ORG_SUB_DOMAIN_AVAILABLE,
} from "ee/constants/messages";
import AnalyticsUtil from "ee/utils/AnalyticsUtil";
import WelcomeBackground from "pages/setup/WelcomeBackground";
import {
  UserWelcomeScreenActionContainer,
  UserWelcomeScreenBannerHeader,
  UserWelcomeScreenTextBanner,
} from "pages/setup/common";
import { UserWelcomeScreenContent } from "pages/setup/common";
import { UserWelcomeScreenWrapper } from "pages/setup/common";
import { Button, Flex, Icon, Input, Spinner, Text } from "@appsmith/ads";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getTenantId } from "ee/selectors/organizationSelectors";
import debounce from "lodash/debounce";
import { OrganizationApi } from "ee/api/OrganizationApi";
import CsrfTokenInput from "pages/UserAuth/CsrfTokenInput";

interface OrgUpdateFormValues {
  orgName: string;
  orgSubdomain: string;
}

type SubDomainStatus = "checking" | "available" | "taken" | undefined;

const StyledInput = styled(Input)`
  .ads-v2-input__label {
    font-size: 16px;
    font-weight: 700;
  }
`;

const SUB_DOMAIN_STATUS_MAP = new Map([
  [
    "checking",
    {
      label: createMessage(ORG_SUB_DOMAIN_CHECKING),
      icon: <Spinner size="sm" />,
    },
  ],
  [
    "available",
    {
      label: createMessage(ORG_SUB_DOMAIN_AVAILABLE),
      icon: (
        <Icon color="var(--ads-v2-color-fg-success)" name="success" size="sm" />
      ),
    },
  ],
  [
    "taken",
    {
      label: createMessage(ORG_SUB_DOMAIN_TAKEN),
      icon: (
        <Icon color="var(--ads-v2-color-fg-error)" name="error" size="sm" />
      ),
    },
  ],
  [
    "undefined",
    {
      label: "",
      icon: "",
    },
  ],
]);

const OrgUpdateForm = () => {
  const tenantId = useSelector(getTenantId);

  /**
   *
   * URL - subdomain
   *
   */
  const SUBDOMAIN_URL = new URL(
    `/api/${OrganizationApi.tenantsUrl}/${tenantId}/subdomain`,
    window.location.origin,
  );

  /**
   * State - form values
   *
   * @type {OrgUpdateFormValues}
   */
  const [formValues, setFormValues] = useState<OrgUpdateFormValues>({
    orgName: "",
    orgSubdomain: "",
  });

  /**
   * State - whether the subdomain is valid
   *
   * @type {boolean}
   */
  const [isSubDomainValid, setIsSubDomainValid] = useState(false);

  /**
   * State - the status of the subdomain
   *
   * @type {SubDomainStatus}
   */
  const [subdomainStatus, setSubdomainStatus] =
    useState<SubDomainStatus>(undefined);

  /**
   * Check if the subdomain is valid
   *
   * @param value - The subdomain to check
   * @returns void
   */
  const checkSubDomainValidity = useCallback(
    async (value: string) => {
      try {
        setSubdomainStatus("checking");
        const response = await OrganizationApi.checkSubdomainAvailability({
          subdomain: value,
          tenantId,
        });

        setIsSubDomainValid(response.data as unknown as boolean);
        setSubdomainStatus(response.data ? "available" : "taken");
      } catch (error) {
        setIsSubDomainValid(false);
        setSubdomainStatus("taken");
      }
    },
    [tenantId],
  );

  /**
   * Debounce the subdomain check to avoid unnecessary API calls
   */
  const debouncedCheckSubDomain = useMemo(
    () => debounce(checkSubDomainValidity, 500),
    [checkSubDomainValidity],
  );

  /**
   * Handle the subdomain change
   *
   * @param value - The subdomain to change
   * @returns void
   */
  const handleSubDomainChange = useCallback(
    (value: string) => {
      //filter subdomain as per url standards
      value = value
        .toLowerCase()
        .replace(/[^a-z0-9-]/g, "")
        .replace(/^-+|-+$/g, "");
      setFormValues((prev) => ({ ...prev, orgSubdomain: value }));

      if (value && value.length >= 2 && value.length <= 63) {
        debouncedCheckSubDomain(value);
      } else {
        setSubdomainStatus(undefined);
        setIsSubDomainValid(false);
      }
    },
    [debouncedCheckSubDomain],
  );

  /**
   * Handle the name change
   *
   * @param value - The name to change
   * @returns void
   */
  const handleNameChange = useCallback((value: string) => {
    setFormValues((prev) => ({ ...prev, orgName: value }));
  }, []);

  return (
    <form action={SUBDOMAIN_URL.toString()} id="test-form" method="POST">
      <Flex flexDirection={"column"} gap={"spaces-11"} width={"322px"}>
        <CsrfTokenInput />
        <StyledInput
          data-testid="t--org-name"
          label={createMessage(ORG_NAME_LABEL)}
          name="orgName"
          onChange={handleNameChange}
          placeholder={createMessage(ORG_NAME_PLACEHOLDER)}
          size="md"
          value={formValues.orgName}
        />
        <Flex flexDirection="column" gap="spaces-2">
          <StyledInput
            data-testid="t--org-sub-domain"
            label={createMessage(ORG_SUB_DOMAIN_LABEL)}
            name="orgSubdomain"
            onChange={handleSubDomainChange}
            placeholder={createMessage(ORG_SUB_DOMAIN_PLACEHOLDER)}
            postfix={createMessage(ORG_SUB_DOMAIN_POSTFIX)}
            size="md"
            value={formValues.orgSubdomain}
          />
          <Flex flexDirection="row" gap="spaces-2" height="20px">
            {SUB_DOMAIN_STATUS_MAP.get(subdomainStatus ?? "undefined")?.icon}
            <Text>
              {
                SUB_DOMAIN_STATUS_MAP.get(subdomainStatus ?? "undefined")
                  ?.label as string
              }
            </Text>
          </Flex>
        </Flex>
        <Button
          isDisabled={!isSubDomainValid || !formValues.orgName}
          size="md"
          type="submit"
        >
          {createMessage(ORG_UPDATE_NEXT_BUTTON)}
        </Button>
      </Flex>
    </form>
  );
};

export default memo(function OrgUpdate() {
  /**
   * Log the event - orgUpdate
   *
   * @returns void
   */
  const logEvent = useCallback(() => {
    AnalyticsUtil.logEvent("PAGE_VIEW", {
      pageType: "orgUpdate",
    });
  }, []);

  /**
   * Log the event effect
   *
   * @returns void
   */
  useEffect(
    function loggingEffect() {
      logEvent();
    },
    [logEvent],
  );

  return (
    <UserWelcomeScreenWrapper data-testid={"welcome-page"}>
      <UserWelcomeScreenContent>
        <UserWelcomeScreenTextBanner>
          <UserWelcomeScreenBannerHeader className="!text-2xl">
            {createMessage(ORG_UPDATE_HEADER)}
          </UserWelcomeScreenBannerHeader>
          <UserWelcomeScreenActionContainer>
            <OrgUpdateForm />
          </UserWelcomeScreenActionContainer>
        </UserWelcomeScreenTextBanner>
        <WelcomeBackground />
      </UserWelcomeScreenContent>
    </UserWelcomeScreenWrapper>
  );
});
