import { modifyMetaWidgetsWithoutEval } from "actions/metaWidgetActions";
import type { ReduxAction } from "actions/ReduxActionTypes";
import { getCanvasWidgets } from "ce/selectors/entitiesSelector";
import { MAIN_CONTAINER_WIDGET_ID } from "constants/WidgetConstants";
import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import type { ModuleInstance } from "ee/constants/ModuleInstanceConstants";
import type { Layout } from "ee/constants/ModuleLayoutConstants";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import type { FlattenedWidgetProps } from "ee/reducers/entityReducers/canvasWidgetsReducer";
import { getShowUIModule } from "ee/selectors/moduleFeatureSelectors";
import {
  getAllModuleInstances,
  getUiModuleInstances,
} from "ee/selectors/moduleInstanceSelectors";
import { MAIN_MODULE_CONTAINER_WIDGET_ID } from "ee/utils/Packages/createBaseModuleDSL";
import { recursivelyGenerateMetaWidgets } from "ee/utils/Packages/moduleInstanceHelpers";
import moduleWidgetFactory from "ee/widgets/ModuleWidgetFactory";
import { get } from "lodash";
import log from "loglevel";
import { all, call, delay, put, select, takeLatest } from "redux-saga/effects";
import { failFastApiCalls } from "sagas/InitSagas";
import { getMetaWidgets } from "selectors/editorSelectors";
import WidgetFactory from "WidgetProvider/factory";
import { registerWidgets } from "WidgetProvider/factory/registrationHelper";
import type { WidgetProps } from "widgets/BaseWidget";

/**
 * Checks if a widget has changed based on property updates
 */
function hasWidgetChanged(
  widget: WidgetProps,
  mainContainerWidget: WidgetProps,
  propertyUpdates: { propertyPath: string; propertyValue: unknown }[],
) {
  let hasChanged = false;

  propertyUpdates.forEach((update) => {
    const updatedValue = get(mainContainerWidget, update.propertyPath);

    if (widget[update.propertyPath] !== updatedValue) {
      hasChanged = true;
    }
  });

  return hasChanged;
}

export function* registerUIModuleInstanceIfNotRegisteredSaga(
  moduleInstance: ModuleInstance<Layout>,
) {
  const widgetConfigs = WidgetFactory.getConfigs();

  const moduleWidgetType = "MODULE_WIDGET_" + moduleInstance.moduleUUID;
  const moduleWidgetConfig = widgetConfigs[moduleWidgetType];

  if (!moduleWidgetConfig) {
    yield call(registerUIModuleInstance, moduleInstance);
  }
}

/**
 * Registers a UI module instance with the widget factory
 */
export function* registerUIModuleInstance(
  moduleInstance: ModuleInstance<Layout>,
) {
  try {
    const dsl = moduleInstance.layouts?.[0]?.dsl;

    if (!dsl) {
      return;
    }

    const container = (dsl?.children || []).find(
      (child: WidgetProps) =>
        child.widgetId === MAIN_MODULE_CONTAINER_WIDGET_ID,
    );

    if (!container) {
      return;
    }

    const instanceMetadata = {
      rows: container.bottomRow - container.topRow,
      columns: container.rightColumn - container.leftColumn,
    };

    registerWidgets([
      // TODO: Fix this
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      moduleWidgetFactory({
        ...instanceMetadata,
        moduleUUID: moduleInstance.moduleUUID,
        widgetName: moduleInstance.name,
      }),
    ]);

    return true;
  } catch (error) {
    log.error("Error registering UI module instance:", error);
  }
}

/**
 * Generates meta widgets for a UI module instance
 */
export function* generateUIModuleMetaWidgetsSaga(
  action: ReduxAction<{ moduleInstance: ModuleInstance<Layout> }>,
) {
  try {
    const { moduleInstance } = action.payload;
    const flattenedMetaWidgets: Record<string, FlattenedWidgetProps> = {};
    const metaWidgets: ReturnType<typeof getMetaWidgets> =
      yield select(getMetaWidgets);
    const rootContainerWidget = moduleInstance.layouts?.[0]?.dsl;

    const mainContainerWidget = rootContainerWidget?.children?.find(
      (child) => child.widgetId === MAIN_MODULE_CONTAINER_WIDGET_ID,
    );

    const modalWidgets = rootContainerWidget?.children?.filter(
      (child) => child.type === "MODAL_WIDGET",
    );

    if (!mainContainerWidget) {
      throw new Error("Main container widget not found");
    }

    if (!moduleInstance.widgetId) {
      throw new Error("Module instance widget id not found");
    }

    recursivelyGenerateMetaWidgets({
      moduleInstanceName: moduleInstance.name,
      widgets: mainContainerWidget.children as WidgetProps[],
      flattenedWidgets: flattenedMetaWidgets,
      parentId: moduleInstance.widgetId,
      creatorId: moduleInstance.widgetId,
    });

    const modalMetaWidgetsIds = recursivelyGenerateMetaWidgets({
      moduleInstanceName: moduleInstance.name,
      widgets: modalWidgets as WidgetProps[],
      flattenedWidgets: flattenedMetaWidgets,
      parentId: MAIN_CONTAINER_WIDGET_ID,
      creatorId: moduleInstance.widgetId,
    });

    modalMetaWidgetsIds.forEach((modalWidgetId) => {
      if (flattenedMetaWidgets[modalWidgetId]) {
        flattenedMetaWidgets[modalWidgetId].hasMetaWidgets = true;
      }
    });

    // Cleanup old meta widgets for this module instance widget
    const metaWidgetsToDelete = Object.values(metaWidgets)
      .filter((metaWidget) => metaWidget.creatorId === moduleInstance.widgetId)
      .map((metaWidget) => metaWidget.widgetId);

    yield put(
      modifyMetaWidgetsWithoutEval({
        addOrUpdate: flattenedMetaWidgets,
        deleteIds: metaWidgetsToDelete,
        propertyUpdates: [],
        creatorId: moduleInstance.widgetId,
      }),
    );

    // Adding a minimal delay (1ms) to ensure this success action is dispatched
    // after the race effect in failFastApiCalls is set up and listening.
    // Without this delay, the success action might be dispatched too quickly,
    // before the race effect has a chance to register, causing failFastApiCalls
    // to miss the success action. This tiny delay yields control back to the
    // event loop, allowing other saga effects to complete their setup.
    yield delay(1);

    yield put({
      type: ReduxActionTypes.GENERATE_UI_MODULE_META_WIDGETS_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GENERATE_UI_MODULE_META_WIDGETS_ERROR,
      payload: error,
    });
  }
}

/**
 * Updates the root module instance widget properties
 */
export function* updateRootModuleInstanceWidgetSaga(
  action: ReduxAction<{
    moduleInstance: ModuleInstance<Layout>;
  }>,
) {
  try {
    const { moduleInstance } = action.payload;
    const widgets: ReturnType<typeof getCanvasWidgets> =
      yield select(getCanvasWidgets);

    if (!moduleInstance.widgetId) {
      throw new Error("Module instance widget id not found");
    }

    const widget = widgets[moduleInstance.widgetId];
    const rootContainerWidget = moduleInstance.layouts?.[0]?.dsl;
    const mainContainerWidget = rootContainerWidget?.children?.find(
      (child) => child.widgetId === MAIN_MODULE_CONTAINER_WIDGET_ID,
    );

    if (!widget || !mainContainerWidget) {
      throw new Error("Root module instance widget not found");
    }

    const propertiesToUpdate = [
      "boxShadow",
      "borderColor",
      "shouldScrollContents",
      "animateLoading",
      "borderWidth",
      "flexVerticalAlignment",
      "backgroundColor",
      "dynamicHeight",
      "containerStyle",
      "minWidth",
      "isVisible",
      "isLoading",
      "responsiveBehavior",
      "borderRadius",
      "maxDynamicHeight",
      "minDynamicHeight",
    ];

    const propertyUpdates = propertiesToUpdate.map((prop) => ({
      propertyPath: prop,
      propertyValue: mainContainerWidget?.[prop],
    }));

    // Add moduleInstanceId property update
    // propertyUpdates.push({
    //   propertyPath: "moduleInstanceId",
    //   propertyValue: moduleInstance.id,
    // });

    // propertyUpdates.push({
    //   propertyPath: "isCreationPending",
    //   propertyValue: false,
    // });

    if (!hasWidgetChanged(widget, mainContainerWidget, propertyUpdates)) {
      // No changes detected, still mark as success
      yield put({
        type: ReduxActionTypes.UPDATE_ROOT_MODULE_INSTANCE_WIDGET_SUCCESS,
      });

      return;
    }

    yield put({
      type: ReduxActionTypes.UPDATE_MULTIPLE_WIDGET_PROPERTIES,
      payload: {
        widgetsToUpdate: {
          [moduleInstance.widgetId]: propertyUpdates,
        },
        shouldEval: false,
      },
    });

    // Adding a minimal delay (1ms) to ensure this success action is dispatched
    // after the race effect in failFastApiCalls is set up and listening.
    // Without this delay, the success action might be dispatched too quickly,
    // before the race effect has a chance to register, causing failFastApiCalls
    // to miss the success action. This tiny delay yields control back to the
    // event loop, allowing other saga effects to complete their setup.
    yield delay(1);

    yield put({
      type: ReduxActionTypes.UPDATE_ROOT_MODULE_INSTANCE_WIDGET_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.UPDATE_ROOT_MODULE_INSTANCE_WIDGET_ERROR,
      payload: error,
    });
  }
}

/**
 * Generates module widgets from instances for edit mode
 */
export function* generateModuleWidgetsFromInstancesSaga() {
  try {
    const showUIModule: boolean = yield select(getShowUIModule);

    if (!showUIModule) {
      return;
    }

    const uiModuleInstances: ModuleInstance<Layout>[] = yield select(
      getUiModuleInstances<Layout>(),
    );

    for (const moduleInstance of uiModuleInstances) {
      yield call(registerUIModuleInstanceIfNotRegisteredSaga, moduleInstance);

      const generationSuccess: boolean = yield failFastApiCalls(
        [
          {
            type: ReduxActionTypes.GENERATE_UI_MODULE_META_WIDGETS_INIT,
            payload: { moduleInstance: moduleInstance },
          },
          {
            type: ReduxActionTypes.UPDATE_ROOT_MODULE_INSTANCE_WIDGET_INIT,
            payload: { moduleInstance: moduleInstance },
          },
        ],
        [
          ReduxActionTypes.GENERATE_UI_MODULE_META_WIDGETS_SUCCESS,
          ReduxActionTypes.UPDATE_ROOT_MODULE_INSTANCE_WIDGET_SUCCESS,
        ],
        [
          ReduxActionErrorTypes.GENERATE_UI_MODULE_META_WIDGETS_ERROR,
          ReduxActionErrorTypes.UPDATE_ROOT_MODULE_INSTANCE_WIDGET_ERROR,
        ],
      );

      if (!generationSuccess) {
        throw new Error(
          `Failed generating module widgets for ${moduleInstance.name}`,
        );
      }
    }

    yield put({
      type: ReduxActionTypes.GENERATE_UI_MODULE_WIDGET_FROM_INSTANCES_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GENERATE_UI_MODULE_WIDGET_FROM_INSTANCES_ERROR,
      payload: error,
    });
  }
}

/**
 * Generates module widgets from instances for view mode
 */
export function* generateModuleWidgetsFromInstancesForViewModeSaga() {
  try {
    const showUIModule: boolean = yield select(getShowUIModule);

    if (!showUIModule) {
      return;
    }

    const moduleInstances: ReturnType<typeof getAllModuleInstances> =
      yield select(getAllModuleInstances);

    const uiModuleInstances = Object.values(moduleInstances).filter(
      (moduleInstance) => moduleInstance.type === MODULE_TYPE.UI,
    ) as ModuleInstance<Layout>[];

    for (const moduleInstance of uiModuleInstances) {
      yield call(registerUIModuleInstanceIfNotRegisteredSaga, moduleInstance);

      const generationSuccess: boolean = yield failFastApiCalls(
        [
          {
            type: ReduxActionTypes.GENERATE_UI_MODULE_META_WIDGETS_INIT,
            payload: { moduleInstance: moduleInstance },
          },
        ],
        [ReduxActionTypes.GENERATE_UI_MODULE_META_WIDGETS_SUCCESS],
        [ReduxActionErrorTypes.GENERATE_UI_MODULE_META_WIDGETS_ERROR],
      );

      if (!generationSuccess) {
        throw new Error(
          `Failed generating module widgets for ${moduleInstance.name}`,
        );
      }
    }

    yield put({
      type: ReduxActionTypes.GENERATE_UI_MODULE_WIDGET_FROM_INSTANCES_FOR_VIEW_MODE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: ReduxActionErrorTypes.GENERATE_UI_MODULE_WIDGET_FROM_INSTANCES_FOR_VIEW_MODE_ERROR,
      payload: error,
    });
  }
}

export default function* uiModuleInstanceSagas() {
  yield all([
    takeLatest(
      ReduxActionTypes.GENERATE_UI_MODULE_META_WIDGETS_INIT,
      generateUIModuleMetaWidgetsSaga,
    ),
    takeLatest(
      ReduxActionTypes.UPDATE_ROOT_MODULE_INSTANCE_WIDGET_INIT,
      updateRootModuleInstanceWidgetSaga,
    ),
    takeLatest(
      ReduxActionTypes.FETCH_PAGE_SUCCESS,
      generateModuleWidgetsFromInstancesSaga,
    ),
    takeLatest(
      [
        // This gets called when page in loaded
        ReduxActionTypes.FETCH_PUBLISHED_PAGE_SUCCESS,
        // This gets called when page in switched
        ReduxActionTypes.FETCH_PUBLISHED_PAGE_RESOURCES_SUCCESS,
      ],
      generateModuleWidgetsFromInstancesForViewModeSaga,
    ),
  ]);
}
