import logoCarbon from "./logo-carbon.svg";
import rubberConnect from "./rubber-connect.png";
import lock from "./svgIcons/lock.svg";
import shield from "./svgIcons/shield.svg";
import backIcon from "./svgIcons/back-icon.svg";
import crossIcon from "./svgIcons/cross-icon.svg";
import searchIcon from "./svgIcons/search-icon.svg";
import monitor from "./svgIcons/monitor.svg";
import info_fill from "./svgIcons/info_fill.svg";
import left_icon from "./svgIcons/left_icon.svg";
import trash_2 from "./svgIcons/trash-2icon.svg";
import tabler_sitemap from "./svgIcons/tabler_sitemap.svg";
import filter from "./svgIcons/filter.svg";
import addIcon from "./svgIcons/add.svg";
import menuIcon from "./svgIcons/menu-icon.svg";
import successIcon from "./svgIcons/success.svg";
import solidplusIcon from "./svgIcons/solid-plus.svg";
import fileUploadSuccess from "./svgIcons/file-upload-success.svg";
import mp2 from "./svgIcons/mp2.svg";
import mp3 from "./svgIcons/mp3.svg";
import mp4 from "./svgIcons/mp4.svg";
import acc from "./svgIcons/aac.svg";
import wav from "./svgIcons/wav.svg";
import flac from "./svgIcons/flac.svg";
import pcm from "./svgIcons/pcm.svg";
import m4a from "./svgIcons/m4a.svg";
import ogg from "./svgIcons/ogg.svg";
import opus from "./svgIcons/opus.svg";
import webm from "./svgIcons/webm.svg";
import jpg from "./svgIcons/jpg.svg";
import png from "./svgIcons/png.svg";
import pdf from "./svgIcons/pdf.svg";
import xlsx from "./svgIcons/xlsx.svg";
import csv from "./svgIcons/csv.svg";
import docx from "./svgIcons/docx.svg";
import txt from "./svgIcons/txt.svg";
import md from "./svgIcons/md.svg";
import rtf from "./svgIcons/rtf.svg";
import tsv from "./svgIcons/tsv.svg";
import pptx from "./svgIcons/pptx.svg";
import json from "./svgIcons/json.svg";
import greenTick from "./svgIcons/greenTick.svg";
import warningTick from "./svgIcons/warningIcon.svg";
import verticleSeperate from "./svgIcons/divider_vertical.svg";
import deleteIcon from "./svgIcons/deleteIcon.svg";
import menudot from "./svgIcons/menuDots.svg";
import deleteIconBlack from "./svgIcons/deleteIcon_black.svg";
import folderIcon from "./svgIcons/folderIcon.svg";
import coreTick from "./svgIcons/coreTick.svg";
import infoClear from "./svgIcons/info_color.svg";
import html from "./svgIcons/htmlIcon.svg";
import rubber from "./rubberImage.svg";

export const images = {
  logoCarbon,
  rubberConnect,
  lock,
  shield,
  backIcon,
  crossIcon,
  searchIcon,
  monitor,
  info_fill,
  left_icon,
  trash_2,
  tabler_sitemap,
  filter,
  addIcon,
  menuIcon,
  successIcon,
  solidplusIcon,
  fileUploadSuccess,
  mp2,
  mp3,
  mp4,
  json,
  jpg,
  pdf,
  png,
  acc,
  wav,
  flac,
  pcm,
  m4a,
  opus,
  ogg,
  webm,
  xlsx,
  docx,
  csv,
  txt,
  md,
  rtf,
  tsv,
  pptx,
  greenTick,
  warningTick,
  verticleSeperate,
  deleteIcon,
  menudot,
  deleteIconBlack,
  folderIcon,
  coreTick,
  infoClear,
  html,
  rubber,
};
