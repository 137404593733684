import { put, take } from "redux-saga/effects";
import type { CreateReleaseTagInitPayload } from "git/store/actions/createReleaseTagActions";
import type { GitArtifactPayloadAction } from "git/store/types";
import { publishPackage } from "ee/actions/packageActions";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";
import { ReduxActionErrorTypes } from "ee/constants/ReduxActionConstants";
import { GitOpsTab } from "git/constants/enums";
import { gitCreateReleaseTagSuccess, gitToggleOpsModal } from "git/store";

export default function* packageCreateReleaseTagSaga(
  action: GitArtifactPayloadAction<CreateReleaseTagInitPayload>,
) {
  const { artifactDef, artifactId } = action.payload;

  const payload = {
    packageId: artifactId,
    commitSHA: action.payload.commitSHA,
    tag: action.payload.tag,
    releaseNote: action.payload.releaseNote,
  };

  yield put(publishPackage(payload));

  yield take([
    ReduxActionTypes.PUBLISH_PACKAGE_SUCCESS,
    ReduxActionErrorTypes.PUBLISH_PACKAGE_ERROR,
  ]);

  yield put(gitCreateReleaseTagSuccess({ artifactDef }));
  yield put(
    gitToggleOpsModal({
      artifactDef,
      open: false,
      tab: GitOpsTab.Deploy,
    }),
  );
}
