import * as React from "react";
import { Slot } from "@radix-ui/react-slot";

import ChevRightSmall from "../../../assets/svgIcons/chevron-right-small.svg";
import { cn } from "./utils";

const Breadcrumb = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"nav"> & {
    separator?: React.ReactNode;
  }
>(({ ...props }, ref) => <nav aria-label="breadcrumb" ref={ref} {...props} />);

Breadcrumb.displayName = "Breadcrumb";

const BreadcrumbList = React.forwardRef<
  HTMLOListElement,
  React.ComponentPropsWithoutRef<"ol">
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <ol
    className={cn(
      "cc-flex cc-flex-wrap cc-items-center cc-gap-1.5 cc-break-words cc-text-sm cc-text-low_em",
      className,
    )}
    ref={ref}
    {...props}
  />
));

BreadcrumbList.displayName = "BreadcrumbList";

const BreadcrumbItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentPropsWithoutRef<"li">
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <li
    className={cn("cc-inline-flex cc-items-center cc-gap-1.5", className)}
    ref={ref}
    {...props}
  />
));

BreadcrumbItem.displayName = "BreadcrumbItem";

const BreadcrumbLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<"a"> & {
    asChild?: boolean;
  }
  // eslint-disable-next-line react/prop-types
>(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : "a";

  return (
    <Comp
      className={cn("cc-transition-colors hover:cc-text-high_em", className)}
      ref={ref}
      {...props}
    />
  );
});

BreadcrumbLink.displayName = "BreadcrumbLink";

const BreadcrumbPage = React.forwardRef<
  HTMLSpanElement,
  React.ComponentPropsWithoutRef<"span">
  // eslint-disable-next-line react/prop-types
>(({ className, ...props }, ref) => (
  <span
    aria-current="page"
    aria-disabled="true"
    className={cn("cc-font-semibold cc-text-high_em", className)}
    ref={ref}
    role="link"
    {...props}
  />
));

BreadcrumbPage.displayName = "BreadcrumbPage";

const BreadcrumbSeparator = ({
  children,
  // eslint-disable-next-line react/prop-types
  className,
  ...props
}: React.ComponentProps<"li">) => (
  <li
    aria-hidden="true"
    className={cn("[&>img]:cc-size-5", className)}
    role="presentation"
    {...props}
  >
    {children ?? (
      <img
        alt="Chev Right Icon"
        className="cc-w-6 cc-shrink-0 dark:cc-invert-[1] dark:cc-hue-rotate-180"
        src={ChevRightSmall}
      />
    )}
  </li>
);

BreadcrumbSeparator.displayName = "BreadcrumbSeparator";

const BreadcrumbEllipsis = ({
  // eslint-disable-next-line react/prop-types
  className,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    aria-hidden="true"
    className={cn(
      "cc-flex cc-h-9 cc-w-9 cc-items-center cc-justify-center",
      className,
    )}
    role="presentation"
    {...props}
  >
    {/* <MoreHorizontal className="h-4 w-4" /> */}
    <span className="sr-only">More</span>
  </span>
);

BreadcrumbEllipsis.displayName = "BreadcrumbElipssis";

export {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
  BreadcrumbEllipsis,
};
