import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  createMessage,
  LOGIN_PAGE_SIGN_UP_LINK_TEXT,
  NEW_TO_APPSMITH,
  ORG_SUB_DOMAIN_POSTFIX,
  SIGN_IN_TO_ORG,
  SIGN_IN_TO_ORG_PAGE_SUBMIT_BUTTON,
  SIGN_IN_TO_ORG_PAGE_SUBTITLE,
  SIGN_IN_TO_ORG_PAGE_URL_PLACEHOLDER,
} from "ee/constants/messages";
import { Link, Callout, Button, Input } from "@appsmith/ads";

import AnalyticsUtil from "ee/utils/AnalyticsUtil";

import { useSelector } from "react-redux";

import { getIsSafeRedirectURL } from "utils/helpers";
import Container from "pages/UserAuth/Container";
import { getOrganizationConfig } from "ee/selectors/organizationSelectors";
import Helmet from "react-helmet";
import { useFeatureFlag } from "utils/hooks/useFeatureFlag";
import { FEATURE_FLAG } from "ee/entities/FeatureFlag";
import { getHTMLPageTitle } from "ee/utils/BusinessFeatures/brandingPageHelpers";
import * as Sentry from "@sentry/react";
import { BASE_URL } from "constants/routes";
import { SpacedSubmitForm } from "pages/UserAuth/StyledComponents";

const OrgForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  let signupURL = BASE_URL;
  const redirectUrl = queryParams.get("redirectUrl");

  if (redirectUrl != null && getIsSafeRedirectURL(redirectUrl)) {
    const encodedRedirectUrl = encodeURIComponent(redirectUrl);

    signupURL += `?redirectUrl=${encodedRedirectUrl}`;
  }

  useEffect(function logEvent() {
    AnalyticsUtil.logEvent("SIGNUP_REACHED", {
      referrer: document.referrer,
    });
  }, []);

  const isBrandingEnabled = useFeatureFlag(
    FEATURE_FLAG.license_branding_enabled,
  );
  const organizationConfig = useSelector(getOrganizationConfig);
  const { instanceName } = organizationConfig;
  const htmlPageTitle = getHTMLPageTitle(isBrandingEnabled, instanceName);
  const [subdomain, setSubdomain] = useState("");

  let showError = false;
  let errorMessage = "";

  if (queryParams.get("error")) {
    errorMessage = queryParams.get("error") || "";
    showError = true;
    Sentry.captureException("Org sign selection failed", {
      level: "error",
      extra: {
        error: new Error(errorMessage),
      },
    });
  }

  const renderFooter = () => (
    <div className="px-2 flex align-center justify-center text-center text-[color:var(--ads-v2\-color-fg)] text-[14px]">
      {createMessage(NEW_TO_APPSMITH)}&nbsp;
      <Link
        className="t--sign-up t--signup-link"
        kind="primary"
        target="_self"
        to={signupURL}
      >
        {createMessage(LOGIN_PAGE_SIGN_UP_LINK_TEXT)}
      </Link>
    </div>
  );

  const handleChange = useCallback((value: string) => {
    //filter subdomain as per url standards
    value = value
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "")
      .replace(/^-+|-+$/g, "");
    setSubdomain(value);
  }, []);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const url = new URL(window.location.href);

      url.hostname = `${subdomain}.${url.hostname.split(".").slice(1).join(".")}`;
      window.location.href = url.toString();
    },
    [subdomain],
  );

  return (
    <Container
      footer={renderFooter()}
      subtitle={createMessage(SIGN_IN_TO_ORG_PAGE_SUBTITLE)}
      title={createMessage(SIGN_IN_TO_ORG)}
    >
      <Helmet>
        <title>{htmlPageTitle}</title>
      </Helmet>

      {showError && <Callout kind="error">{errorMessage}</Callout>}
      <SpacedSubmitForm onSubmit={handleSubmit}>
        <Input
          autoFocus
          name="subdomain"
          onChange={handleChange}
          placeholder={createMessage(SIGN_IN_TO_ORG_PAGE_URL_PLACEHOLDER)}
          postfix={createMessage(ORG_SUB_DOMAIN_POSTFIX)}
          size="md"
          type="text"
          value={subdomain}
        />
        <Button isDisabled={!subdomain} kind="primary" size="md" type="submit">
          {createMessage(SIGN_IN_TO_ORG_PAGE_SUBMIT_BUTTON)}
        </Button>
      </SpacedSubmitForm>
    </Container>
  );
};

export default OrgForm;
