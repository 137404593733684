export * from "ce/IDE/constants/routes";
import {
  EntityPaths as CE_EntityPaths,
  IDEBasePaths as CE_IDEBasePaths,
} from "ce/IDE/constants/routes";
import {
  ADD_PATH,
  MODULE_INSTANCE_ID_PATH,
} from "ee/constants/routes/appRoutes";
import {
  MODULE_EDITOR_PATH,
  PACKAGE_EDITOR_PATH,
  UI_PACKAGE_MODULE_EDITOR_PATH,
  UI_PACKAGE_EDITOR_PATH,
} from "ee/constants/routes/packageRoutes";
import { WORKFLOW_EDITOR_URL } from "ee/constants/routes/workflowRoutes";

import { IDE_TYPE, type IDEType } from "ee/IDE/Interfaces/IDETypes";

export const EntityPaths = [
  ...CE_EntityPaths,
  MODULE_INSTANCE_ID_PATH,
  MODULE_INSTANCE_ID_PATH + ADD_PATH,
];
export const IDEBasePaths: Readonly<Record<IDEType, string[]>> = {
  ...CE_IDEBasePaths,
  [IDE_TYPE.Package]: [PACKAGE_EDITOR_PATH, MODULE_EDITOR_PATH],
  [IDE_TYPE.Workflow]: [WORKFLOW_EDITOR_URL],
  [IDE_TYPE.UIPackage]: [UI_PACKAGE_EDITOR_PATH, UI_PACKAGE_MODULE_EDITOR_PATH],
};
