import React from "react";
import { Header } from "./Header";
// import { GitPackageContextProvider } from "git-artifact-helpers/ee/packages/components";

export function UIPackageHeader() {
  return (
    // <GitPackageContextProvider>
    <Header />
    // </GitPackageContextProvider>
  );
}
