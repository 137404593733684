import React from "react";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";

import { getThemeDetails, ThemeMode } from "selectors/themeSelectors";
import type { AppState } from "ee/reducers";
import { useIsMobileDevice } from "utils/hooks/useDeviceDetect";
import { getAssetUrl } from "ee/utils/airgapHelpers";
import { getOrganizationConfig } from "ee/selectors/organizationSelectors";
import FooterLinks from "pages/UserAuth/FooterLinks";
import { requiresUnauth } from "pages/UserAuth/requiresAuthHOC";
import { BASE_URL, ORG_LOGIN_PATH } from "constants/routes";
import Org from "./Org";
import SignUp from "pages/UserAuth/SignUp";
import VerificationError from "pages/UserAuth/VerificationError";
import VerifyUser from "pages/UserAuth/VerifyUser";
import VerificationPending from "pages/UserAuth/VerificationPending";

const SentryRoute = Sentry.withSentryRouting(Route);

export function CloudBilling() {
  const location = useLocation();
  const lightTheme = useSelector((state: AppState) =>
    getThemeDetails(state, ThemeMode.LIGHT),
  );
  const isMobileDevice = useIsMobileDevice();
  const organizationConfig = useSelector(getOrganizationConfig);

  return (
    <ThemeProvider theme={lightTheme}>
      <div
        className={`absolute inset-0 flex flex-col overflow-y-auto auth-container bg-[color:var(--ads-color-background-secondary)] ${
          !isMobileDevice ? "p-4" : "px-6 py-12"
        } t--auth-container justify-between`}
      >
        {isMobileDevice && (
          <img
            className="h-8 mx-auto"
            src={getAssetUrl(organizationConfig.brandLogoUrl)}
          />
        )}
        <Switch location={location}>
          <SentryRoute component={SignUp} exact path={BASE_URL} />
          <SentryRoute component={Org} exact path={ORG_LOGIN_PATH} />
          <SentryRoute
            component={VerificationPending}
            exact
            path={`/user/verificationPending`}
          />
          <SentryRoute component={VerifyUser} exact path={`/user/verify`} />
          <SentryRoute
            component={VerificationError}
            exact
            path={`/user/verify-error`}
          />
          <Route path="*">
            <Redirect to={BASE_URL} />
          </Route>
        </Switch>
        {/* render only if not 404 */}
        {[BASE_URL, ORG_LOGIN_PATH].includes(location.pathname) && (
          <FooterLinks />
        )}
      </div>
    </ThemeProvider>
  );
}

export default requiresUnauth(CloudBilling);
