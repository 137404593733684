import { AppsmithError, ErrorCodes } from "../../base/appsmithError";

/**
 * Default timeout in milliseconds for API requests
 */
export const DEFAULT_REQUEST_TIMEOUT_MS = 45000; // 45 seconds

/**
 * Error message for timeout errors
 */
export const REQUEST_TIMEOUT_ERROR_MESSAGE = "Request timed out";

/**
 * Wraps a function with a timeout. If the function doesn't complete within the specified
 * timeout period, a timeout error is thrown.
 *
 * @param fn The function to wrap with a timeout
 * @param fnDescription A descriptive name of the utility/service method being called (e.g., "ChatService.sendMessage")
 * @param timeoutMs Timeout duration in milliseconds (defaults to 60 seconds)
 * @returns A promise that resolves with the result of the function or rejects with a timeout error
 */
export async function withTimeout<T>(
  fn: () => Promise<T>,
  fnDescription: string,
  timeoutMs: number = DEFAULT_REQUEST_TIMEOUT_MS,
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    // Set up the timeout
    const timeoutId = setTimeout(() => {
      // Create a timeout error using the REQUEST_TIMEOUT_ERROR code
      const errorMessage = `Request from ${fnDescription} timed out`;
      const error = new AppsmithError(
        ErrorCodes.REQUEST_TIMEOUT_ERROR,
        errorMessage,
      );

      reject(error);
    }, timeoutMs);

    // Execute the function
    fn()
      .then((result) => {
        // Clear the timeout if the function completes successfully
        clearTimeout(timeoutId);
        resolve(result);
      })
      .catch((error) => {
        // Clear the timeout if the function throws an error
        clearTimeout(timeoutId);
        reject(error);
      });
  });
}

/**
 * Usage examples for the withTimeout utility
 *
 * Example 1: Basic usage with a function that returns a promise
 * ```
 * // Original function
 * async function fetchData(id: string): Promise<Data> {
 *   const response = await fetch(`/api/data/${id}`);
 *   return response.json();
 * }
 *
 * // With timeout (30 seconds)
 * try {
 *   const data = await withTimeout(
 *     () => fetchData("123"),
 *     "DataService.fetchData", // Clear description of the function being called
 *     30000
 *   );
 *   // Process data
 * } catch (error) {
 *   // Handle timeout or other errors
 * }
 * ```
 *
 * Example 2: Using with service methods
 * ```
 * // In a chat service method
 * async function sendMessage(threadId: string, message: string): Promise<Response> {
 *   return withTimeout(
 *     () => actualSendMessageImplementation(threadId, message),
 *     "ChatService.sendMessage", // Service and method name for clear identification
 *     60000 // 1 minute timeout
 *   );
 * }
 * ```
 *
 * Example 3: Using with service methods that make API calls
 * ```
 * // Original service method
 * class ChatService {
 *   async getMessages(threadId: string): Promise<Message[]> {
 *     // Implementation that calls an API
 *   }
 *
 *   // Modified to include timeout
 *   getMessagesWithTimeout(threadId: string): Promise<Message[]> {
 *     return withTimeout(
 *       () => this.getMessages(threadId),
 *       "ChatService.getMessages", // Include the class and method name
 *       DEFAULT_REQUEST_TIMEOUT_MS
 *     );
 *   }
 * }
 * ```
 *
 * Example 4: Real-world usage in RTS Chat services
 * ```
 * // In the ChatService class
 * async fetchCitation(request: FetchCitationFileRequestDto): Promise<FetchCitationFileResponseDto> {
 *   return withTimeout(
 *     () => this.actualFetchCitation(request),
 *     "ChatService.fetchCitation",
 *     DEFAULT_REQUEST_TIMEOUT_MS
 *   );
 * }
 *
 * // In the RagService class
 * async search(searchRequest: RagSearchRequestDto): Promise<RagSearchResponseDto> {
 *   return withTimeout(
 *     () => this.performSearch(searchRequest),
 *     "RagService.search",
 *     DEFAULT_REQUEST_TIMEOUT_MS
 *   );
 * }
 * ```
 */
