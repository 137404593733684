import type { FocusStrategy } from "ee/navigation/FocusStrategy/types";
import PackageFocusElements from "../FocusElements/PackageIDE";
import type { FocusEntityInfo } from "navigation/FocusEntity";
import {
  FocusEntity,
  FocusStoreHierarchy,
  identifyEntityFromPath,
} from "navigation/FocusEntity";
import {
  datasourcesEditorURL,
  jsCollectionModuleListURL,
  queryModuleListURL,
} from "ee/RouteBuilder";
import { EditorState } from "IDE/enums";
import { all, take } from "redux-saga/effects";
import { ReduxActionTypes } from "ee/constants/ReduxActionConstants";

export const createEditorFocusInfoKey = (basePackageId: string) => {
  return `PACKAGE_EDITOR.${basePackageId}`;
};

export const createEditorFocusInfo = (basePackageId: string) => ({
  key: createEditorFocusInfoKey(basePackageId),
  entityInfo: {
    id: `PACKAGE_EDITOR.${basePackageId}`,
    appState: EditorState.EDITOR,
    entity: FocusEntity.EDITOR,
    params: {},
  },
});

const isPackageChange = (prevPath: string, currentPath: string) => {
  const prevFocusEntityInfo = identifyEntityFromPath(prevPath);
  const currFocusEntityInfo = identifyEntityFromPath(currentPath);

  const prevPkgId = prevFocusEntityInfo.params.basePackageId;
  const currentPkgId = currFocusEntityInfo.params.basePackageId;

  if (prevPkgId === "" || currentPkgId === "") {
    return false;
  }

  return prevPkgId !== currentPkgId;
};

export const PackageFocusStrategy: FocusStrategy = {
  focusElements: PackageFocusElements,
  *getEntitiesForSet(previousPath: string, currentPath: string) {
    const entities = [];
    const prevEntityInfo = identifyEntityFromPath(previousPath);
    const currentEntityInfo = identifyEntityFromPath(currentPath);

    if (
      currentEntityInfo.entity === FocusEntity.CANVAS &&
      (prevEntityInfo.params.basePackageId !==
        currentEntityInfo.params.basePackageId ||
        prevEntityInfo.appState !== currentEntityInfo.appState)
    ) {
      if (currentEntityInfo.params.basePackageId) {
        entities.push(
          createEditorFocusInfo(currentEntityInfo.params.basePackageId),
        );
      }
    }

    entities.push({
      entityInfo: currentEntityInfo,
      key: currentPath,
    });

    return entities;
  },
  *getEntitiesForStore(previousPath: string, currentPath: string) {
    const entities = [];
    const prevFocusEntityInfo = identifyEntityFromPath(previousPath);
    const currentFocusEntityInfo = identifyEntityFromPath(currentPath);

    if (prevFocusEntityInfo.entity in FocusStoreHierarchy) {
      const parentEntity = FocusStoreHierarchy[prevFocusEntityInfo.entity];

      if (parentEntity && parentEntity !== currentFocusEntityInfo.entity) {
        const parentPath = PackageFocusStrategy.getEntityParentUrl(
          prevFocusEntityInfo,
          parentEntity,
        );

        entities.push({
          entityInfo: {
            entity: parentEntity,
            id: "",
            appState: prevFocusEntityInfo.appState,
            params: prevFocusEntityInfo.params,
          },
          key: parentPath,
        });
      }
    }

    // Store editor state if previous url was in editor.
    // Does not matter if still in editor or not
    if (
      prevFocusEntityInfo.appState === EditorState.EDITOR &&
      prevFocusEntityInfo.entity !== FocusEntity.NONE &&
      (prevFocusEntityInfo.entity !== currentFocusEntityInfo.entity ||
        prevFocusEntityInfo.params.basePackageId !==
          currentFocusEntityInfo.params.basePackageId)
    ) {
      if (prevFocusEntityInfo.params.basePackageId) {
        entities.push(
          createEditorFocusInfo(prevFocusEntityInfo.params.basePackageId),
        );
      }
    }

    if (
      !Object.values(FocusStoreHierarchy).includes(prevFocusEntityInfo.entity)
    ) {
      entities.push({
        entityInfo: prevFocusEntityInfo,
        key: previousPath,
      });
    }

    return entities;
  },
  getEntityParentUrl: (
    entityInfo: FocusEntityInfo,
    parentEntity: FocusEntity,
  ): string => {
    let parentUrl: string = "";

    if (parentEntity === FocusEntity.DATASOURCE_LIST) {
      parentUrl = datasourcesEditorURL({});
    }

    if (parentEntity === FocusEntity.JS_OBJECT_LIST) {
      parentUrl = jsCollectionModuleListURL({ baseModuleId: "" });
    }

    if (parentEntity === FocusEntity.QUERY_LIST) {
      parentUrl = queryModuleListURL({ baseModuleId: "" });
    }

    // We do not have to add any query params because this url is used as the key
    return parentUrl.split("?")[0];
  },
  getUrlKey: function* (url: string) {
    return url;
  },
  *waitForPathLoad(currentPath: string, previousPath?: string) {
    if (previousPath) {
      // When package is changing, there may be some items still not loaded,
      // so wait till the package fetch is complete
      if (isPackageChange(previousPath, currentPath)) {
        yield all([
          take(ReduxActionTypes.FETCH_PACKAGE_SUCCESS),
          take(ReduxActionTypes.FETCH_PLUGINS_SUCCESS),
        ]);
      }
    } else {
      // Wait for the package's actions and plugins to be fetched
      // so we know if we should focus the Headers or Body tab in the API Editor,
      // which depends on the action type.
      yield all([
        take(ReduxActionTypes.FETCH_ACTIONS_SUCCESS),
        take(ReduxActionTypes.FETCH_PLUGINS_SUCCESS),
      ]);
    }
  },
};
