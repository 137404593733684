import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { getFormValues } from "redux-form";
import { RagChunkControls } from "./RagChunkControls";
import upperCase from "lodash/upperCase";
import get from "lodash/get";
import { Flex, Switch, Select, Option, Callout } from "@appsmith/ads";
import { RagIntegrationTitle } from "./RagIntegrationTitle";
import {
  updateIntegrationProperty,
  updateFileIntegrationProperty,
} from "./RagUtils";
import type { RagIntegrationsControlsProps } from "./types";
import type {
  Integration,
  LocalFilesIntegration,
} from "@appsmith/carbon-connect";

export const RagIntegrationsControls = (
  props: RagIntegrationsControlsProps,
) => {
  const { configProperty, formName, input } = props;
  const formValues = useSelector((state) => getFormValues(formName)(state));
  const integrations: Integration[] = get(formValues, configProperty, []);

  const updateIntegrationsChunkSize = useCallback(
    (id: string, value: number) => {
      if (integrations) {
        input?.onChange(
          updateIntegrationProperty(integrations, id, "chunkSize", value),
        );
      }
    },
    [integrations, input],
  );

  const updateIntegrationsOverlapSize = useCallback(
    (id: string, value: number) => {
      if (integrations) {
        input?.onChange(
          updateIntegrationProperty(integrations, id, "overlapSize", value),
        );
      }
    },
    [integrations, input],
  );

  const updateIntegrationsFileChunkSize = useCallback(
    (extension: string, value: number) => {
      if (integrations) {
        input?.onChange(
          updateFileIntegrationProperty(
            integrations,
            extension,
            "chunkSize",
            value,
          ),
        );
      }
    },
    [integrations, input],
  );

  const updateIntegrationsFileOverlapSize = useCallback(
    (extension: string, value: number) => {
      if (integrations) {
        input?.onChange(
          updateFileIntegrationProperty(
            integrations,
            extension,
            "overlapSize",
            value,
          ),
        );
      }
    },
    [integrations, input],
  );

  const updateIntegrationsPdfOcr = useCallback(
    (value: boolean) => {
      if (integrations) {
        input?.onChange(
          updateFileIntegrationProperty(integrations, "PDF", "useOcr", value),
        );
      }
    },
    [integrations, input],
  );

  const updateIntegrationsPdfSetPageAsBoundary = useCallback(
    (value: string) => {
      const booleanValue = value === "true";

      if (integrations) {
        input?.onChange(
          updateFileIntegrationProperty(
            integrations,
            "PDF",
            "setPageAsBoundary",
            booleanValue,
          ),
        );
      }
    },
    [integrations, input],
  );

  const renderContent = () => {
    return (
      <Flex flexDirection="column" gap="spaces-4" maxWidth="600px">
        <Callout>
          Configure how sources are processed. Changes will apply to new data
          points and existing sources after resync.
        </Callout>

        <Flex flexDirection="column" gap="spaces-11" marginBottom="spaces-12">
          {integrations?.map((integration) => (
            <Flex flexDirection="column" key={integration.id}>
              {integration.id === "LOCAL_FILES" && (
                <>
                  <RagIntegrationTitle title={integration.id} />
                  <Flex
                    flexDirection="column"
                    gap="spaces-9"
                    key={integration.id}
                    paddingLeft="spaces-9"
                  >
                    {(
                      integration as LocalFilesIntegration
                    ).allowedFileTypes?.map((fileType) => (
                      <Flex flexDirection="column" key={fileType.extension}>
                        <RagIntegrationTitle
                          title={fileType.extension}
                          variant="subtitle"
                        />
                        {/*Additional controls are needed for the PDF, so we handle this case separately.*/}
                        {upperCase(fileType.extension) === "PDF" && (
                          <Flex flexDirection="column" gap="spaces-7">
                            <Flex alignItems="center" gap="spaces-7">
                              <Select
                                onChange={
                                  updateIntegrationsPdfSetPageAsBoundary
                                }
                                value={
                                  fileType.setPageAsBoundary ? "true" : "false"
                                }
                              >
                                <Option value="true">Page Size</Option>
                                <Option value="false">Token Limit</Option>
                              </Select>
                              <Switch
                                defaultSelected={fileType.useOcr}
                                onChange={updateIntegrationsPdfOcr}
                              >
                                <Flex gap="spaces-4">Enable OCR</Flex>
                              </Switch>
                            </Flex>
                            <RagChunkControls
                              chunkSize={fileType.chunkSize}
                              isDisabled={fileType.setPageAsBoundary}
                              onChangeChunkSize={(v) =>
                                updateIntegrationsFileChunkSize("PDF", v)
                              }
                              onChangeOverlapSize={(v) =>
                                updateIntegrationsFileOverlapSize("PDF", v)
                              }
                              overlapSize={fileType.overlapSize}
                            />
                          </Flex>
                        )}

                        {upperCase(fileType.extension) !== "PDF" && (
                          <RagChunkControls
                            chunkSize={fileType.chunkSize}
                            onChangeChunkSize={(v) =>
                              updateIntegrationsFileChunkSize(
                                fileType.extension,
                                v,
                              )
                            }
                            onChangeOverlapSize={(v) =>
                              updateIntegrationsFileOverlapSize(
                                fileType.extension,
                                v,
                              )
                            }
                            overlapSize={fileType.overlapSize}
                          />
                        )}
                      </Flex>
                    ))}
                  </Flex>
                </>
              )}

              {integration.id !== "LOCAL_FILES" && (
                <>
                  <RagIntegrationTitle title={integration.id} />
                  <RagChunkControls
                    chunkSize={integration.chunkSize}
                    onChangeChunkSize={(v) =>
                      updateIntegrationsChunkSize(integration.id, v)
                    }
                    onChangeOverlapSize={(v) =>
                      updateIntegrationsOverlapSize(integration.id, v)
                    }
                    overlapSize={integration.overlapSize}
                  />
                </>
              )}
            </Flex>
          ))}
        </Flex>
      </Flex>
    );
  };

  return renderContent();
};
