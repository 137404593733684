export { createOrUpdateDataSourceWithAction } from "ce/sagas/DatasourcesSagas";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
  ReduxFormActionTypes,
} from "ee/constants/ReduxActionConstants";
import type {
  ReduxAction,
  ReduxActionWithCallbacks,
} from "actions/ReduxActionTypes";
import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  addAndFetchDatasourceStructureSaga,
  addMockDbToDatasources,
  changeDatasourceSaga,
  createDatasourceFromFormSaga,
  datasourceDiscardActionSaga,
  deleteDatasourceSaga,
  executeDatasourceQuerySaga,
  fetchDatasourcesSaga,
  fetchDatasourceStructureSaga,
  fetchGsheetColumns,
  fetchGsheetSheets,
  fetchGsheetSpreadhsheets,
  fetchMockDatasourcesSaga,
  filePickerActionCallbackSaga,
  formValueChangeSaga,
  getOAuthAccessTokenSaga,
  handleDatasourceNameChangeFailureSaga,
  handleFetchDatasourceStructureOnLoad,
  initializeFormWithDefaults,
  loadFilePickerSaga,
  redirectAuthorizationCodeSaga,
  refreshDatasourceStructure,
  setDatasourceViewModeSaga as CE_setDatasourceViewModeSaga,
  storeAsDatasourceSaga,
  switchDatasourceSaga,
  testDatasourceSaga,
  updateDatasourceAuthStateSaga,
  updateDatasourceNameSaga,
  updateDatasourceSaga as CE_updateDatasourceSaga,
  updateDatasourceSuccessSaga,
  createTempDatasourceFromFormSaga as CE_createTempDatasourceFromFormSaga,
} from "ce/sagas/DatasourcesSagas";
import {
  AuthenticationType,
  type Datasource,
  type ExternalSaasDSAuthentication,
} from "entities/Datasource";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import type { WorkspaceToken } from "ee/constants/workspaceConstants";
import {
  getCurrentEnvironmentDetails,
  getCurrentEnvironmentId,
} from "ee/selectors/environmentSelectors";
import { getDatasources, getPlugin } from "ce/selectors/entitiesSelector";
import { type Plugin, PluginType } from "entities/Plugin";
import { paragon, type IntegrationInstallEvent } from "@useparagon/connect";
import { get, set } from "lodash";
import { getCurrentUser } from "selectors/usersSelectors";
import type { User } from "constants/userConstants";
import { datasourcesEditorIdURL } from "ee/RouteBuilder";
import history, { NavigationMethod } from "utils/history";
import WorkspaceApi, {
  type FetchWorkspaceTokenResponse,
} from "ee/api/WorkspaceApi";
import { type CreateDatasourceConfig } from "ee/api/DatasourcesApi";
import {
  createDatasourceActionPayloadFromParagonEvent,
  getProviderDataArray,
  paragonInstallIntegration,
} from "ee/sagas/helpers";

export function* paragonInit() {
  const workspaceId: string = yield select(getCurrentWorkspaceId);
  const currentUser: User = yield select(getCurrentUser);

  yield call(paragon.configureGlobal, {
    host: "connect.appsmith.com",
  });

  const responseToken: FetchWorkspaceTokenResponse = yield call(
    WorkspaceApi.fetchWorkspaceToken,
    {
      workspaceId: workspaceId,
    },
  );

  const currentWorkspaceToken: WorkspaceToken = responseToken.data;

  if (!currentWorkspaceToken.token || !currentWorkspaceToken.projectId) {
    throw new Error("Project Id and Token not set");
  }

  yield call(
    paragon.authenticate,
    currentWorkspaceToken.projectId,
    currentWorkspaceToken.token,
    {
      metadata: {
        Name: currentUser.name,
        Email: currentUser.email,
      },
    },
  );
}

/**
 * The main difference between this and the CE version is that for integrations that
 * are type of "EXTERNAL_SAAS", we create datasource through paragon integrations. For everything else,
 * it just calls the CE version.
 */
export function* createTempDatasourceFromFormSaga(
  actionPayload: ReduxAction<CreateDatasourceConfig | Datasource>,
) {
  yield put({
    type: ReduxActionTypes.CREATE_DATASOURCE_INIT,
  });
  const currentEnvId: string = yield select(getCurrentEnvironmentId);
  const datasources: Datasource[] = yield select(getDatasources);
  const plugin: Plugin = yield select(
    getPlugin,
    actionPayload?.payload?.pluginId,
  );

  if (plugin?.type === PluginType.EXTERNAL_SAAS) {
    try {
      yield call(paragonInit);
      yield put({
        type: ReduxActionTypes.CREATE_DATASOURCE_FROM_FORM_TOGGLE_LOADING,
        payload: {
          loading: false,
        },
      });

      const event: IntegrationInstallEvent = yield call(
        paragonInstallIntegration,
        plugin,
      );

      // we need to close the paragon modal otherwise when we navigate the newly created datasource later on,
      // the paragon portal gets unmounted but some state of the paragon portal remains in memory. and when you edit
      // the datasource, and click on the "Connect datasource" button, you will see two paragon modals
      paragon.closePortal();

      // now at this point, we would have successfully authenticated the paragon integration
      // we are now ready to create the datasource
      const createDatasourceActionPayload: CreateDatasourceConfig = yield call(
        createDatasourceActionPayloadFromParagonEvent,
        {
          event,
          currentEnvId,
          datasources,
          plugin,
        },
      );

      yield call(createDatasourceFromFormSaga, {
        payload: createDatasourceActionPayload,
        type: ReduxActionTypes.CREATE_DATASOURCE_FROM_FORM_INIT,
      });
    } catch (error) {
      yield put({
        type: ReduxActionErrorTypes.CREATE_DATASOURCE_ERROR,
        payload: { error },
      });
    }
  } else {
    yield call(CE_createTempDatasourceFromFormSaga, actionPayload);
  }
}

// Then modify handleParagonUpdateSuccess similarly
function* handleParagonUpdateSuccess(
  event: IntegrationInstallEvent,
  actionPayload: ReduxActionWithCallbacks<
    Datasource & { isInsideReconnectModal: boolean; currEditingEnvId?: string },
    unknown,
    unknown
  >,
  currentEnvId: string,
) {
  const { credential, credentialId, integrationId, integrationType } =
    event || {};
  const { providerData = {} } = credential || {};

  const providerDataArray: { key: string; value: string | boolean | number }[] =
    yield call(
      getProviderDataArray,
      providerData,
      credentialId,
      integrationType,
    );

  set(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication.providerData`,
    providerDataArray,
  );

  set(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication.credentialId`,
    credentialId,
  );

  set(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication.integrationId`,
    integrationId,
  );

  set(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication.integrationType`,
    integrationType,
  );

  set(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication.authenticationType`,
    AuthenticationType.EXTERNAL_SAAS_AUTHENTICATION,
  );

  yield call(CE_updateDatasourceSaga, actionPayload);
}

export function* updateDatasourceSaga(
  actionPayload: ReduxActionWithCallbacks<
    Datasource & { isInsideReconnectModal: boolean; currEditingEnvId?: string },
    unknown,
    unknown
  >,
) {
  const currentEnvDetails: { editingId: string; name: string } = yield select(
    getCurrentEnvironmentDetails,
  );
  const currentEnvId =
    actionPayload.payload?.currEditingEnvId || currentEnvDetails.editingId;
  const plugin: Plugin = yield select(
    getPlugin,
    actionPayload?.payload?.pluginId,
  );

  const authenticationPayload = get(
    actionPayload.payload,
    `datasourceStorages[${currentEnvId}].datasourceConfiguration.authentication`,
  ) as ExternalSaasDSAuthentication;

  if (plugin?.type === PluginType.EXTERNAL_SAAS) {
    try {
      yield call(paragonInit);
      yield put({
        type: ReduxActionTypes.CREATE_DATASOURCE_FROM_FORM_TOGGLE_LOADING,
        payload: {
          loading: false,
        },
      });
      // Convert Promise-based integration installation to saga using Promise wrapper
      const event: IntegrationInstallEvent = yield call(
        async () =>
          new Promise((resolve, reject) => {
            paragon.installIntegration(plugin.pluginName!, {
              allowMultipleCredentials: true,
              // @ts-expect-error  selectedCredentialId & onClose function is not present in type
              selectedCredentialId: authenticationPayload?.credentialId,
              onSuccess: resolve,
              onError: reject,
              accountType: ["default", "user-configured-oauth"],
            });
          }),
      );

      // Now we can call our success handler
      yield call(
        handleParagonUpdateSuccess,
        event,
        actionPayload,
        currentEnvId,
      );
    } catch (error) {
      yield put({
        type: ReduxActionErrorTypes.UPDATE_DATASOURCE_ERROR,
        payload: { error },
      });
    }
  } else {
    yield call(CE_updateDatasourceSaga, actionPayload);
  }
}

export function* setDatasourceViewModeSaga(
  action: ReduxAction<{ datasourceId: string; viewMode: boolean }>,
) {
  yield call(CE_setDatasourceViewModeSaga, action);
}

/**
 * external saas datasource ( type === EXTERNAL_SAAS ) are created via paragon flow.
 * This function handles the success of the create datasource action for those external saas datasources.
 */
function* handleExternalSaasDatasourceCreationSuccess(
  action: ReduxAction<Datasource>,
) {
  const plugin: Plugin | undefined = yield select(
    getPlugin,
    action.payload.pluginId,
  );

  if (plugin && plugin.type !== PluginType.EXTERNAL_SAAS) return;

  history.replace(
    datasourcesEditorIdURL({
      datasourceId: action.payload.id,
    }),
    {
      invokedBy: NavigationMethod.AppNavigation,
    },
  );
}

export function* watchDatasourcesSagas() {
  yield all([
    takeEvery(ReduxActionTypes.FETCH_DATASOURCES_INIT, fetchDatasourcesSaga),
    takeEvery(
      ReduxActionTypes.FETCH_MOCK_DATASOURCES_INIT,
      fetchMockDatasourcesSaga,
    ),
    takeEvery(
      ReduxActionTypes.ADD_MOCK_DATASOURCES_INIT,
      addMockDbToDatasources,
    ),
    takeEvery(
      ReduxActionTypes.CREATE_DATASOURCE_FROM_FORM_INIT,
      createDatasourceFromFormSaga,
    ),
    takeEvery(
      ReduxActionTypes.CREATE_TEMP_DATASOURCE_FROM_FORM_SUCCESS,
      createTempDatasourceFromFormSaga,
    ),
    takeEvery(ReduxActionTypes.UPDATE_DATASOURCE_INIT, updateDatasourceSaga),
    takeEvery(
      ReduxActionTypes.UPDATE_DATASOURCE_NAME,
      updateDatasourceNameSaga,
    ),
    takeEvery(
      ReduxActionErrorTypes.UPDATE_DATASOURCE_NAME_ERROR,
      handleDatasourceNameChangeFailureSaga,
    ),
    takeEvery(ReduxActionTypes.TEST_DATASOURCE_INIT, testDatasourceSaga),
    takeEvery(ReduxActionTypes.DELETE_DATASOURCE_INIT, deleteDatasourceSaga),
    takeEvery(ReduxActionTypes.CHANGE_DATASOURCE, changeDatasourceSaga),
    takeLatest(ReduxActionTypes.SWITCH_DATASOURCE, switchDatasourceSaga),
    takeEvery(ReduxActionTypes.STORE_AS_DATASOURCE_INIT, storeAsDatasourceSaga),
    takeEvery(
      ReduxActionTypes.UPDATE_DATASOURCE_SUCCESS,
      updateDatasourceSuccessSaga,
    ),
    takeEvery(
      ReduxActionTypes.REDIRECT_AUTHORIZATION_CODE,
      redirectAuthorizationCodeSaga,
    ),
    takeEvery(ReduxActionTypes.GET_OAUTH_ACCESS_TOKEN, getOAuthAccessTokenSaga),
    takeEvery(
      ReduxActionTypes.FETCH_DATASOURCE_STRUCTURE_INIT,
      fetchDatasourceStructureSaga,
    ),
    takeEvery(
      ReduxActionTypes.REFRESH_DATASOURCE_STRUCTURE_INIT,
      refreshDatasourceStructure,
    ),
    takeEvery(
      ReduxActionTypes.EXECUTE_DATASOURCE_QUERY_INIT,
      executeDatasourceQuerySaga,
    ),
    takeEvery(
      ReduxActionTypes.INITIALIZE_DATASOURCE_FORM_WITH_DEFAULTS,
      initializeFormWithDefaults,
    ),
    // Intercepting the redux-form change actionType to update drafts and track change history
    takeEvery(ReduxFormActionTypes.VALUE_CHANGE, formValueChangeSaga),
    takeEvery(ReduxFormActionTypes.ARRAY_PUSH, formValueChangeSaga),
    takeEvery(ReduxFormActionTypes.ARRAY_REMOVE, formValueChangeSaga),
    takeEvery(
      ReduxActionTypes.FILE_PICKER_CALLBACK_ACTION,
      filePickerActionCallbackSaga,
    ),
    takeLatest(
      ReduxActionTypes.FETCH_GSHEET_SPREADSHEETS,
      fetchGsheetSpreadhsheets,
    ),
    takeLatest(ReduxActionTypes.FETCH_GSHEET_SHEETS, fetchGsheetSheets),
    takeLatest(ReduxActionTypes.FETCH_GSHEET_COLUMNS, fetchGsheetColumns),
    takeEvery(ReduxActionTypes.LOAD_FILE_PICKER_ACTION, loadFilePickerSaga),
    takeEvery(
      ReduxActionTypes.UPDATE_DATASOURCE_AUTH_STATE,
      updateDatasourceAuthStateSaga,
    ),
    takeEvery(
      ReduxActionTypes.DATASOURCE_DISCARD_ACTION,
      datasourceDiscardActionSaga,
    ),
    takeEvery(
      ReduxActionTypes.ADD_AND_FETCH_MOCK_DATASOURCE_STRUCTURE_INIT,
      addAndFetchDatasourceStructureSaga,
    ),
    takeEvery(
      ReduxActionTypes.FETCH_DATASOURCES_SUCCESS,
      handleFetchDatasourceStructureOnLoad,
    ),
    takeEvery(
      ReduxActionTypes.SOFT_REFRESH_DATASOURCE_STRUCTURE,
      handleFetchDatasourceStructureOnLoad,
    ),
    takeEvery(
      ReduxActionTypes.SET_DATASOURCE_EDITOR_MODE,
      setDatasourceViewModeSaga,
    ),
    takeEvery(
      ReduxActionTypes.CREATE_DATASOURCE_SUCCESS,
      handleExternalSaasDatasourceCreationSuccess,
    ),
  ]);
}
