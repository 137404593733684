export * from "ce/sagas/helpers";
import type {
  GenerateDestinationIdInfoReturnType as CE_GenerateDestinationIdInfoReturnType,
  ResolveParentEntityMetadataReturnType,
} from "ce/sagas/helpers";
import {
  CreateNewActionKey,
  type CreateNewActionKeyInterface,
} from "ee/entities/Engine/actionHelpers";
import {
  resolveParentEntityMetadata as CE_resolveParentEntityMetadata,
  transformTriggerEvalErrors as ce_transformTriggerEvalErrors,
  generateDestinationIdInfoForQueryDuplication as CE_generateDestinationIdInfoForQueryDuplication,
} from "ce/sagas/helpers";
import type { Action } from "entities/Action";
import type { Log } from "entities/AppsmithConsole";
import { ENTITY_TYPE } from "ee/entities/AppsmithConsole/utils";
import type {
  ModuleInstanceAction,
  ModuleInstanceJSCollection,
} from "ee/reducers/entityReducers/moduleInstanceEntitiesReducer";
import { call, select, take } from "redux-saga/effects";
import {
  getAllEnhancedReferencedModuleInstance,
  getModuleInstanceActionById,
  getModuleInstanceJSCollectionById,
  getPrivateEntityInstanceMapping,
} from "ee/selectors/moduleInstanceSelectors";
import { getModuleInstanceById } from "ee/selectors/moduleInstanceSelectors";
import type { ModuleInstance } from "ee/constants/ModuleInstanceConstants";
import { klona } from "klona/json";
import type { DeleteErrorLogPayload } from "actions/debuggerActions";
import { getIsFetchingConsumableModules } from "ee/selectors/modulesSelector";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "ee/constants/ReduxActionConstants";
import type { EvaluationError } from "utils/DynamicBindingUtils";
import { objectKeys } from "@appsmith/utils";
import { ToastMessageType } from "entities/Datasource";
import { AuthenticationType } from "entities/Datasource";
import { TEMP_DATASOURCE_ID } from "constants/Datasource";
import { DATASOURCE_NAME_DEFAULT_PREFIX } from "constants/Datasource";
import { paragon, type IntegrationInstallEvent } from "@useparagon/connect";
import type { Datasource } from "entities/Datasource";
import { PluginType, type Plugin } from "entities/Plugin";
import { getNextEntityName } from "utils/AppsmithUtils";
import type { CreateDatasourceConfig } from "ee/api/DatasourcesApi";
import { toast } from "@appsmith/ads";
import DatasourcesApi from "ee/api/DatasourcesApi";
import { getCurrentWorkspaceId } from "ee/selectors/selectedWorkspaceSelectors";
import type { ApiResponse } from "api/ApiResponses";

export interface GenerateDestinationIdInfoReturnType
  extends CE_GenerateDestinationIdInfoReturnType {
  workflowId?: string;
  moduleId?: string;
}

export function generateDestinationIdInfoForQueryDuplication(
  destinationEntityId: string,
  parentEntityKey: CreateNewActionKeyInterface,
): GenerateDestinationIdInfoReturnType {
  const result = CE_generateDestinationIdInfoForQueryDuplication(
    destinationEntityId,
    parentEntityKey,
  );

  if (objectKeys(result).length > 0) return result;

  if (parentEntityKey === CreateNewActionKey.WORKFLOW) {
    return { workflowId: destinationEntityId };
  }

  if (parentEntityKey === CreateNewActionKey.MODULE) {
    return { moduleId: destinationEntityId };
  }

  return {};
}

export const resolveParentEntityMetadata = (
  action: Partial<Action>,
): ResolveParentEntityMetadataReturnType => {
  const result = CE_resolveParentEntityMetadata(action);

  if (result.parentEntityId) return result;

  if (action.moduleId) {
    return {
      parentEntityId: action.moduleId,
      parentEntityKey: CreateNewActionKey.MODULE,
    };
  }

  if (action.workflowId) {
    return {
      parentEntityId: action.workflowId,
      parentEntityKey: CreateNewActionKey.WORKFLOW,
    };
  }

  return { parentEntityId: undefined, parentEntityKey: undefined };
};

function* waitForFetchingConsumableModules() {
  const isFetchingConsumableModules: boolean = yield select(
    getIsFetchingConsumableModules,
  );

  if (isFetchingConsumableModules) {
    yield take([
      ReduxActionTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_SUCCESS,
      ReduxActionErrorTypes.FETCH_CONSUMABLE_PACKAGES_IN_WORKSPACE_ERROR,
    ]);
  }
}

function* resolveModuleInstance(
  moduleInstanceId: string,
  referencedModuleInstances: ModuleInstance[],
) {
  const moduleInstance: ModuleInstance | undefined = yield select(
    getModuleInstanceById,
    moduleInstanceId,
  );

  let referencedInstance: ModuleInstance | undefined;

  if (!moduleInstance) {
    referencedInstance = referencedModuleInstances.find(
      ({ id }) => id === moduleInstanceId,
    );
  }

  return moduleInstance || referencedInstance;
}

export function* transformAddErrorLogsSaga(logs: Log[]) {
  yield call(waitForFetchingConsumableModules);

  const transformedLogs: Log[] = klona(logs);
  const privateEntityInstanceMapping: Record<string, string> = yield select(
    getPrivateEntityInstanceMapping,
  );
  const referencedModuleInstances: ModuleInstance[] = yield select(
    getAllEnhancedReferencedModuleInstance,
  );
  const mappingEntries = Object.entries(privateEntityInstanceMapping);

  for (const log of transformedLogs) {
    const { id = "", messages, source } = log;

    messages?.forEach(({ message }) => {
      (mappingEntries || []).forEach(([name, sourceModuleName]) => {
        if (sourceModuleName) {
          message.message = message.message.replace(name, sourceModuleName);
        }
      });
    });

    if (source?.type === ENTITY_TYPE.ACTION) {
      const instanceAction: ModuleInstanceAction | undefined = yield select(
        getModuleInstanceActionById,
        id,
      );
      const { moduleInstanceId = "" } = instanceAction || {};
      const moduleInstance: ModuleInstance | undefined = yield call(
        resolveModuleInstance,
        moduleInstanceId,
        referencedModuleInstances,
      );

      if (moduleInstance) {
        log.id = moduleInstanceId;

        if (log.source) {
          log.source.id =
            moduleInstance.rootModuleInstanceId || moduleInstance.id;
          log.source.type = ENTITY_TYPE.MODULE_INSTANCE;
          log.source.name =
            moduleInstance.rootModuleInstanceName || moduleInstance.name;
        }
      }
    }

    if (source?.type === ENTITY_TYPE.JSACTION) {
      const instanceJSCollection: ModuleInstanceJSCollection | undefined =
        yield select(getModuleInstanceJSCollectionById, source?.id || "");

      const { moduleInstanceId = "" } = instanceJSCollection || {};
      const moduleInstance: ModuleInstance | undefined = yield call(
        resolveModuleInstance,
        moduleInstanceId,
        referencedModuleInstances,
      );

      if (moduleInstance) {
        if (log.source) {
          log.source.id =
            moduleInstance.rootModuleInstanceId || moduleInstance.id;
          log.source.type = ENTITY_TYPE.MODULE_INSTANCE;
          log.source.name =
            moduleInstance.rootModuleInstanceName || moduleInstance.name;
        }
      }
    }
  }

  return transformedLogs;
}

export function* transformDeleteErrorLogsSaga(payload: DeleteErrorLogPayload) {
  const transformedPayload = klona(payload);

  for (const item of transformedPayload) {
    const { id } = item;

    const instanceAction: ModuleInstanceAction | undefined = yield select(
      getModuleInstanceActionById,
      id,
    );
    const instanceJSCollection: ModuleInstanceJSCollection | undefined =
      yield select(getModuleInstanceJSCollectionById, id || "");

    const moduleInstanceId =
      instanceAction?.moduleInstanceId ||
      instanceJSCollection?.moduleInstanceId;

    if (moduleInstanceId) {
      item.id = moduleInstanceId;
    }
  }

  return transformedPayload;
}

export function* transformTriggerEvalErrors(errors: EvaluationError[]) {
  const ce_errors: EvaluationError[] = yield call(
    ce_transformTriggerEvalErrors,
    errors,
  );

  if (ce_errors.length === 0) return ce_errors;

  const privateEntityInstanceMapping: Record<string, string> = yield select(
    getPrivateEntityInstanceMapping,
  );
  const mappingEntries = Object.entries(privateEntityInstanceMapping);

  const transformedErrors = klona(ce_errors);

  transformedErrors.forEach((error) => {
    mappingEntries.forEach(([entityName, moduleInstanceName]) => {
      error.errorMessage.message = error.errorMessage.message.replace(
        entityName,
        moduleInstanceName,
      );
    });
  });

  return transformedErrors;
}

/**
 * wrapping the paragon install integration function to return a promise
 * so that we can use it in sagas
 */
export const paragonInstallIntegration = async (plugin: Plugin) => {
  return new Promise((resolve, reject) => {
    paragon.installIntegration(plugin.pluginName!, {
      allowMultipleCredentials: true,
      onSuccess: resolve,
      onError: reject,
      accountType: ["default", "user-configured-oauth"],
    });
  });
};

interface CreateDatasourcePayloadFromParagonEventParams {
  event: IntegrationInstallEvent;
  currentEnvId: string;
  datasources: Datasource[];
  plugin: Plugin;
}

export function* getProviderDataArray(
  providerData: Record<string, string | boolean | number> = {},
  credentialId?: string,
  integrationType?: string,
) {
  let customProviderData = {};
  const providerDataArray: { key: string; value: string | boolean | number }[] =
    [];

  // Store provider data in customProviderData if available
  if (Object.keys(providerData).length > 0) {
    customProviderData = providerData;
  } else {
    try {
      const workspaceId: string = yield select(getCurrentWorkspaceId);
      const response: ApiResponse<Record<string, string | boolean | number>> =
        yield call(
          DatasourcesApi.fetchProviderData,
          workspaceId,
          credentialId || "",
          integrationType || "",
        );

      customProviderData = response.data || {};
    } catch (error) {
      toast.show("Failed to fetch provider data", {
        kind: "error",
      });
    }
  }

  // Process the customProviderData
  objectKeys(customProviderData).forEach((key) => {
    const value = customProviderData[key];

    if (
      typeof value === "string" ||
      typeof value === "boolean" ||
      typeof value === "number"
    ) {
      providerDataArray.push({
        key,
        value,
      });
    }
  });

  return providerDataArray;
}

/**
 * After the paragon integration is installed, we get an event from paragon.
 * From this event, we can extract the credentials, create an payload
 * that can be used to create a datasource.
 */
export function* createDatasourceActionPayloadFromParagonEvent(
  props: CreateDatasourcePayloadFromParagonEventParams,
) {
  const { currentEnvId, datasources, event, plugin } = props;

  const datasourceName = getNextEntityName(
    DATASOURCE_NAME_DEFAULT_PREFIX,
    datasources.map((el: Datasource) => el.name),
  );

  const { credential, credentialId, integrationId, integrationType } =
    event || {};

  const { providerData = {} } = credential || {};

  const providerDataArray: { key: string; value: string | boolean | number }[] =
    yield call(
      getProviderDataArray,
      providerData,
      credentialId,
      integrationType,
    );

  const reduxActionPayload: CreateDatasourceConfig = {
    name: datasourceName,
    type: PluginType.EXTERNAL_SAAS,
    pluginId: plugin?.id,
    datasourceStorages: {
      [currentEnvId]: {
        environmentId: currentEnvId,
        datasourceId: TEMP_DATASOURCE_ID,
        isValid: false,
        datasourceConfiguration: {
          url: "",
          authentication: {
            integrationId,
            credentialId,
            integrationType,
            authenticationType: AuthenticationType.EXTERNAL_SAAS_AUTHENTICATION,
            providerData: providerDataArray,
          },
        },
        toastMessage: ToastMessageType.EMPTY_TOAST_MESSAGE,
      },
    },
  };

  return reduxActionPayload;
}
