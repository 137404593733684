import type { ReactNode } from "react";
import React, { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent } from "../common/design-system/Dialog";
import { findModifications, getBaseURL } from "../../utils/helper-functions";
import Loader from "../common/Loader";
import IntegrationList from "../IntegrationModal/IntegrationList";
import WebScraper from "../WebScraper/WebScraper";
import CarbonFilePicker from "../CarbonFilePicker/CarbonFilePicker";
import { INTEGRATIONS_LIST } from "../../utils/integrationModalconstants";
import { useCarbon } from "../../context/CarbonContext";
import type { ActiveStep } from "../../typing/shared";
import { IntegrationName } from "../../typing/shared";

import LocalFilesScreen from "../SystemFileUpload/LocalFilesScreen";

export interface ModalProps {}

// todo - better types
export interface IntegrationAPIResponse {
  id: number;
  data_source_type: IntegrationName;
  data_source_external_id: string;
  files: never;
  synced_files: never[];
  sync_status: string;
  last_synced_at: Date;
  last_sync_action: string;
  source_items_synced_at: Date;
  files_synced_at: Date;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data_source_metadata: any;
  created_at: Date;
}

export function IntegrationModal({ children }: { children: ReactNode }) {
  const {
    accessToken,
    apiURL,
    authenticatedFetch,
    dataSourcePollingInterval,
    dataSourceTagsFilterQuery,
    entryPoint,
    environment,
    fetchTokens,
    loading,
    manageModalOpenState,
    onSuccess,
    requestIds,
    setLastModifications,
    showModal,
    whiteLabelingData,
  } = useCarbon();

  const [activeIntegrations, setActiveIntegrations] = useState<
    IntegrationAPIResponse[]
  >([]);

  const requestIdsRef = useRef(requestIds);
  const activeIntegrationsRef = useRef(activeIntegrations);
  const firstFetchCompletedRef = useRef(false);
  const [activeStep, setActiveStep] = useState<ActiveStep>(
    entryPoint || "INTEGRATION_LIST",
  );

  const fetchUserIntegrations = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const params: any = {
        pagination: {
          limit: 250,
          offset: 0,
        },
        filters: {
          revoked_access: false,
        },
      };

      if (dataSourceTagsFilterQuery) {
        params["filters"]["tags"] = dataSourceTagsFilterQuery;
      }

      const userIntegrationsResponse = await authenticatedFetch(
        `${getBaseURL(apiURL, environment)}/user_data_sources`,
        {
          method: "POST",
          body: JSON.stringify(params),
          headers: {
            Authorization: `Token ${accessToken}`,
            "Content-Type": "application/json",
          },
        },
      );

      if (userIntegrationsResponse.status === 200) {
        const responseBody = await userIntegrationsResponse.json();

        if (firstFetchCompletedRef.current) {
          const integrationModifications = findModifications(
            responseBody["results"],
            activeIntegrationsRef.current,
            requestIdsRef,
          );

          if (integrationModifications.length > 0) {
            for (let i = 0; i < integrationModifications.length; i++) {
              onSuccess && onSuccess(integrationModifications[i]);
            }
          }

          setLastModifications(integrationModifications);
        } else {
          firstFetchCompletedRef.current = true;
        }

        activeIntegrationsRef.current = responseBody["results"];
        setActiveIntegrations(responseBody["results"]);

        return;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    if (accessToken) {
      fetchUserIntegrations();
    } else {
      fetchTokens();
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken && showModal) {
      const pollingInterval = dataSourcePollingInterval
        ? Math.max(dataSourcePollingInterval, 3000)
        : 8000;
      const intervalId = setInterval(fetchUserIntegrations, pollingInterval);

      // Make sure to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [accessToken, showModal]);

  useEffect(() => {
    requestIdsRef.current = requestIds || {};
  }, [requestIds]);

  useEffect(() => {
    activeIntegrationsRef.current = activeIntegrations;
  }, [activeIntegrations]);

  const isWhiteLabeledEntryPoint =
    entryPoint && whiteLabelingData?.remove_branding;

  const showActiveContent = (activeStep: ActiveStep) => {
    switch (activeStep) {
      case "INTEGRATION_LIST":
        return (
          <IntegrationList
            activeIntegrations={activeIntegrations}
            onCloseModal={() => manageModalOpenState(false)}
            setActiveStep={setActiveStep}
          />
        );

      case IntegrationName.WEB_SCRAPER:
        return (
          <WebScraper
            activeStep={activeStep}
            isWhiteLabeledEntryPoint={isWhiteLabeledEntryPoint}
            onCloseModal={() => manageModalOpenState(false)}
            setActiveStep={setActiveStep}
          />
        );

      case IntegrationName.LOCAL_FILES:
        return (
          <LocalFilesScreen
            activeStepData={INTEGRATIONS_LIST.find(
              (item) => item.id === IntegrationName.LOCAL_FILES,
            )}
            isWhiteLabeledEntryPoint={isWhiteLabeledEntryPoint}
            onCloseModal={() => manageModalOpenState(false)}
            setActiveStep={setActiveStep}
          />
        );
      default:
        return (
          <CarbonFilePicker
            activeIntegrations={activeIntegrations}
            activeStepData={INTEGRATIONS_LIST.find(
              (item) => item.id === activeStep,
            )}
            isWhiteLabeledEntryPoint={isWhiteLabeledEntryPoint}
            onCloseModal={() => manageModalOpenState(false)}
            setActiveStep={setActiveStep}
          />
        );
        break;
    }
  };

  return (
    <>
      {children ? <div>{children}</div> : null}
      <Dialog
        onOpenChange={(modalOpenState) => manageModalOpenState(modalOpenState)}
        open={showModal}
      >
        <DialogContent activeState={activeStep}>
          {loading ? <Loader /> : showActiveContent(activeStep)}
        </DialogContent>
      </Dialog>
    </>
  );
}

export default IntegrationModal;
