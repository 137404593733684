/**
 * UIModuleMockApi
 *
 * This class provides a mock API interface for managing UI Modules in local storage.
 * It mimics the behavior of a real API by providing similar response structures and async operations.
 *
 * Note: This is a client-side implementation that uses local storage instead of server calls and will be removed when the backend is ready.
 */

import { MODULE_TYPE } from "ee/constants/ModuleConstants";
import type { Module, BaseModule } from "ee/constants/ModuleConstants";
import type { ApiResponse } from "api/ApiResponses";
import type { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { generateDefaultInputSection } from "ee/components/InputsForm/Fields/helper";
import { PluginType } from "entities/Plugin";

const LOCAL_STORAGE_KEY = "ui_modules";

interface CreateModulePayload {
  packageId: string;
  type: MODULE_TYPE;
  name?: string;
  inputsForm?: Module["inputsForm"];
  // eslint-disable-next-line
  entity?: any;
  layouts?: unknown;
}

class UIModuleMockApi {
  private static getModulesFromStorage(): Module[] {
    const modulesStr = localStorage.getItem(LOCAL_STORAGE_KEY);

    return modulesStr ? JSON.parse(modulesStr) : [];
  }

  private static saveModulesToStorage(modules: Module[]) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(modules));
  }

  private static createAxiosResponse<T>(
    data: ApiResponse<T>,
  ): AxiosResponse<ApiResponse<T>> {
    return {
      data,
      // eslint-disable-next-line
      // @ts-ignore
      responseMeta: { status: 200, success: true },
      status: 200,
      statusText: "OK",
      headers: {},
      config: {
        headers: {} as Record<string, string>,
      } as InternalAxiosRequestConfig,
    };
  }

  static async createModule(
    payload: CreateModulePayload,
  ): Promise<AxiosResponse<ApiResponse<Module>>> {
    const modules = this.getModulesFromStorage();

    const newModule: BaseModule = {
      id: `ui-module-${Date.now()}`,
      baseId: `ui-module-${Date.now()}`,
      name: payload.name || "Untitled UI Module",
      packageId: payload.packageId,
      type: MODULE_TYPE.UI,
      inputsForm: payload.inputsForm || [generateDefaultInputSection()],
      // eslint-disable-next-line
      // @ts-ignore
      layouts: payload.layouts,
      pluginId: "",
      pluginType: PluginType.JS,
      datasourceId: "",
      settingsForm: [],
      userPermissions: [
        "read:modules",
        "create:moduleExecutables",
        "create:moduleInstances",
        "manage:modules",
        "delete:modules",
        "read:moduleInstances",
      ],
      version: "1.0.0",
      actionId: "",
    };

    modules.push(newModule as Module);
    this.saveModulesToStorage(modules);

    // eslint-disable-next-line
    // @ts-ignore
    return this.createAxiosResponse(newModule);
  }

  static async updateModule(
    payload: Module,
  ): Promise<AxiosResponse<ApiResponse<Module>>> {
    const modules = this.getModulesFromStorage();
    const moduleIndex = modules.findIndex((m) => m.id === payload.id);

    if (moduleIndex === -1) {
      throw new Error("Module not found");
    }

    if (modules[moduleIndex].type !== MODULE_TYPE.UI) {
      throw new Error("Not a UI module");
    }

    modules[moduleIndex] = {
      ...modules[moduleIndex],
      ...payload,
    };

    this.saveModulesToStorage(modules);

    // eslint-disable-next-line
    // @ts-ignore
    return this.createAxiosResponse(modules[moduleIndex]);
  }

  static shouldHandleModule(type?: MODULE_TYPE): boolean {
    return type === MODULE_TYPE.UI;
  }
}

export default UIModuleMockApi;
