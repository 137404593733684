import type { AppState } from "ee/reducers";
import React from "react";
import { connect } from "react-redux";
import { RagIntegrationsControls } from "./RagIntegrationsControls";
import { RagDocuments } from "./RagDocuments";
import BaseControl from "components/formControls/BaseControl";
import type { ControlType } from "constants/PropertyControlConstants";
import type {
  RagIntegrationsProps,
  RagIntegrationsState,
  RagIntegrationsTabs,
} from "./types";
import { Field } from "redux-form";
import { Tab, TabPanel, Tabs, TabsList } from "@appsmith/ads";

class _RagIntegrations extends BaseControl<
  RagIntegrationsProps,
  RagIntegrationsState
> {
  state = {
    activeTab: "vectorDB" as RagIntegrationsTabs,
  };

  getControlType(): ControlType {
    return "RAG_INTEGRATIONS";
  }

  handleTabChange = (tabId: RagIntegrationsTabs) => {
    this.setState({ activeTab: tabId });
  };

  render() {
    const { activeTab } = this.state;
    const { configProperty, datasourceId, ragDocumentsCount, workspaceId } =
      this.props;

    return (
      <Tabs
        defaultValue={activeTab}
        onValueChange={(tabId) =>
          this.handleTabChange(tabId as RagIntegrationsTabs)
        }
      >
        <TabsList>
          <Tab notificationCount={ragDocumentsCount} value="vectorDB">
            Vector DB
          </Tab>
          <Tab value="configuration">Configuration</Tab>
        </TabsList>
        <TabPanel value="vectorDB">
          <RagDocuments datasourceId={datasourceId} workspaceId={workspaceId} />
        </TabPanel>
        <TabPanel value="configuration">
          <Field
            component={RagIntegrationsControls}
            name={configProperty}
            props={this.props}
          />
        </TabPanel>
      </Tabs>
    );
  }
}

export const RagIntegrations = connect(
  (state: AppState, ownProps: RagIntegrationsProps) => ({
    ragDocumentsCount: ownProps.datasourceId
      ? state.ai.ragDocuments?.[ownProps.datasourceId]?.length || 0
      : 0,
  }),
)(_RagIntegrations);
