import type { FlattenedWidgetProps } from "ee/reducers/entityReducers/canvasWidgetsStructureReducer";
import { set } from "lodash";
import { generateReactKey } from "utils/generators";
import type { WidgetProps } from "widgets/BaseWidget";
interface Props {
  moduleInstanceName: string;
  widgets: WidgetProps[];
  flattenedWidgets: Record<string, FlattenedWidgetProps>;
  parentId: string;
  creatorId: string;
}

/**
 * Recursively generates meta widgets for a module instance.
 *
 * This function takes a list of widgets and recursively processes them to create
 * meta widgets for a module instance. It assigns unique IDs, updates parent-child
 * relationships, prefixes widget names with the module instance name, and disables
 * various widget operations.
 *
 * @param {Props} props - The properties required for generating meta widgets
 * @param {string} props.moduleInstanceName - The name of the module instance
 * @param {WidgetProps[]} props.widgets - Array of widget properties to process
 * @param {Record<string, FlattenedWidgetProps>} props.flattenedWidgets - Object to store flattened widget references
 * @param {string} props.parentId - ID of the parent widget
 * @param {string} props.creatorId - ID of the creator widget
 * @returns {WidgetProps[]} Array of processed widget properties
 */
export function recursivelyGenerateMetaWidgets({
  creatorId,
  flattenedWidgets,
  moduleInstanceName,
  parentId,
  widgets,
}: Props) {
  return widgets.map((widget) => {
    const widgetId = `${creatorId}_${generateReactKey()}`;

    widget.widgetId = widgetId;
    widget.parentId = parentId;
    disableWidgetOperations(widget);

    if (widget.children) {
      widget.children = recursivelyGenerateMetaWidgets({
        creatorId,
        moduleInstanceName,
        widgets: widget.children,
        flattenedWidgets,
        parentId: widgetId,
      });
    }

    flattenedWidgets[widgetId] = widget;

    return widgetId;
  });
}

/**
 * Disables various operations and interactions for a meta widget.
 *
 * This function sets multiple properties on a widget to disable user interactions
 * such as resizing, dragging, property pane access, and more. It marks the widget
 * as a meta widget and configures it to suppress certain behaviors.
 *
 * @param {WidgetProps} metaWidget - The widget to be configured as a meta widget
 */
function disableWidgetOperations(metaWidget: WidgetProps) {
  set(metaWidget, "resizeDisabled", true);
  set(metaWidget, "forceFullOpacity", true);
  set(metaWidget, "disablePropertyPane", true);
  set(metaWidget, "dragDisabled", true);
  set(metaWidget, "dropDisabled", true);

  set(metaWidget, "ignoreCollision", true);
  set(metaWidget, "shouldScrollContents", undefined);

  set(metaWidget, "suppressAutoComplete", true);
  set(metaWidget, "suppressDebuggerError", true);
  set(metaWidget, "isMetaWidget", true);

  set(metaWidget, `disabledResizeHandles`, [
    "left",
    "top",
    "right",
    "bottomRight",
    "topLeft",
    "topRight",
    "bottomLeft",
  ]);
}
